export const GET_NEW_ENTITY_SAVE_SUCCESS = "GET_NEW_ENTITY_SAVE_SUCCESS";
export const GET_ENTITY_UPDATE_SUCCESS = "GET_ENTITY_UPDATE_SUCCESS";
export const GET_UPDATE_ENTITY_MSGS = "GET_UPDATE_ENTITY_MSGS";
export const GET_SAVE_ENTITY_MSGS = "GET_SAVE_ENTITY_MSGS";
export const GET_ELR_OBJECT_TYPE_NAME_DATA = "GET_ELR_OBJECT_TYPE_NAME_DATA";
export const GET_ELR_DATA_SOURCE_NAME_DATA = "GET_ELR_DATA_SOURCE_NAME_DATA";
export const GET_ELR_SUBJECT_AREA_NAME_DATA = "GET_ELR_SUBJECT_AREA_NAME_DATA";

export type NewEntityActionTypes = typeof GET_UPDATE_ENTITY_MSGS | typeof GET_SAVE_ENTITY_MSGS
    | typeof GET_NEW_ENTITY_SAVE_SUCCESS | typeof GET_ENTITY_UPDATE_SUCCESS
    | typeof GET_ELR_OBJECT_TYPE_NAME_DATA | typeof GET_ELR_DATA_SOURCE_NAME_DATA | typeof GET_ELR_SUBJECT_AREA_NAME_DATA;
