import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../../redux/types/app-actions";
import { AppState } from "../../../../redux/configureStore";
import * as DomainDefinitionAPI from "../../../../api/domain-definition-api";
import * as DomainDefinitionActions from "../../../../redux/actions/domain-definition-actions";
import { IDomainMsgInfo, INewDomainAPI, INewDomainState } from "./model/INewDomain";


export const startGetDomainTypes = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = DomainDefinitionAPI.getDomainTypes()
            .then((response) => {
                //Response from API is in form: "<DomainTypeID>,<DomainTypeName>"
                const data: any[] = response.map((domainTypeIdName: string, index: any) => {
                    return ({
                        key: domainTypeIdName.split(",")[1], 
                        text: domainTypeIdName.split(",")[1], 
                        id: domainTypeIdName.split(",")[0]
                    })
                });
                dispatch(DomainDefinitionActions.getDomainTypes(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

export const startGetAllEntities = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = DomainDefinitionAPI.getAllEntitiesList(fiscalYear)
            .then((response) => {
                //Response from API is in form: "<EntityID>,<EntityName>"
                const data: any[] = response.map((EntityIdName: string, index: any) => {
                    return ({
                        key: EntityIdName.split(",")[1], 
                        text: EntityIdName.split(",")[1],
                        id: EntityIdName.split(",")[0]
                    })
                });
                dispatch(DomainDefinitionActions.getAllEntities(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

export const addNewEntity = (entity: string) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        dispatch(DomainDefinitionActions.addNewEntity(entity));
    };
}

const setDomainStateToApi = (newDomainState: INewDomainState) => {
    const domain : INewDomainAPI = {};
    domain.FiscalYear = newDomainState.FiscalYear;
    domain.DomainName = newDomainState.DomainName;
    domain.DomainTypeID = newDomainState.DomainTypeID;
    domain.DomainTypeName = newDomainState.DomainTypeName;
    domain.DimTableName = newDomainState.DimTableName;
    domain.DomainAttributeLevels = newDomainState.DomainAttributeLevels;
    domain.NaturalKey = newDomainState.NaturalKey;
    domain.IsCompensableMetricsEligible = newDomainState.IsCompensableMetricsEligible;
    return domain;
}

export const startSaveDomainToDB = (newDomainState: INewDomainState, startGetAllDomains: ()=>void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const newDomain = setDomainStateToApi(newDomainState);
        const res = DomainDefinitionAPI.saveDomainToDB(newDomain)
            .then((response) => {
                const result: IDomainMsgInfo = response;
                let tempResult: IDomainMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "Domain saved successfully. Please proceeed to create ENTITY RELATIONSHIPS, if applicable" :
                                                (result.Result === 0 ? "Domain NOT saved successfully." : 
                                                    (result.Result === -2 ? "Domain with same name already exists." : "")));
                    dispatch(DomainDefinitionActions.getNewDomainSaveSuccessAction(tempResult));

                    if (startGetAllDomains && (result != undefined && result.Result === 1)) 
                        startGetAllDomains();
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const startUpdateDomainToDB = (domainId: number, domainState: INewDomainState, startGetAllDomains: ()=>void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const domain = setDomainStateToApi(domainState);
        const res = DomainDefinitionAPI.updateDomainToDB(domainId, domain)
            .then((response) => {
                const result: IDomainMsgInfo = response;
                let tempResult: IDomainMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "Domain updated successfully.Please proceeed to create/edit entity relationships, if applicable" :
                                                (result.Result === 0 ? "Domain NOT updated successfully." : ""));
                    dispatch(DomainDefinitionActions.getNewDomainSaveSuccessAction(tempResult));

                    if (startGetAllDomains && (result != undefined && result.Result === 1)) 
                        startGetAllDomains();
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const clearAllEntitiesProps = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const data: any[] = [];
        dispatch(DomainDefinitionActions.getAllEntities(data));
    };
}

export const clearDatasetColumnsProps = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        dispatch(DomainDefinitionActions.clearDatasetColumns());
    };
};

export const clearSaveResultMsgProps = (loading: boolean) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        var tempResult: IDomainMsgInfo = {
            Result: 5,
            StatusCode: 0,
            ErrorMessage: "",
            loading: loading,
        };
        dispatch(DomainDefinitionActions.getNewDomainSaveSuccessAction(tempResult));
    };
};






