import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { IColumn } from "office-ui-fabric-react";
import { GetToken } from "../auth/authorization-token";
import { IFlexGrain, INewPerspectiveAPI, IPerspectiveMeasure } from "../components/onboarding/perspective-definition/new-perspective/model/INewPerspective";


let authToken = "";
const baseUrl = Configurations.mintSystemConfigApiBaseUrl + "/PerspectiveOnboarding/";
const FlexbaseUrl = Configurations.mintSystemConfigApiBaseUrl + "/QuotaFactAttributeMapping/";
const columns: IColumn[] = [];

export function getAllPerspectives() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'Perspectives', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getAllFlexGrains(perspectiveId: number, ) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(FlexbaseUrl + 'QuotaFactAttributeMappings?PerspectiveId=' + perspectiveId , {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}


export function getAllDomainAttributes(fiscalYear: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'DomainAttributes?fiscalYear=' + fiscalYear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getFlexGrainDomainAttributes(fiscalYear: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'PerspectiveDomainAttributes?fiscalYear=' + fiscalYear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getMeasures(perspectiveId: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'Measures?perspectiveId=' + perspectiveId, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function savePerspectiveToDB(newPerspective: INewPerspectiveAPI) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'CreateNewPerspective', {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(newPerspective),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function updatePerspectiveToDB(perspective: INewPerspectiveAPI) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'UpdatePerspective' , {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(perspective),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function saveFlexGrainToDB(flexGrain: IFlexGrain) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(FlexbaseUrl + 'CreateNewQuotaFactAttributeMapping' , {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(flexGrain),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function updateFlexGrainToDB(flexGrain: IFlexGrain) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(FlexbaseUrl + 'UpdateQuotaFactAttributeMapping' , {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(flexGrain),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}