import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../../redux/types/app-actions";
import { AppState } from "../../../../redux/configureStore";
import * as DomainDefinitionAPI from "../../../../api/domain-definition-api";
import * as DomainDefinitionActions from "../../../../redux/actions/domain-definition-actions";

export const startGetDatasetColumns = (attributeLevelId: number, datasetIds: number[]) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        //Sending list of DatasetIDs to API in the form of a string separated by ','
        const res = DomainDefinitionAPI.getDatasetColumns(datasetIds.join())
            .then((response) => {
                const data: string[] = response;
                dispatch(DomainDefinitionActions.getDatasetColumns(attributeLevelId, data));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};