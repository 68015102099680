import React from "react";
import {
  IDropdownOption,
  Label,
  TextField,
  PrimaryButton,
  FontIcon,
  Checkbox,
  Spinner,
  SpinnerSize,
  DefaultButton,
  Modal,
} from "office-ui-fabric-react";
import { IDropdownPropsCustom } from "../../../common/dropdown/models/IDropdown";
import Dropdown from "../../../common/dropdown/dropdown.controller";
import "./new-domain.scss";
import { connect } from "react-redux";
import {
  startGetDomainTypes,
  startGetAllEntities,
  startSaveDomainToDB,
  startUpdateDomainToDB,
  clearSaveResultMsgProps,
  clearDatasetColumnsProps,
  clearAllEntitiesProps,
  addNewEntity,
} from "./new-domain.service";
import {
  INewDomainProps,
  INewDomainState,
  INewDomainLinkStateProps,
  INewDomainLinkDispatchProps,
  IDomainAttributeLevel,
  IDomainMsgInfo,
  IDomainAttribute,
} from "./model/INewDomain";
import { AppState } from "../../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import { bindActionCreators } from "redux";
import DomainAttributeLevel from "./domain-attribute-level.controller";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { getDeepCopy } from "../../../../util/javascript-functions";
import * as TelemetryProvider from "../../../../TelemetryProvider";
import {
  newDomainClearButtonClickEvent,
  newDomainSaveButtonClickEvent,
} from "../domain-definition.telemtetry-constants";
import {
  contentStyles,
  iconButtonStyles,
} from "../../../common/modal/modal.style";
import IconButton from "../../../common/button/icon-button/icon-button.controller";

type Props = INewDomainProps &
  INewDomainLinkStateProps &
  INewDomainLinkDispatchProps;

class NewDomain extends React.Component<Props, INewDomainState> {
  private _AttributeLevelCount: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      ActionType: this.props.ActionType,
      FiscalYear: this.props.fiscalYear,
      DomainID: this.props.DomainID,
      DomainName: this.props.DomainName,
      DomainTypeID: this.props.DomainTypeID,
      DomainTypeName: this.props.DomainTypeName,
      DimTableName: this.props.DimTableName,
      SelectedEntities: this.setSelectedEntities(),
      SelectedEntitiesIDName: this.setSelectedEntitiesIDName(),
      DomainAttributeLevels: this.setDomainAttributeLevels(),
      InitDomainAttributeLevels: this.setInitDomainAttributeLevels(),
      NaturalKey: this.setNaturalKey(),
      IsCompensableMetricsEligible: this.props.IsCompensableMetricsEligible,
      AttributeLevels: this.setAttributeLevels(),
      NewEntityName: undefined,
    };

    this.props.startGetAllEntities(this.props.fiscalYear);
    this.props.startGetDomainTypes();

    this._AttributeLevelCount = this.state.AttributeLevels.length;
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  private setSelectedEntities = (): string[] => {
    var SelectedEntities: string[] = [];

    if (this.props.DomainEntities != undefined) {
      SelectedEntities = this.props.DomainEntities.map(
        (EntityIdName: string, index: any) => {
          return EntityIdName.split(",")[1];
        }
      );
    }
    return SelectedEntities;
  };

  private setSelectedEntitiesIDName = (): any[] => {
    var SelectedEntitiesIDName: any[] = [];

    if (this.props.DomainEntities != undefined) {
      SelectedEntitiesIDName = this.props.DomainEntities.map(
        (EntityIdName: string, index: any) => {
          return {
            id: Number(EntityIdName.split(",")[0]),
            name: EntityIdName.split(",")[1],
          };
        }
      );
    }
    return SelectedEntitiesIDName;
  };

  private setInitDomainAttributeLevels = (): IDomainAttributeLevel[] => {
    var AttributeLevels: IDomainAttributeLevel[] = [];

    if (this.props.DomainAttributes != undefined) {
      this.props.DomainAttributes.forEach((attribute) => {
        var index = AttributeLevels.findIndex(
          (a) => a.HierarchyLevelNumber == attribute.HierarchyLevel
        );

        if (index == -1) {
          var attributeLevel: IDomainAttributeLevel = {
            HierarchyLevelNumber: attribute.HierarchyLevel,
            HierarchyLevelName: attribute.HierarchyLevelName,
            Entities: [],
            DomainAttributes: [],
          };

          var entityIDName = this.props.DomainEntities?.find((entity) =>
            entity.startsWith(attribute.EntityID + ",")
          );
          if (entityIDName != undefined) {
            attributeLevel.Entities?.push({
              id: Number(entityIDName.split(",")[0]),
              name: entityIDName.split(",")[1],
            });
          }

          attributeLevel.DomainAttributes.push({
            AttributeDisplayName:
              attribute.AttributeDisplayName != undefined
                ? attribute.AttributeDisplayName
                : "NULL",
            DomainAttributeName:
              attribute.DomainAttributeName != undefined
                ? attribute.DomainAttributeName
                : "NULL",
            DomainAttributeQualifier:
              attribute.DomainAttributeQualifier != undefined
                ? attribute.DomainAttributeQualifier
                : "NULL",
            DefaultValue:
              attribute.DefaultValue != undefined
                ? attribute.DefaultValue
                : "NULL",
            EntityID: attribute.EntityID,
            EntityName: entityIDName?.split(",")[1],
            DataTypeName:
            attribute.DataTypeName != undefined
              ? attribute.DataTypeName
              : "NULL",
          });

          AttributeLevels.push(attributeLevel);
        } else {
          var attributeLevel = AttributeLevels[index];

          var entityIDName = this.props.DomainEntities?.find((entity) =>
            entity.startsWith(attribute.EntityID + ",")
          );
          if (
            entityIDName != undefined &&
            attributeLevel.Entities?.find(
              (a) => a.id.toString() == entityIDName?.split(",")[0]
            ) == undefined
          ) {
            attributeLevel.Entities?.push({
              id: Number(entityIDName.split(",")[0]),
              name: entityIDName.split(",")[1],
            });
          }

          attributeLevel.DomainAttributes.push({
            AttributeDisplayName:
              attribute.AttributeDisplayName != undefined
                ? attribute.AttributeDisplayName
                : "NULL",
            DomainAttributeName:
              attribute.DomainAttributeName != undefined
                ? attribute.DomainAttributeName
                : "NULL",
            DomainAttributeQualifier:
              attribute.DomainAttributeQualifier != undefined
                ? attribute.DomainAttributeQualifier
                : "NULL",
            DefaultValue:
              attribute.DefaultValue != undefined
                ? attribute.DefaultValue
                : "NULL",
            EntityID: attribute.EntityID,
            EntityName: entityIDName?.split(",")[1],
            DataTypeName: 
              attribute.DataTypeName != undefined
                ? attribute.DataTypeName
                : "NULL",
          });

          AttributeLevels[index] = attributeLevel;
        }
      });
    }
    return AttributeLevels;
  };

  private setDomainAttributeLevels = (): IDomainAttributeLevel[] => {
    var AttributeLevels: IDomainAttributeLevel[] = [];

    if (this.props.DomainAttributes != undefined) {
      this.props.DomainAttributes.forEach((attribute) => {
        var index = AttributeLevels.findIndex(
          (a) => a.HierarchyLevelNumber == attribute.HierarchyLevel
        );

        if (index == -1) {
          var attributeLevel: IDomainAttributeLevel = {
            id: attribute.HierarchyLevel,
            index: attribute.HierarchyLevel,
            HierarchyLevelNumber: attribute.HierarchyLevel,
            HierarchyLevelName: attribute.HierarchyLevelName,
            Entities: [],
            DomainAttributes: [],
          };

          var entityIDName = this.props.DomainEntities?.find((entity) =>
            entity.startsWith(attribute.EntityID + ",")
          );
          if (entityIDName != undefined) {
            attributeLevel.Entities?.push({
              id: Number(entityIDName.split(",")[0]),
              name: entityIDName.split(",")[1],
            });
          }

          attributeLevel.DomainAttributes.push({
            AttributeDisplayName:
              attribute.AttributeDisplayName != undefined
                ? attribute.AttributeDisplayName
                : "NULL",
            DomainAttributeName:
              attribute.DomainAttributeName != undefined
                ? attribute.DomainAttributeName
                : "NULL",
            DomainAttributeQualifier:
              attribute.DomainAttributeQualifier != undefined
                ? attribute.DomainAttributeQualifier
                : "NULL",
            DefaultValue:
              attribute.DefaultValue != undefined
                ? attribute.DefaultValue
                : "NULL",
            EntityID: attribute.EntityID,
            EntityName: entityIDName?.split(",")[1],
            DataTypeName:
              attribute.DataTypeName != undefined
                ? attribute.DataTypeName
                : "NULL",
          });

          AttributeLevels.push(attributeLevel);
        } else {
          var attributeLevel = AttributeLevels[index];

          var entityIDName = this.props.DomainEntities?.find((entity) =>
            entity.startsWith(attribute.EntityID + ",")
          );
          if (
            entityIDName != undefined &&
            attributeLevel.Entities?.find(
              (a) => a.id.toString() == entityIDName?.split(",")[0]
            ) == undefined
          ) {
            attributeLevel.Entities?.push({
              id: Number(entityIDName.split(",")[0]),
              name: entityIDName.split(",")[1],
            });
          }

          attributeLevel.DomainAttributes.push({
            AttributeDisplayName:
              attribute.AttributeDisplayName != undefined
                ? attribute.AttributeDisplayName
                : "NULL",
            DomainAttributeName:
              attribute.DomainAttributeName != undefined
                ? attribute.DomainAttributeName
                : "NULL",
            DomainAttributeQualifier:
              attribute.DomainAttributeQualifier != undefined
                ? attribute.DomainAttributeQualifier
                : "NULL",
            DefaultValue:
              attribute.DefaultValue != undefined
                ? attribute.DefaultValue
                : "NULL",
            EntityID: attribute.EntityID,
            EntityName: entityIDName?.split(",")[1],
            DataTypeName: 
              attribute.DataTypeName != undefined
                ? attribute.DataTypeName
                : "NULL",
          });

          AttributeLevels[index] = attributeLevel;
        }
      });
    }
    AttributeLevels.sort((a, b) => Number(a.id) - Number(b.id));
    return AttributeLevels;
  };

  private setAttributeLevels = (): number[] => {
    var AttributeLevels: number[] = [];

    if (this.props.DomainAttributes != undefined) {
      var maxLevel = -1;
      this.props.DomainAttributes.forEach((a) => {
        if (a.HierarchyLevel > maxLevel) maxLevel = a.HierarchyLevel;
      });
      for (var i = 0; i <= maxLevel; i++) {
        AttributeLevels.push(i);
      }
    }
    return AttributeLevels;
  };

  private setNaturalKey = (): string | undefined => {
    var NaturalKey;

    if (this.props.DomainAttributes != undefined) {
      this.props.DomainAttributes.forEach((a) => {
        if (a.IsNaturalKey) NaturalKey = a.DomainAttributeName;
      });
    }
    return NaturalKey;
  };

  private _onDropDownFiscalyearValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const FiscalYear = Number(item == null ? "" : item.text);
    this.props.startGetAllEntities(FiscalYear);
    this.props.clearDatasetColumnsProps();
    this.setState({
      FiscalYear,
      SelectedEntities: [],
      SelectedEntitiesIDName: [],
      AttributeLevels: [],
      DomainAttributeLevels: [],
      NaturalKey: "",
    });
    this._AttributeLevelCount = 0;
  };

  private _onDomainNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const DomainName = newValue;
    this.setState({
      DomainName,
    });
  };

  private _onDropDownDomainTypeValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const DomainTypeID = Number(item == null ? "" : item.id);
    const DomainTypeName = item == null ? "" : item.text;
    this.setState({
      DomainTypeID,
      DomainTypeName,
    });
  };

  private _onDimTableNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const DimTableName = newValue;
    this.setState({
      DimTableName,
    });
  };

  private _onDropDownEntityValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    var EntityKey = item == null ? "" : item.key;
    var EntityID = Number(item == null ? "" : item.id);
    var EntityName = item == null ? "" : item.text;
    var obj = {
      id: EntityID,
      name: EntityName,
    };

    if (!item?.selected) {
      /*
            this.props.clearDatasetColumnsProps();
            this.setState({
                AttributeLevels: [],
                DomainAttributeLevels: [],
                NaturalKey: "",
            });
            this._AttributeLevelCount = 0;
            */

      for (var i = 0; i < this.state.AttributeLevels.length; i++) {
        var attributeLevel = this.state.DomainAttributeLevels[
          this.state.AttributeLevels[i]
        ];
        var entities = attributeLevel.Entities;
        if (entities != undefined) {
          for (var j = 0; j < entities.length; j++) {
            if (entities[j].name == EntityName) {
              alert(
                "The entity cannot be de-selected as it is selected by one of the Attribute Levels."
              );
              return;
            }
          }
        }
      }
    }

    if (
      this.state.SelectedEntities != undefined &&
      this.state.SelectedEntitiesIDName != undefined
    ) {
      this.setState({
        SelectedEntities: item?.selected
          ? [...this.state.SelectedEntities, EntityKey as string]
          : this.state.SelectedEntities.filter(
              (key: string) => key != EntityKey
            ),

        SelectedEntitiesIDName: item?.selected
          ? [...this.state.SelectedEntitiesIDName, obj]
          : this.state.SelectedEntitiesIDName.filter(
              (obj) => obj.id != EntityID
            ),
      });
    }
  };

  private _onNewEntityNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const NewEntityName = newValue;
    this.setState({
      NewEntityName,
    });
  };

  private _onDropDownNaturalKeyChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const NaturalKey = item == null ? "" : item.text;
    this.setState({
      NaturalKey,
    });
  };

  private _onIsCompensableMetricsEligibleValueChange = (
    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ): void => {
    this.setState({
      IsCompensableMetricsEligible: checked,
    });
  };

  private _clearbuttonClicked = (): void => {
    TelemetryProvider._trackEvent(newDomainClearButtonClickEvent);
    this.props.clearAllEntitiesProps();
    this.props.clearDatasetColumnsProps();
    this.props.clearSaveResultMsgProps(false);
    this.setState({
      DomainID: undefined,
      DomainName: "",
      DomainTypeID: undefined,
      DomainTypeName: "",
      DimTableName: "",
      SelectedEntities: [],
      SelectedEntitiesIDName: [],
      AttributeLevels: [],
      DomainAttributeLevels: [],
      NaturalKey: "",
      IsCompensableMetricsEligible: false,
    });
    this._AttributeLevelCount = 0;
  };

  private _savebuttonClicked = (): void => {
    TelemetryProvider._trackEvent(newDomainSaveButtonClickEvent);
    console.log(this.state); //for debugging

    if (
      this.state.DomainName == undefined ||
      this.state.DomainName == "" ||
      this.state.DomainTypeName == undefined ||
      this.state.DomainTypeID == undefined ||
      this.state.DimTableName == undefined ||
      this.state.DimTableName == "" ||
      this.state.SelectedEntitiesIDName.length == 0 ||
      this.state.DomainAttributeLevels.length == 0 ||
      this.state.AttributeLevels.length == 0 ||
      this.state.NaturalKey == undefined ||
      this.state.NaturalKey == ""
    ) {
      alert("Please enter all the mandatory fields.");
      return;
    }

    const AttributeLevels = [...this.state.AttributeLevels];
    const copyDomainAttributeLevels: IDomainAttributeLevel[] = getDeepCopy(
      this.state.DomainAttributeLevels
    );
    var DomainAttributeLevels: IDomainAttributeLevel[] = [];

    //Input validation in Attribute Levels
    for (var i = 0; i < AttributeLevels.length; i++) {
      var attributeLevel = copyDomainAttributeLevels[AttributeLevels[i]];
      if (attributeLevel.HierarchyLevelName == "") {
        alert(
          "Please enter Hierarchy Level Name for Attribute Level " + i + "."
        );
        return;
      }
      if (attributeLevel.DomainAttributes.length == 0) {
        alert(
          "Please select atleast one Domain Attribute in Attribute Level " +
            i +
            "."
        );
        return;
      }
      attributeLevel.DomainAttributes.forEach((da) => {
        if (da.AttributeDisplayName == "[Click to edit]")
          da.AttributeDisplayName = "";
        if (da.DefaultValue == "[Click to edit]") da.DefaultValue = "";
        if (da.DataTypeName == "[Click to edit]") da.DataTypeName = "";
      });
    }

    //Assigning final HierarchyLevelNo to the attribute levels as per re-ordering
    for (var i = 0; i < AttributeLevels.length; i++) {
      var attributeLevel = copyDomainAttributeLevels[AttributeLevels[i]];
      attributeLevel.HierarchyLevelNumber = i;
      DomainAttributeLevels.push(attributeLevel);
    }

    if (this.props.ActionType == "edit") {
      this.props.clearSaveResultMsgProps(true);
      this.props.startUpdateDomainToDB(
        this.props.DomainID != undefined ? this.props.DomainID : 0,
        { ...this.state, DomainAttributeLevels },
        this.props.startGetAllDomains
      );
    } else {
      this.props.clearSaveResultMsgProps(true);
      this.props.startSaveDomainToDB(
        { ...this.state, DomainAttributeLevels },
        this.props.startGetAllDomains
      );
    }
  };

  private _addLevelButtonClicked = () => {
    const DomainAttributeLevelObject: IDomainAttributeLevel = {
      id: this._AttributeLevelCount,
      index: this.state.DomainAttributeLevels.length,
      HierarchyLevelName: "",
      DomainAttributes: [],
    };

    this.setState({
      AttributeLevels: [
        ...this.state.AttributeLevels,
        this._AttributeLevelCount,
      ],
      DomainAttributeLevels: [
        ...this.state.DomainAttributeLevels,
        DomainAttributeLevelObject,
      ],
    });

    this._AttributeLevelCount = this._AttributeLevelCount + 1;
  };

  private _newEntityButtonClicked = (): void => {
    if (this.state.NewEntityName != undefined) {
      if (
        this.state.SelectedEntities != undefined &&
        this.state.SelectedEntitiesIDName != undefined
      ) {
        this.setState({
          SelectedEntities: [
            ...this.state.SelectedEntities,
            this.state.NewEntityName as string,
          ],
          SelectedEntitiesIDName: [
            ...this.state.SelectedEntitiesIDName,
            { id: 0, name: this.state.NewEntityName },
          ],
        });
      }
      this.props.addNewEntity(this.state.NewEntityName);
    }
  };

  private getAttributeLevelBoxStyle = (
    isDragging: boolean,
    draggableStyle: any
  ) => {
    return {
      background: isDragging ? "lightblue" : "white",
      ...draggableStyle,
    };
  };

  private getAttributeLevel = (id: number, index: number) => {
    var _EntityIDNameOptions = this.state.SelectedEntitiesIDName?.map(
      (value: any, index: number) => {
        return {
          key: value.name,
          text: value.name,
          id: value.id,
        };
      }
    );
    return (
      <DomainAttributeLevel
        id={id}
        index={index}
        InitDomainAttributeLevels={this.state.InitDomainAttributeLevels.find(
          (a) => a.HierarchyLevelNumber == id
        )}
        EntityIDNameOptions={_EntityIDNameOptions}
        _onAttributeLevelEntityChange={this._onAttributeLevelEntityChange}
        _onHierarchyLevelNameChange={this._onHierarchyLevelNameChange}
        _onDomainAttributesDetailsChange={this._onDomainAttributesDetailsChange}
        _deleteLevelButtonClicked={this._deleteLevelButtonClicked}
      />
    );
  };

  private onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const AttributeLevels = this.reorder(
      this.state.AttributeLevels,
      result.source.index,
      result.destination.index
    );

    this.setState({
      AttributeLevels,
    });
  };

  private reorder = (
    array: any[],
    startIndex: number,
    endIndex: number
  ): any[] => {
    const result = array;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  private handleDismiss() {
    this.props.updateIsEditModalVisible(false);
  }

  public render(): JSX.Element {
    //Need to change this to API
    var FiscalyearList = [
      { key: 2020, text: "2020" },
      { key: 2021, text: "2021" },
    ];

    const dropDownFiscalYear: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Fiscal Year:",
      onChange: this._onDropDownFiscalyearValueChange,
      options: FiscalyearList,
      required: true,
      selectedKey: this.state.FiscalYear,
      disabled: this.props.ActionType === "edit" ? true : false,
    };

    const dropDownDomainTypeNames: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Domain Type:",
      onChange: this._onDropDownDomainTypeValueChange,
      options:
        this.props.DomainTypeIDNameOptions != undefined
          ? this.props.DomainTypeIDNameOptions
          : [],
      required: true,
      selectedKey: this.state.DomainTypeName,
      disabled: this.props.ActionType === "edit" ? true : false,
    };

    const dropDownEntityNames: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Domain Source Entities:",
      dropdownWidth: 450,
      onChange: this._onDropDownEntityValueChange,
      options:
        this.props.EntityIDNameOptions != undefined
          ? this.props.EntityIDNameOptions
          : [],
      required: true,
      multiSelect: true,
      selectedKeys: this.state.SelectedEntities,
    };

    const dropDownNaturalKey: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Natural Key:",
      onChange: this._onDropDownNaturalKeyChange,
      options:
        this.state.AttributeLevels.length == 0
          ? []
          : this.state.DomainAttributeLevels[
              this.state.AttributeLevels[0]
            ].DomainAttributes.map((a: any) => {
              return {
                key: a.DomainAttributeName,
                text: a.DomainAttributeName,
              };
            }),
      required: true,
      selectedKey: this.state.NaturalKey,
    };

    return (
      <Modal
        isOpen={this.props.showModal}
        //onDismiss={this.handleDismiss}
        containerClassName={contentStyles.container}
        isBlocking={true}
      >
        <div className={contentStyles.header}>
          <span id="preview">New/Edit Domain</span>
          <IconButton
            styles={iconButtonStyles}
            iconName="Cancel"
            title="Close Modal"
            fontSize={12}
            ariaLabel="Close popup modal"
            onClick={this.handleDismiss}
          />
        </div>
        <div id="children" className={contentStyles.body}>
          <div className="new-domain">
            <div className="new-domain-buttons-list">
              <PrimaryButton
                text="Clear"
                onClick={this._clearbuttonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
              <PrimaryButton
                text="Save"
                onClick={this._savebuttonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
            </div>

            {this.getMsgDetails(
              this.props.saveResultMsg != undefined
                ? this.props.saveResultMsg
                : {}
            )}

            {/*<div className="seperator-cls">
                    <Dropdown {...dropDownFiscalYear} />
                </div>*/}

            <div className="seperator-cls">
              <div className="combine-cls">
                <div className="single-cls">
                  <TextField
                    label={"Domain Name:"}
                    required={true}
                    value={this.state.DomainName}
                    disabled={this.props.ActionType === "edit" ? true : false}
                    onChange={this._onDomainNameChange}
                    ariaLabel={"Domain Name"}
                  />
                </div>

                <div className="single-cls">
                  <Dropdown {...dropDownDomainTypeNames} />
                </div>

                <div className="single-cls">
                  <TextField
                    label={"Dim Table Name:"}
                    required={true}
                    value={this.state.DimTableName}
                    disabled={this.props.ActionType === "edit" ? true : false}
                    onChange={this._onDimTableNameChange}
                    ariaLabel={"Dim Table Name"}
                  />
                </div>
              </div>
            </div>

            <div className="seperator-cls">
              <Dropdown {...dropDownEntityNames} />
            </div>

            <div className="seperator-cls">
              <div className="combine-cls">
                <div className="entity-dropdown">
                  <TextField
                    placeholder={"Enter a new entity name"}
                    value={this.state.NewEntityName}
                    onChange={this._onNewEntityNameChange}
                  />
                </div>
                <PrimaryButton
                  className="new-entity-button"
                  title="Add Entity"
                  text="Entity"
                  iconProps={{ iconName: "Add", style: { fontSize: 12 } }}
                  onClick={this._newEntityButtonClicked}
                />
              </div>
            </div>

            <div className="seperator-cls">
              <Label required={true}>Attribute Levels:</Label>
            </div>

            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {this.state.AttributeLevels.map((id, index) => (
                      <Draggable key={id} draggableId={id + ""} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getAttributeLevelBoxStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div style={{ marginTop: "8px" }}>
                              {this.getAttributeLevel(id, index)}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="new-domain-buttons-list">
              <PrimaryButton
                className="add-level-button"
                title="New Attribute Level"
                text="Attribute Level"
                iconProps={{ iconName: "Add", style: { fontSize: 12 } }}
                onClick={this._addLevelButtonClicked}
              />
            </div>

            <div className="seperator-cls">
              <Dropdown {...dropDownNaturalKey} />
            </div>

            <div className="seperator-cls">
              <Checkbox
                label={"IsCompensableMetricsEligible"}
                checked={this.state.IsCompensableMetricsEligible}
                onChange={this._onIsCompensableMetricsEligibleValueChange}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  private _deleteLevelButtonClicked = (attributeLevelID: number): void => {
    var AttributeLevels: number[] = getDeepCopy(this.state.AttributeLevels);
    var index = AttributeLevels.findIndex((id) => id == attributeLevelID);
    AttributeLevels.splice(index, 1);

    this.setState({
      AttributeLevels,
    });
  };

  private _onAttributeLevelEntityChange = (
    attributeLevelID: number,
    item: IDropdownOption
  ): void => {
    var EntityID = Number(item.id);
    var EntityName = item.text;
    var obj = { id: EntityID, name: EntityName };

    var newArray: IDomainAttributeLevel[] = getDeepCopy(
      this.state.DomainAttributeLevels
    );
    var Entities = newArray[attributeLevelID].Entities;
    if (Entities == undefined) Entities = [];

    Entities = item.selected
      ? [...Entities, obj]
      : Entities.filter((e) => e.name != EntityName);
    newArray[attributeLevelID].Entities = Entities;
    this.setState({
      DomainAttributeLevels: newArray,
    });
  };

  private _onHierarchyLevelNameChange = (
    attributeLevelID: number,
    hierarchyLevelName: string
  ): void => {
    var newArray = getDeepCopy(this.state.DomainAttributeLevels);
    newArray[attributeLevelID].HierarchyLevelName = hierarchyLevelName;
    this.setState({
      DomainAttributeLevels: newArray,
    });
  };

  private _onDomainAttributesDetailsChange = (
    attributeLevelID: number,
    oldEntityName: string,
    oldDomainAttributeName: string,
    domainAttribute: any,
    isSelected: boolean
  ): void => {
    var newArray: IDomainAttributeLevel[] = getDeepCopy(
      this.state.DomainAttributeLevels
    );
    var index = newArray[attributeLevelID].DomainAttributes.findIndex(
      (a) =>
        a.EntityName == oldEntityName &&
        a.DomainAttributeName == oldDomainAttributeName
    );

    if (index != -1) {
      if (isSelected) {
        var attribute = newArray[attributeLevelID].DomainAttributes[index];
        attribute.DomainAttributeName = domainAttribute.DomainAttributeName;
        attribute.DomainAttributeQualifier =
          domainAttribute.DomainAttributeQualifier;
        attribute.AttributeDisplayName = domainAttribute.AttributeDisplayName;
        attribute.DefaultValue = domainAttribute.DefaultValue;
        attribute.EntityID = domainAttribute.EntityID == 0 ? this.state.SelectedEntitiesIDName?.find(e => e.name == domainAttribute.EntityName)?.id : domainAttribute.EntityID;
        attribute.EntityName = domainAttribute.EntityName;
        attribute.DataTypeName = domainAttribute.DataTypeName == undefined ? "" : domainAttribute.DataTypeName;
      } else {
        newArray[attributeLevelID].DomainAttributes.splice(index, 1);
      }
    } else {
      if (isSelected) {
        var attribute: IDomainAttribute = {
          DomainAttributeName: domainAttribute.DomainAttributeName,
          DomainAttributeQualifier: domainAttribute.DomainAttributeQualifier,
          AttributeDisplayName: domainAttribute.AttributeDisplayName,
          DefaultValue: domainAttribute.DefaultValue,
          EntityID: domainAttribute.EntityID,
          EntityName: domainAttribute.EntityName,
          DataTypeName: domainAttribute.DataTypeName == undefined ? "" : domainAttribute.DataTypeName,
        };
        newArray[attributeLevelID].DomainAttributes.push(attribute);
      }
    }
    this.setState({
      DomainAttributeLevels: newArray,
    });
  };

  private getMsgDetails = (msg: IDomainMsgInfo) => {
    return (
      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
        {msg.loading ? (
          <div>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : (
          ""
        )}
        {msg != undefined && msg.Result != undefined ? (
          msg.Result === 1 && msg.StatusCode === 200 ? (
            <span style={{ color: "green" }}> {msg.ErrorMessage} </span>
          ) : msg.Result === 0 || msg.Result === -2 ? (
            <span style={{ color: "red" }}> {msg.ErrorMessage} </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  componentWillUnmount() {
    this.props.clearDatasetColumnsProps();
    this.props.clearSaveResultMsgProps(false);
    this.props.clearAllEntitiesProps();
  }
}

const mapStateToProps = (state: AppState): INewDomainLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    DomainTypeIDNameOptions: state.domainDefinition.domainTypesList,
    EntityIDNameOptions: state.domainDefinition.allEntitiesList,
    saveResultMsg: state.domainDefinition.saveResultMsg,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): INewDomainLinkDispatchProps => ({
  startGetDomainTypes: bindActionCreators(startGetDomainTypes, dispatch),
  startGetAllEntities: bindActionCreators(startGetAllEntities, dispatch),
  addNewEntity: bindActionCreators(addNewEntity, dispatch),
  startSaveDomainToDB: bindActionCreators(startSaveDomainToDB, dispatch),
  startUpdateDomainToDB: bindActionCreators(startUpdateDomainToDB, dispatch),
  clearSaveResultMsgProps: bindActionCreators(
    clearSaveResultMsgProps,
    dispatch
  ),
  clearDatasetColumnsProps: bindActionCreators(
    clearDatasetColumnsProps,
    dispatch
  ),
  clearAllEntitiesProps: bindActionCreators(clearAllEntitiesProps, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDomain);
