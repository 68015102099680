import React, { useState } from "react";
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
} from "react-aad-msal";
import "./App.scss";
import { Provider, connect } from "react-redux";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import MintSystemConfigContainer from "./components/mint-systemconfig-container/mint-systemconfig-container";
import configureStore, { AppState } from "./redux/configureStore";
import { authProvider } from "./auth/auth-provider";
import AccountContext from "./auth/account-context";
import { InitialState } from "./App.initial-state";
import { bindActionCreators } from "redux";
import { AppActions } from "./redux/types/app-actions";
import { ThunkDispatch } from "redux-thunk";
import { IAppLinkDispatchProps, IAppProps, IAppLinkStateProps } from "./IApp";
import {
  startGetUserRoleData,
  startGetFiscalYearList,
  startGetCurrentFiscalYear,
} from "./App.service";
import AppInterceptor from "./App.Interceptor";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import * as TelemetryProvider from "./TelemetryProvider";

type Props = IAppProps & IAppLinkStateProps & IAppLinkDispatchProps;

export class App extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.startGetUserRoleData) {
      this.props.startGetUserRoleData();
      this.props.startGetFiscalYearList();
      this.props.startGetCurrentFiscalYear();
    }
  }

  componentDidCatch(error: any, info: any) {
    TelemetryProvider._trackException(error);
    console.log(error, info);
  }

  public render(): JSX.Element {
    return (
      <div className="App">
        <React.Fragment>
          <AccountContext.Provider value={this.props.accountInfo.account}>
            <AppInsightsContext.Provider value={reactPlugin}>
              <AppInterceptor />
            </AppInsightsContext.Provider>
          </AccountContext.Provider>
        </React.Fragment>
      </div>
    );
  }
}

// const mapStateToProps = (
//   state: AppState,
//   ownProps: IAppProps
// ): IAppLinkStateProps => {
//   return {
//     userRoleDetails: state.userProfile.userRoleDetails,
//   };
// };

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IAppProps
): IAppLinkDispatchProps => ({
  startGetUserRoleData: bindActionCreators(startGetUserRoleData, dispatch),
  startGetFiscalYearList: bindActionCreators(startGetFiscalYearList, dispatch),
  startGetCurrentFiscalYear: bindActionCreators(
    startGetCurrentFiscalYear,
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(App);
