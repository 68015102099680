import * as React from "react";
import { PrimaryButton } from "office-ui-fabric-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import PrimaryButtonView from "../../common/button/primary-button/primary-button.view";
import { ModalView } from "../../common/modal/modal.view";
import NewEntity from "./new-entity";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { entityOnboardingExportButtonClickEvent } from "./entity-onboarding.telemtetry-constants";

type EntityOnboardingViewProps = {
  totalEntities: {};
  columnDefs: any[];
  rowData: any[];
  getAllEntits: any;
  getDatasetColumnsList: any;
  editModalParams: any;
  showModal: boolean;
  updateIsEditModalVisible: any;
  gridCellKeyPress: any;
  isNewModal: boolean;
};

export class EntityOnboarding extends React.Component<
  EntityOnboardingViewProps
> {
  public render(): JSX.Element {
    return (
      <div>
        <div className="new-button-div">
          <PrimaryButtonView
            text="Create New Entity"
            onClick={() => {
              this.props.updateIsEditModalVisible(true);
            }}
          />
        </div>

        <div>
          <div
            className="ag-theme-balham"
            style={{ height: "450px", width: "100%", marginTop: "11px" }}
            tabIndex={-1}
          >
            <AgGridReact
              columnDefs={this.props.columnDefs}
              rowData={this.props.rowData}
              paginationPageSize={15}
              enableRangeSelection={true}
              pagination={true}
              onCellKeyDown={(event: any) => {
                this.props.gridCellKeyPress(event);
              }}
            ></AgGridReact>
          </div>
        </div>

        <div className="new-button-div" style={{ marginTop: "11px" }}>
          <PrimaryButtonView
            text="Export to Excel"
            onClick={this._onExportButtonClicked}
          />
        </div>
        {this.props.showModal === true ? (
          this.props.isNewModal ? (
            <NewEntity
              {...{
                getAllEntits: this.props.getAllEntits,
                getDatasetColumnsList: this.props.getDatasetColumnsList,
                totalEntities: this.props.totalEntities,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          ) : (
            <NewEntity
              {...{
                ...this.props.editModalParams,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          )
        ) : (
          <></>
        )}
      </div>
    );
  }

  private _onExportButtonClicked = () => {
    TelemetryProvider._trackEvent(entityOnboardingExportButtonClickEvent);
    OnboardingSummaryUtility._onExportButtonClicked(
      this.props.rowData,
      "EntityOnboarding"
    );
  };
}

export default EntityOnboarding;
