import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const entityOnboardingPageView: IPageViewTelemetry = {
  name: "Entity Onboarding",
  uri: "system/entity-onboarding",
};

export const entityOnboardingExportButtonClickEvent: IEventTelemetry = {
  name: "EntityOnboarding_ExportButtonClick",
};
export const entityOnboardingDeleteButtonClickEvent: IEventTelemetry = {
  name: "EntityOnboarding_DeleteButtonClick",
};
export const newEntitySaveButtonClickEvent: IEventTelemetry = {
  name: "EntityOnboarding_NewEntity_SaveButtonClick",
};
export const newEntityClearButtonClickEvent: IEventTelemetry = {
  name: "EntityOnboarding_NewEntity_ClearButtonClick",
};
// Done
