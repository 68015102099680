import React, { Component } from "react";
import { Provider, connect } from "react-redux";
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
} from "react-aad-msal";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/configureStore";
import { authProvider } from "./auth/auth-provider";

const store = configureStore();

const authenticate = (
  authenticationState: any,
  accountInfo: any,
  login: any
) => {
  if (authenticationState === AuthenticationState.Unauthenticated) {
    login();
    return <></>;
  } else if (authenticationState === AuthenticationState.Authenticated) {
    const params = {
      accountInfo: accountInfo,
    };
    return (
      <>
        <App {...params} />
      </>
    );
  }
};

const getAppBaseElement = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <Router history={createBrowserHistory()}>
          <AzureAD provider={authProvider} reduxStore={store}>
            {({
              accountInfo,
              authenticationState,
              login,
              error,
            }: IAzureADFunctionProps) => {
              return (
                <React.Fragment>
                  {authenticate(authenticationState, accountInfo, login)}
                </React.Fragment>
              );
            }}
          </AzureAD>
        </Router>
      </div>
    </Provider>
  );
};

ReactDOM.render(getAppBaseElement(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
