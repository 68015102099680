import { IDomains } from "../../components/onboarding/domain-definition/model/IDomainDefinition"
import { IDomainMsgInfo } from "../../components/onboarding/domain-definition/new-domain/model/INewDomain";

export const GET_ALL_DOMAINS = "GET_ALL_DOMAINS";
export const GET_DOMAIN_TYPES = "GET_DOMAIN_TYPES";
export const GET_ALL_ENTITIES = "GET_ALL_ENTITIES";
export const GET_DATASET_COLUMNS = "GET_DATASET_COLUMNS";
export const GET_NEW_DOMAIN_SAVE_SUCCESS = "GET_NEW_DOMAIN_SAVE_SUCCESS";
export const CLEAR_DATASET_COLUMNS = "CLEAR_DATASET_COLUMNS";
export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const ADD_NEW_ENTITY = "ADD_NEW_ENTITY";

export interface GetAllDomains {
    type: typeof GET_ALL_DOMAINS;
    allDomains: IDomains[];
}

export interface GetDomainTypes {
    type: typeof GET_DOMAIN_TYPES;
    domainTypesList: any;
}

export interface GetEntities {
    type: typeof GET_ALL_ENTITIES;
    allEntitiesList: any;
}

export interface AddNewEntity {
    type: typeof ADD_NEW_ENTITY;
    entity: string;
}

export interface GetDatasetColumnNames {
    type: typeof GET_DATASET_COLUMNS;
    attributeLevelId: number;
    datasetColumnNames: string[];
}

export interface GetNewDomainSaveSuccess {
    type: typeof GET_NEW_DOMAIN_SAVE_SUCCESS;
    saveResultMsg: IDomainMsgInfo;
}

export interface ClearDatasetColumns {
    type: typeof CLEAR_DATASET_COLUMNS;
}

export interface DeleteDomain {
    type: typeof DELETE_DOMAIN;
    allDomains: IDomains[];
}

export type DomainDefinitionActionTypes = 
  GetAllDomains
| GetDomainTypes
| GetEntities
| AddNewEntity
| GetDatasetColumnNames
| GetNewDomainSaveSuccess
| ClearDatasetColumns
| DeleteDomain;