import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../../redux/types/app-actions";
import { AppState } from "../../../../redux/configureStore";
import * as EntityRelationhshipDefinitionAPI from "../../../../api/entity-relationship-api";
import * as DomainDefinitionAPI from "../../../../api/domain-definition-api";
import * as EntityRelationshipActions from "../../../../redux/actions/entity-relationship-actions";
import * as DomainDefinitionActions from "../../../../redux/actions/domain-definition-actions";
import { IEntityRelationshipInput,IEntityRelationshipMsgInfo,INewEntityRelationshipState  } from "./model/INewEntityRelationship";
import { INewTranscationSource, ITransactionMsgInfo } from "./model/INewTransactionSource";



export const startGetAllEntities = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = DomainDefinitionAPI.getAllEntitiesList(fiscalYear)
            .then((response) => {
                //Response from API is in form: "<EntityID>,<EntityName>"
                const data: any[] = response.map((EntityIdName: string, index: any) => {
                    return ({
                        key: EntityIdName.split(",")[1], 
                        text: EntityIdName.split(",")[1],
                        id: EntityIdName.split(",")[0]
                    })
                });
                dispatch(EntityRelationshipActions.getAllEntities(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};


export const startGetDatasetColumnsList = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = EntityRelationhshipDefinitionAPI.getAllDataSetColumnList()
            .then((response) => {
                //Response from API is in form: "<EntityID>,<EntityName>"
               // const data: INewTranscationSource[] = response;
                dispatch(EntityRelationshipActions.getDatasetColumnsList(response));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

const setERStateToApi = (newEntityRelationshipState: INewEntityRelationshipState) => {
    const erInput : IEntityRelationshipInput[] = [];
    newEntityRelationshipState.EntityRelationshipRowData.forEach((elementPG) => {
    erInput.push(
        {
            Attribute1_Name:elementPG.AttributeName_1,
            Attribute1_Qualifier:elementPG.Attribute1_Qualifier,
            Attribute2_Name:elementPG.AttributeName_2,
            Attribute2_Qualifier:elementPG.Attribute2_Qualifier,
            DimRoleName:elementPG.DimRoleName,
            Entity1_ID:elementPG.EntityID_1,
            Entity2_ID:elementPG.EntityID_2,
            Entity1_Name:elementPG.EntityName_1,
            Entity2_Name:elementPG.EntityName_2,
            EntityRelationshipID:elementPG.EntityRelationshipID,
            JoinType:elementPG.JoinType
        }
    );
  
} );
return erInput;
}

export const startSaveEntityRelationshipToDB = (newEntityRelationshipState: INewEntityRelationshipState, startGetAllEntityRelationships: ()=>void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const newER:IEntityRelationshipInput[] = setERStateToApi(newEntityRelationshipState);
        const res = EntityRelationhshipDefinitionAPI.saveEntityRelationshipToDB(newER)
            .then((response) => {
                const result: IEntityRelationshipMsgInfo = response;
                let tempResult: IEntityRelationshipMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "EntityRelationships saved successfully. Please proceeed to create PERSPECTIVE, if applicable" :
                                                (result.Result === 0 ? "Following EntityRelationships NOT saved successfully: \n" + result.ErrorMessage : ""));
                    dispatch(EntityRelationshipActions.getNewERSaveSuccessAction(tempResult));

                    if (startGetAllEntityRelationships) 
                        startGetAllEntityRelationships();
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const startSaveTransactionSourceToDB = (transactionSourceName: string, columnList: string,fiscalyear: number, startGetAllEntities: ()=>void,startGetDatasetColumns:()=>void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = EntityRelationhshipDefinitionAPI.saveNewTransactionSourceToDB(transactionSourceName,columnList,fiscalyear)
            .then((response) => {
                const result: IEntityRelationshipMsgInfo = response;
                let tempResult: IEntityRelationshipMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "Transaction Source saved successfully" :
                                                (result.Result === 0 ? "Transaction Source NOT saved successfully." : ""));
                    dispatch(EntityRelationshipActions.getNewTransactionSaveSuccessAction(tempResult));
                   /* var data:any;
                    var colData:any;
                    dispatch(EntityRelationshipActions.getAllEntities(data));
                    dispatch(EntityRelationshipActions.getDatasetColumns(colData));
                    */
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const startUpdateERToDB = (newEntityRelationshipState: INewEntityRelationshipState, startGetAllEntityRelationships: ()=>void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const updatedER :IEntityRelationshipInput[] = setERStateToApi(newEntityRelationshipState);
        const res = EntityRelationhshipDefinitionAPI.updateEntityRelationshipToDB(updatedER)
            .then((response) => {
                const result: IEntityRelationshipMsgInfo = response;
                let tempResult: IEntityRelationshipMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "EntityRelationships updated successfully. Please proceeed to create/edit PERSPECTIVE, if applicable" :
                                                (result.Result === 0 ? "Following EntityRelationships NOT updated successfully: \n" + result.ErrorMessage : ""));
    
                        dispatch(EntityRelationshipActions.getNewERSaveSuccessAction(tempResult));

                        if (startGetAllEntityRelationships) 
                            startGetAllEntityRelationships();
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const clearSaveResultMsgProps = (loading: boolean) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        var tempResult: IEntityRelationshipMsgInfo = {
            Result: 5,
            StatusCode: 0,
            ErrorMessage: "",
            loading: loading,
        };
        dispatch(EntityRelationshipActions.getNewERSaveSuccessAction(tempResult));
    };
};

export const clearTransactionSaveResultMsgProps = (loading: boolean) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        var tempResult: ITransactionMsgInfo = {
            Result: 5,
            StatusCode: 0,
            ErrorMessage: "",
            loading: loading,
        };
        dispatch(EntityRelationshipActions.getNewTransactionSaveSuccessAction(tempResult));
    };
};

export const clearDatasetColumnsProps = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        dispatch(EntityRelationshipActions.clearDatasetColumns());
    };
};

export const clearAllEntitiesProps = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const data: any[] = [];
        dispatch(DomainDefinitionActions.getAllEntities(data));
    };
}

export const startGetDatasetColumns = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
       //Sending list of DatasetIDs to API in the form of a string separated by ','
        const res = EntityRelationhshipDefinitionAPI.getDatasetColumns(fiscalYear)
            .then((response) => {
                const data: any[] = response.map((EntityIdName: string, index: any) => {
                    return ({
                        key: EntityIdName.split(",")[1], 
                        text: EntityIdName.split(",")[1],
                        id: EntityIdName.split(",")[0]
                    })
                });
                dispatch(EntityRelationshipActions.getDatasetColumns(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

export const startGetDatasetColumnsWithAlias = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
       //Sending list of DatasetIDs to API in the form of a string separated by ','
        const res = EntityRelationhshipDefinitionAPI.getDatasetColumnsWithAlias(fiscalYear)
            .then((response) => {
                const data: any[] = response.map((EntityIdName: string, index: any) => {
                    return ({
                        key: EntityIdName.split(",")[1], 
                        text: EntityIdName.split(",")[1],
                        id: EntityIdName.split(",")[0]
                    })
                });
                dispatch(EntityRelationshipActions.getDatasetColumnsWithAlias(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

export const startGetTransactionDatasetDetails = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = EntityRelationhshipDefinitionAPI.getTransactionDatasetDetails(fiscalYear)
            .then((response) => {
                const data: any[] = response.map((DatasetIDDetails: string, index: any) => {
                    return ({
                        DatasetID: DatasetIDDetails.split(",")[0], 
                        IsTransactionSource: DatasetIDDetails.split(",")[1] == '1' ? true : false
                    })
                });
                dispatch(EntityRelationshipActions.getTransactionDatasetDetails(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};


