import React from "react";
import {
  IEntityRelationship,
  EntityRelationshipProps,
  EntityRelationshipLinkStateProps,
  EntityRelationshipLinkDispatchProps,
  IERSummaryRowData,
  IERObject,
} from "./model/IEntityRelationship";
import { AppState } from "../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import { startGetAllEntityRelationships } from "./entity-relationship.service";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import { EntityRelationship } from "./entity-relationship.view";
import { ColDef } from "ag-grid-community";
import { ModalView } from "../../common/modal/modal.view";
import IconButtonView from "../../common/button/icon-button/icon-button.view";
import { IconButton } from "office-ui-fabric-react";
import { getDeepCopy } from "../../../util/javascript-functions";
import NewER from "../entityrelationship-definition/new-entityrelationship/new-entityrelationship.controller";
import * as TelemetryProvider from "../../../TelemetryProvider";
import {
  entityRelationshipDeleteButtonClickEvent,
  entityRelationshipPageView,
} from "./entity-relationship.telemtetry-constants";

type Props = EntityRelationshipProps &
  EntityRelationshipLinkStateProps &
  EntityRelationshipLinkDispatchProps;

export class EntityRelationshipList extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    TelemetryProvider._trackPageView(entityRelationshipPageView);
    this.state = {
      editModalParams: undefined,
      isEditModalVisible: false,
      isNewModal: false,
    };
    this.getEditControl = this.getEditControl.bind(this);
    this.getDeleteControl = this.getDeleteControl.bind(this);
    this.updateIsEditModalVisible = this.updateIsEditModalVisible.bind(this);
  }

  componentDidMount() {
    this.props.startGetAllEntityRelationships();
  }

  public render(): JSX.Element {
    if (
      this.props.allEntityRelationships != undefined ||
      this.props.allEntityRelationships != null
    ) {
      var columnDefs: any[] = [];
      var rowData: any[] = [];
      columnDefs = this.getColDefinitions(columnDefs);
      rowData = this.props.allEntityRelationships.filter((e) => e.FiscalYear == this.props.fiscalYear);
      var params = {
        allEntityRelationships: this.props.allEntityRelationships,
        columnDefs: columnDefs,
        rowData: rowData,
        startGetAllEntityRelationships: this.props
          .startGetAllEntityRelationships,
        editModalParams: this.state.editModalParams,
        showModal: this.state.isEditModalVisible,
        updateIsEditModalVisible: this.updateIsEditModalVisible,
        gridCellKeyPress: this.gridCellKeyPress,
        isNewModal: this.state.isNewModal,
      };
      return <EntityRelationship {...params} />;
    } else {
      return (
        <>
          <div>Loading...</div>
        </>
      );
    }
  }

  private gridCellKeyPress = (event: any) => {
    let keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "Edit":
          // TelemetryProvider._trackEvent(editActivityClickEvent);
          const _editParams = {
            ActionType: "edit",
            FiscalYear: event.data.FiscalYear,
            EntityID: event.data.EntityID,
            EntityName: event.data.EntityName,
            RelatedEntities: event.data.RelatedEntities,
            RelatedEntityObjects: event.data.RelatedEntityObjects,
            startGetAllEntityRelationships: this.props
              .startGetAllEntityRelationships,
          };
          this.setState({
            editModalParams: _editParams,
            isEditModalVisible: true,
          });
          break;
        case "Delete":
          if (event.data.IsActive) {
            this.deleteButtonClicked(event.data);
          }
          break;
        default:
          return;
      }
    }
  };

  private deleteButtonClicked = (params: any) => {
    TelemetryProvider._trackEvent(entityRelationshipDeleteButtonClickEvent);
    var clonedEntityRelationshipState = getDeepCopy(
      this.props.allEntityRelationships
    );
    var tempVal = window.confirm(
      "Do you want to delete this Entity Relationship?"
    );
    if (tempVal == true) {
      //this.props.startDeleteEntityRelationshipById(params.EntityRelationshipID, clonedEntityRelationshipState);
    }
  };

  private updateIsEditModalVisible(isNewModal: boolean = false) {
    this.setState({
      isEditModalVisible: !this.state.isEditModalVisible,
      isNewModal: isNewModal,
    });
  }

  private getEditControl = (params: any) => {
    var _editParams = {
      ActionType: "edit",
      FiscalYear: params.data.FiscalYear,
      EntityID: params.data.EntityID,
      EntityName: params.data.EntityName,
      RelatedEntities: params.data.RelatedEntities,
      RelatedEntityObjects: params.data.RelatedEntityObjects,
      startGetAllEntityRelationships: this.props.startGetAllEntityRelationships,
    };
    return (

        <IconButtonView
          iconName="SingleColumnEdit"
          fontSize={12}
          title="Edit"
          onClick={() => {
            this.setState({
              editModalParams: _editParams,
              isEditModalVisible: true,
              isNewModal: false,
            });
          }}
          ariaLabel="Edit"
        />

    );
  };

  private getDeleteControl = (params: any) => {
    return (
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "20%" }}>
          <IconButton
            iconProps={{
              iconName: "Delete",
              style: { fontSize: 12 },
            }}
            title="Delete"
            onClick={(event) => this.deleteButtonClicked(params.data)}
            ariaLabel="Delete"
            disabled={!params.data.IsActive}
          />
        </div>
      </div>
    );
  };

  private getColDefinitions(colDefns: ColDef[]) {
    colDefns = [
      {
        field: "FiscalYear",
        flex: 0.5,
        sortable: true,
        filter: true,
        resizable: true,
      },
      { field: "EntityID", hide: true },
      {
        field: "EntityName",
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "RelatedEntities",
        flex: 4,
        sortable: true,
        filter: true,
        resizable: true,
      },
      { field: "RelatedEntityObjects", hide: true },
      {
        headerName: "Edit",
        width: 60,
        cellRendererFramework: this.getEditControl,
      },
      {
        headerName: "Delete",
        width: 60,
        cellRendererFramework: this.getDeleteControl,
      },
    ];
    return colDefns;
  }


}

const mapStateToProps = (state: AppState): EntityRelationshipLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    allEntityRelationships: state.entityRelationship.allEntityRelationships,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): EntityRelationshipLinkDispatchProps => ({
  startGetAllEntityRelationships: bindActionCreators(
    startGetAllEntityRelationships,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityRelationshipList);
