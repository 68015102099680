import React from "react";
import SideNavBar from "../common/side-nav-bar-panel/side-nav-bar";
import nodeList from "../common/side-nav-bar-panel/nav-bar-data/node-list";
import MintHeader from "../common/header/mint-header";
import { Switch, Route } from "react-router-dom";
import "./mint-systemconfig-container.scss";
import AccountContext from "../../auth/account-context";
import HorizontalNavBar from "../common/horizontal-nav-bar/horizontal-nav-bar.controller";
import EntityOnboardingList from "../onboarding/entity-onboarding/entity-onboarding-controller";
import DomainDefinitionList from "../onboarding/domain-definition/domain-definition.controller";
import PerspectiveDefinitionList from "../onboarding/perspective-definition/perspective-definition.controller";
import MeasureDefinitionList from "../onboarding/measure-definition/measure-definition.controller";
import EntityRelationshipList from "../onboarding/entity-relationship/entity-relationship.controller";
import Loading from "../loading/loading";
import RecurrencePatternController from "../common/recurrence-pattern/recurrence-pattern.controller";
import { ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";

const MintSystemConfigContainer = (props: any) => {
  return (
    <div className="mint-systemconfig-container">
      <div className="mint-systemconfig-header-wrapper">
        <SideNavBar node_data={nodeList} {...props} />
        <AccountContext.Consumer>
          {(data) => <MintHeader account={data} />}
        </AccountContext.Consumer>
      </div>
      <div className="horizontal-nav-bar-placeholder">
        <HorizontalNavBar {...props} />
      </div>

      <ScrollablePane
        className="component-scroll-config"
        scrollbarVisibility={ScrollbarVisibility.auto}
      >
        <div className="component-placeholder">
          <Switch>
            <Route
              path="/system/entity-onboarding"
              component={EntityOnboardingList}
            ></Route>
            <Route
              path="/system/entity-relationship"
              component={EntityRelationshipList}
            ></Route>
            <Route
              path="/system/domain-definition"
              component={DomainDefinitionList}
            ></Route>
            <Route
              path="/system/perspective-definition"
              component={PerspectiveDefinitionList}
            ></Route>
            <Route path='/' component={() => {window.location.href='/system/entity-onboarding'; return null;}}></Route>
          </Switch>
        </div>
      </ScrollablePane>
    </div>
  );
};
export default MintSystemConfigContainer;
