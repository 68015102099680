import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as MeasureDefinitionAPI from "../../../api/measure-definition-api";
import * as MeasureDefinitionActions from "../../../redux/actions/measure-definition-actions";
import "../../onboarding/common/onboarding-summary.scss";
import { IMeasure } from "./model/IMeasure";


export const startGetAllMeasures = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = MeasureDefinitionAPI.getAllMeasures()
            .then((value) => {
                const data: IMeasure[] = value;
                dispatch(MeasureDefinitionActions.getAllMeasures(data.sort((a,b)=> a.PerspectiveMeasure.localeCompare(b.PerspectiveMeasure))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

