import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as PerspectiveDefinitionAPI from "../../../api/perspective-definition-api";
import * as PerspectiveDefinitionActions from "../../../redux/actions/perspective-definition-actions";
import "../../onboarding/common/onboarding-summary.scss";
import { IPerspective } from "./model/IPerspectiveDefinition";


export const startGetAllPerspectives = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = PerspectiveDefinitionAPI.getAllPerspectives()
            .then((value) => {
                const data: IPerspective[] = value;
                dispatch(PerspectiveDefinitionActions.getAllPerspectives(data));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

