import * as React from "react";
import { PrimaryButton } from "office-ui-fabric-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import PrimaryButtonView from "../../common/button/primary-button/primary-button.view";
import { ModalView } from "../../common/modal/modal.view";
import NewPerspective from "./new-perspective/new-perspective.controller";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import NewTransactionControl from "../../onboarding/entityrelationship-definition/new-TransactionSource";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { perspectiveDefinitionExportButtonClickEvent } from "./perspective-definition.telemtetry-constants";

type PerspectiveDefinitionViewProps = {
  allPerspectives: any;
  columnDefs: any[];
  rowData: any[];
  startGetAllPerspectives: () => void;
  editModalParams: any;
  showModal: boolean;
  updateIsEditModalVisible: any;
  gridCellKeyPress: any;
  isNewModal: boolean;
};

var newTranscationControl = () => {
  return (
    <PrimaryButton
      text="+ New Transaction Source"
      onClick={() => {}}
    ></PrimaryButton>
  );
};

var newTransactionSourceModal = {
  title: "Create new Transaction Source",
  control: newTranscationControl(),
  onDismissCloseModal: false,
};

export class PerspectiveDefinition extends React.Component<
  PerspectiveDefinitionViewProps
> {
  public render(): JSX.Element {
    return (
      <div>
        <div className="new-button-div">
          <div>
            <PrimaryButtonView
              text="Create New Perspective"
              onClick={() => {
                this.props.updateIsEditModalVisible(true);
              }}
            />
          </div>
        </div>

        <div>
          <div
            className="ag-theme-balham"
            style={{ height: "450px", width: "100%", marginTop: "11px" }}
          >
            <AgGridReact
              columnDefs={this.props.columnDefs}
              rowData={this.props.rowData}
            
            //  paginationPageSize={15}
              enableRangeSelection={true}
              pagination={true}
              onCellKeyDown={(event: any) => {
                this.props.gridCellKeyPress(event);
              }}
            ></AgGridReact>
          </div>
        </div>

        <div className="new-button-div" style={{ marginTop: "11px" }}>
          <PrimaryButtonView
            text="Export to Excel"
            onClick={this._onExportButtonClicked}
          />
        </div>
        {this.props.showModal === true ? (
          this.props.isNewModal ? (
            <NewPerspective
              {...{
                startGetAllPerspectives: this.props.startGetAllPerspectives,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          ) : (
            <NewPerspective
              {...{
                ...this.props.editModalParams,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          )
        ) : (
          <></>
        )}
      </div>
    );
  }

  private _onExportButtonClicked = () => {
    TelemetryProvider._trackEvent(perspectiveDefinitionExportButtonClickEvent);
    OnboardingSummaryUtility._onExportButtonClicked(
      this.props.rowData,
      "PerspectiveDefinition"
    );
  };
}

export default PerspectiveDefinition;
