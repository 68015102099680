import { ISideNavBar } from "../model/ISideNavBar";
import { Configurations } from "../../../../App.global";

const nodeList: ISideNavBar[] = [
  {
    iconName: "Processing",
    iconDescription: "System Configuration",
    navItem: {
      name: "System Configuration",
      url: "/system/entity-onboarding",
      children: [
        {
          name: "Entity Onboarding",
          url: "/system/entity-onboarding",
          children: [],
        },
        {
          name: "Entity Relationship",
          url: "/system/entity-relationship",
          children: [],
        },
        {
          name: "Domain Definition",
          url: "/system/domain-definition",
          children: [],
        },
        {
          name: "Perspective Definition",
          url: "/system/perspective-definition",
          children: [],
        },
      ],
    },
  },
  {
    iconName: "Train",
    iconDescription: "Orchestration",
    navItem: {
      name: "Orchestration",
      url: Configurations.mintOrchUIBaseUrl,
      target: "_blank",
      rel: "noopener noreferrer",
      children: [],
    },
  },
];

export default nodeList;
