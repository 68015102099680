import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as EntityRelationshipAPI from "../../../api/entity-relationship-api";
import * as EntityRelationshipActions from "../../../redux/actions/entity-relationship-actions";
import "../../onboarding/common/onboarding-summary.scss";
import { IEntityRelationship, IERObject, IERSummaryRowData } from "./model/IEntityRelationship";


export const startGetAllEntityRelationships = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = EntityRelationshipAPI.getAllEntityRelationships()
            .then((value) => {
                //mapping of ERs to respective entities
                var entityRelationships: IERSummaryRowData[] = [];
                var map: Map<string, IERObject[]> = new Map();

                const data: IEntityRelationship[] = value;
                data.forEach((er) => {
                    //Entity 1
                    var Entity_ID_Name_1 =
                        er.FiscalYear +
                        "," +
                        er.Entity1_ID +
                        "," +
                        er.Entity1_Name?.substr(5).trim();
                    var value = map.get(Entity_ID_Name_1);
                    if (value == undefined) value = [];

                    value.push({
                        FiscalYear: er.FiscalYear,
                        EntityRelationshipID: er.EntityRelationshipID,
                        Attribute1_Name: er.Attribute1_Name,
                        Attribute1_Qualifier: er.Attribute1_Qualifier,
                        Entity2_ID: er.Entity2_ID,
                        Entity2_Name: er.Entity2_Name?.substr(5).trim(),
                        Attribute2_Name: er.Attribute2_Name,
                        Attribute2_Qualifier: er.Attribute2_Qualifier,
                        DimRoleName: er.DimRoleName,
                        JoinType: er.JoinType,
                        IsActive: er.IsActive,
                    });
                    map.set(Entity_ID_Name_1, value);

                    //Entity 2
                    var Entity_ID_Name_2 =
                        er.FiscalYear +
                        "," +
                        er.Entity2_ID +
                        "," +
                        er.Entity2_Name?.substr(5).trim();
                    var value = map.get(Entity_ID_Name_2);
                    if (value == undefined) value = [];

                    value.push({
                        FiscalYear: er.FiscalYear,
                        EntityRelationshipID: er.EntityRelationshipID,
                        Attribute1_Name: er.Attribute2_Name,
                        Attribute1_Qualifier: er.Attribute2_Qualifier,
                        Entity2_ID: er.Entity1_ID,
                        Entity2_Name: er.Entity1_Name?.substr(5).trim(),
                        Attribute2_Name: er.Attribute1_Name,
                        Attribute2_Qualifier: er.Attribute1_Qualifier,
                        DimRoleName: er.DimRoleName,
                        JoinType: er.JoinType,
                        IsActive: er.IsActive,
                    });
                    map.set(Entity_ID_Name_2, value);
                });

                map.forEach((value, key) => {
                    entityRelationships.push({
                        FiscalYear: Number((key.split(",")[0])),
                        EntityID: Number((key.split(",")[1])),
                        EntityName: key.split(",")[2],
                        RelatedEntities: value.map((e) => e.Entity2_Name).join(),
                        RelatedEntityObjects: value,
                    });
                });

                dispatch(EntityRelationshipActions.getAllEntityRelationships(entityRelationships));
            })
            .catch((error) => {
                const err = error;
            })
    };
};



