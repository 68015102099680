import React, { Dispatch } from "react";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as EntityOnboardingApi from "../../../api/entity-onboarding-api";
import * as EntityOnboardingActions from "../../../redux/actions/entity-onboarding-actions";
import { IColumn, IconButton } from "office-ui-fabric-react";
import { IEntityOnboarding, IEntityOnboardingList } from "./model/IEntityOnboarding";
import "../../onboarding/common/onboarding-summary.scss";
import PrimaryButtonView from "../../common/button/primary-button/primary-button.view";
import { ModalView } from "../../common/modal/modal.view";
import NewEntity from '../entity-onboarding/new-entity';
import IconButtonView from "../../common/button/icon-button/icon-button.view";
import { Entity } from "../../../models/Entity";

export const startGetAllEntities = () => {
    return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
       // const entities_temp = EntityOnboardingApi.startGetAllEntities();

        const abc = EntityOnboardingApi.startGetAllEntities()
            .then((value) => {
                const entities: Entity[] = value;
                var totalEntities: IEntityOnboardingList = {
                    totEntities: entities,
                };
                dispatch(
                    EntityOnboardingActions.getAllEntities(totalEntities)
                );
            })
            .catch((error) => {
                const err = error;
            });
    };
};

export const deleteEntityById = (params: any, clonedState: IEntityOnboardingList) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        EntityOnboardingApi.deleteEntityById(params)
            .then((value) => {
                const result: any = value;
                if (result) {
                    const entityObj = clonedState.totEntities;
                    const entObj1 = entityObj.find(({ DataSetID }) => DataSetID === params);
                    if (entObj1 != null || entObj1 != undefined) entObj1.IsActive = false;
                    var totalEntitiesAfterDelete: IEntityOnboardingList = {
                        totEntities: entityObj,
                    };
                    dispatch(EntityOnboardingActions.deleteEntity(totalEntitiesAfterDelete));
                }
            })
            .catch((error) => {
                const err = error;
            });
    };
}

