import { IEntityRelationship, IERSummaryRowData } from "../../components/onboarding/entity-relationship/model/IEntityRelationship";
import { IEntityRelationshipMsgInfo } from "../../components/onboarding/entityrelationship-definition/new-entityrelationship/model/INewEntityRelationship";
import { ITransactionMsgInfo } from "../../components/onboarding/entityrelationship-definition/new-entityrelationship/model/INewTransactionSource";

export const GET_ALL_ENTITYRELATIONSHIPS = "GET_ALL_ENTITYRELATIONSHIPS";
export const GET_NEW_ER_SAVE_SUCCESS="GET_NEW_ER_SAVE_SUCCESS";
export const CLEAR_DATASET_COLUMNS="CLEAR_DATASET_COLUMNS";
export const GET_ALL_ENTITIES = "GET_ALL_ENTITIES";
export const GET_DATASET_COLUMNS="GET_DATASET_COLUMNS";
export const GET_DATASET_COLUMNS_WITH_ALIAS="GET_DATASET_COLUMNS_WITH_ALIAS";
export const GET_DATASET_COLUMNS_LIST="GET_DATASET_COLUMNS_LIST";
export const GET_NEW_TRANSACTIONSOURCE_SAVE_SUCCESS="GET_NEW_TRANSACTIONSOURCE_SAVE_SUCCESS";
export const GET_TRANSACTION_DATASET_DETAILS = "GET_TRANSACTION_DATASET_DETAILS";


export interface GetAllEntityRelationships {
    type: typeof GET_ALL_ENTITYRELATIONSHIPS;
    allEntityRelationships: IERSummaryRowData[];
}

export interface GetNewERSaveSuccess {
    type: typeof GET_NEW_ER_SAVE_SUCCESS;
    saveResultMsg: IEntityRelationshipMsgInfo;
}

export interface GetNewTransactionSaveSuccess {
    type: typeof GET_NEW_TRANSACTIONSOURCE_SAVE_SUCCESS;
    saveTransResultMsg: ITransactionMsgInfo;
}

export interface ClearDatasetColumns {
    type: typeof CLEAR_DATASET_COLUMNS;
}

export interface GetEntities {
    type: typeof GET_ALL_ENTITIES;
    allEntitiesList: any;
}

export interface GetDatasetColumnNames {
    type: typeof GET_DATASET_COLUMNS;
    datasetColumnNames: string[];
}

export interface GetDatasetColumnNamesList {
    type: typeof GET_DATASET_COLUMNS_LIST;
    datasetColumnNamesList: any;
}

export interface GetDatasetColumnNamesWithAlias {
    type: typeof GET_DATASET_COLUMNS_WITH_ALIAS;
    datasetColumnNamesWithAlias: string[];
}

export interface GetTransactionDatasetDetails {
    type: typeof GET_TRANSACTION_DATASET_DETAILS;
    transactionDatasetDetails: any;
}

export type EntityRelationshipActionTypes = 
GetAllEntityRelationships|
GetNewERSaveSuccess|
GetEntities|
GetDatasetColumnNames|
GetNewTransactionSaveSuccess|
GetDatasetColumnNamesWithAlias|
GetDatasetColumnNamesList |
GetTransactionDatasetDetails;

