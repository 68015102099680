import React from "react";
import {
  IDropdownOption,
  Label,
  TextField,
  PrimaryButton,
  FontIcon,
  Checkbox,
  Spinner,
  SpinnerSize,
  DefaultButton,
  DetailsList,
  IColumn,
  Selection,
  SelectionMode,
  IDetailsRowProps,
  IRenderFunction,
  DetailsRow,
  DetailsRowFields,
  IDetailsRowFieldsProps,
} from "office-ui-fabric-react";
import {
  contentStyles,
  iconButtonStyles,
} from "../../../common/modal/modal.style";
import IconButton from "../../../common/button/icon-button/icon-button.controller";
import IconButtonView from "../../../common/button/icon-button/icon-button.view";
import { IDropdownPropsCustom } from "../../../common/dropdown/models/IDropdown";
import Dropdown from "../../../common/dropdown/dropdown.controller";
import { connect } from "react-redux";
import { startGetAllMeasures } from "../../measure-definition/measure-definition.service";
import {
  startGetAllTransactionalEntities,
  startGetAllMeasuresTypes,
  startGetAllPerpectiveDetails,
  startGetAllUnitTypes,
  startGetAllUnits,
  startGetDatasetColumns,
  clearAllEntitiesProps,
  clearDatasetColumnsProps,
  clearSaveResultMsgProps,
  startUpdateMeasureToDB,
} from "./new-measure.service";
import {
  IMeasureAttribute,
  IMeasureInput,
  INewMeasureProps,
  INewMeasureState,
  NewMeasureLinkDispatchProps,
  NewMeasureLinkStateProps,
} from "./model/INewMeasure";
import { AppState } from "../../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import { bindActionCreators } from "redux";
import { getDeepCopy } from "../../../../util/javascript-functions";
import {
  IDetailsListProps,
  IDetailsRowStyles,
  IDetailsRowBaseProps,
  SearchBox,
  DetailsRowCheck,
  ScrollablePane,
  ScrollbarVisibility,
} from "office-ui-fabric-react";
import { IMeasureMsgInfo } from "../model/IMeasure";
import { updateInferTypeNode } from "typescript";
import { IPerspectiveMeasure } from "../../perspective-definition/new-perspective/model/INewPerspective";
import ComboBox from "../../../common/combo-box/combo-box.controller";
import * as TelemetryProvider from "../../../../TelemetryProvider";
import {
  newMeasureAddLevelButtonClickEvent,
  newMeasureClearButtonClickEvent,
} from "../../perspective-definition/perspective-definition.telemtetry-constants";

type Props = INewMeasureProps &
  NewMeasureLinkDispatchProps &
  NewMeasureLinkStateProps;

class NewMeasure extends React.Component<Props, INewMeasureState> {
  constructor(props: Props) {
    super(props);
    var newArray: IMeasureAttribute[] = [];
    newArray[0] = {
      id: 0,
      EntityID: 0,
      EntityName: "",
      MeasureAttributeName: "",
      MeasureName: this.props.MeasureName,
    };
    if (this.props.FiscalYearID != undefined) 
      this.props.startGetAllMeasures();
    //this.props.startGetAllMeasuresTypes();
    //this.props.startGetAllPerpectiveDetails(this.props.fiscalYear);
    //this.props.startGetAllUnits();
    //this.props.startGetAllUnitTypes();
    this.props.startGetAllTransactionalEntities(this.props.fiscalYear);
    this.props.startGetDatasetColumns(this.props.fiscalYear);

    this.state = {
      ActionType: this.props.ActionType,
      FiscalYearID: this.props.FiscalYearID,
      MeasureID: this.props.MeasureID,
      MeasureName: this.props.MeasureName ? this.props.MeasureName : "",
      MeasureTypeName: this.props.MeasureTypeName
        ? this.props.MeasureTypeName
        : "",
      UnitName: this.props.UnitName ? this.props.UnitName : "",
      UnitTypeName: this.props.UnitTypeName ? this.props.UnitTypeName : "",
      PerspectiveName: this.props.PerspectiveName
        ? this.props.PerspectiveName
        : "",
      // EntityName:this.props.EntityName?this.props.EntityName:"",
      //  AttributeName:this.props.AttributeName?this.props.AttributeName:"",
      MeasureAttributes:
        this.props.MeasureAttributes != undefined
          ? this.props.MeasureAttributes
          : newArray,
    };

    //this.getMeasureAttributes();

    this.onRenderRow = this.onRenderRow.bind(this);
    this.renderRowFields = this.renderRowFields.bind(this);
  }

  private getMeasureAttributes() {
    var MeasureAttributes: IMeasureAttribute[] = [];
    if (this.state.MeasureAttributes != null) {
      var count = 0;
      this.state.MeasureAttributes.forEach((element) => {
        const mAttrib: IMeasureAttribute = {
          id: count,
          EntityID: element.EntityID,
          EntityName: element.EntityName,
          MeasureName: element.MeasureName,
          MeasureAttributeName: element.MeasureAttributeName,
        };
        MeasureAttributes.push(mAttrib);
        count = count + 1;
      });
      this.setState({
        MeasureAttributes,
      });
    }
  }

  private _onDropDownFiscalyearValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const FiscalYear = Number(item == null ? "" : item.text);
    var data: any = [];
    data = this.props.startGetAllPerpectiveDetails(2021);
    /*data=this.props.startGetAllEntities(FiscalYear);
        this.props.clearDatasetColumnsProps();
        this.setState({FiscalYear});*/
    this.setState({ FiscalYearID: FiscalYear });
    //var a=this.props.PerspectiveDetails;
  };

  private _onDropDownPerspectiveNameChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const perspectiveName = String(item == null ? "" : item.text);
    var data: any = [];
    this.setState({
      PerspectiveName: perspectiveName,
    });
  };

  private _onDropDownEntity1Change(id: number, item?: IDropdownOption) {
    var oldValue = this.state.MeasureAttributes[id].EntityName;
    var entityName1 = item != undefined ? item.text : "";
    var entityid1 = item != undefined ? Number(item.id) : 0;
    var EntityIDs: number[] = [];
    EntityIDs.push(entityid1);
    var newArray: IMeasureAttribute[] = getDeepCopy(
      this.state.MeasureAttributes
    );
    var count = 0;
    newArray.forEach((element) => {
      if (element.EntityName == entityName1) {
        window.alert(
          "Please do not create multiple measure attributes using the same entity more than once"
        );
        count = count + 1;
        return;
      }
    });
    if (count == 0) {
      newArray[id] = {
        id: id,
        MeasureName:
          this.props.MeasureName != undefined ? this.props.MeasureName : "",
        EntityID: entityid1,
        EntityName: entityName1,
        MeasureAttributeName:
          this.state.MeasureAttributes != undefined
            ? this.state.MeasureAttributes[id].MeasureAttributeName
            : "",
      };
      this.props._onMeasureAttributesChange(
        id,
        this.state.MeasureAttributes[id].EntityName,
        this.state.MeasureAttributes[id].MeasureAttributeName,
        this.props.MeasureRowID,
        newArray
      );
      this.setState({
        MeasureAttributes: newArray,
      });
    } else {
      //newArray.splice(id,1);
      this.setState({
        MeasureAttributes: newArray,
      });
    }
  }

  private _onDropDownAttributeNameChange(id: number, item?: IDropdownOption) {
    var attribName = item != undefined ? item.text : "";
    var attribid = item != undefined ? Number(item.id) : 0;
    var newArray: IMeasureAttribute[] = getDeepCopy(
      this.state.MeasureAttributes
    );
    newArray[id] = {
      id: id,
      MeasureName:
        this.props.MeasureName != undefined ? this.props.MeasureName : "",
      EntityID:
        this.state.MeasureAttributes != undefined
          ? this.state.MeasureAttributes[id].EntityID
          : 0,
      EntityName:
        this.state.MeasureAttributes != undefined
          ? this.state.MeasureAttributes[id].EntityName
          : "",
      MeasureAttributeName: attribName,
      //this.state.MeasureAttributes!=undefined?this.state.MeasureAttributes[id].MeasureAttributeName:""
    };
    this.props._onMeasureAttributesChange(
      id,
      this.state.MeasureAttributes[id].EntityName,
      this.state.MeasureAttributes[id].MeasureAttributeName,
      this.props.MeasureRowID,
      newArray
    );
    this.setState({
      MeasureAttributes: newArray,
    });
  }

  private getAttributeNameOptions_1(
    item: IMeasureAttribute
  ): IDropdownOption[] {
    var options: any[] = [];
    if (this.props.DatasetColumnDetails == undefined) return [];
    if (this.props.ActionType == "edit") {
      options = this.props.DatasetColumnDetails.filter(
        (d) => d.id == this.state.EntityID?.toString()
      );
    } else {
      options = this.props.DatasetColumnDetails.filter(
        (d) => d.id == item.EntityID.toString()
      );
    }
    return options;
  }

  private get(item: IMeasureInput, id: number): IDropdownOption[] {
    var options: any[] = [];
    if (this.props.DatasetColumnDetails == undefined) return [];
    if (this.props.ActionType == "edit") {
      //         options=this.props.DatasetColumnDetails.filter(d => d.id== this.state.Entity2_ID?.toString());
    } else {
      //        options=this.props.DatasetColumnDetails.filter(d => d.id== item.EntityID_2?.toString());
    }
    return options;
  }

  private _clearbuttonClicked = (): void => {
    TelemetryProvider._trackEvent(newMeasureClearButtonClickEvent);
    //this.props.clearAllEntitiesProps();
    //this.props.clearDatasetColumnsProps();
    this.props.clearSaveResultMsgProps(false);
    var newArray: IMeasureAttribute[] = [];
    this.setState({
      FiscalYearID: undefined,
      PerspectiveName: "",
      MeasureAttributes: newArray,
    });
  };

  public render(): JSX.Element {
    var _columns: IColumn[];

    _columns = [
      {
        key: "column1",
        name: "Transactional Entity",
        fieldName: "EntityNameFirst",
        minWidth: 350,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <ComboBox
              options={this.props.EntityIDNameOptions}
              ariaLabel={"Entity Name"}
              placeholder={"Select a value"}
              selectedKey={
                this.state.MeasureAttributes != undefined && this.state.MeasureAttributes[item.id]!=undefined &&
                this.state.MeasureAttributes[item.id].EntityName != undefined
                  ? this.state.MeasureAttributes[item.id].EntityName
                  : ""
              }
              onChange={(event, option) =>
                this._onDropDownEntity1Change(item.id, option)
              }
            />
          );
        },
      },
      {
        key: "column2",
        name: "Transactional Entity Field",
        fieldName: "AttributeNameFirst",
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <ComboBox
              options={this.getAttributeNameOptions_1(item)}
              ariaLabel={"Attribute Name"}
              placeholder={"Select a value"}
              selectedKey={
                this.state.MeasureAttributes != undefined && this.state.MeasureAttributes[item.id]!=undefined
                  ? this.state.MeasureAttributes[item.id].MeasureAttributeName
                  : ""
              }
              onChange={(event, option) =>
                this._onDropDownAttributeNameChange(item.id, option)
              }
            />
          );
        },
      },
      
      {
        key: "Delete",
        name:"Delete",
        minWidth: 60,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <IconButton
              styles={iconButtonStyles}
              iconName="Delete"
              title="Delete"
              fontSize={12}
              ariaLabel="Delete"
              onClick={(event) =>
                this.deleteButtonClicked(item)
              }
            />
          );
        },
      },
      /*,
              {
                key: "deleteMeasure",
                name:"",
                minWidth: 30,
                maxWidth: 150,
                isResizable: true,
                onRender: (item: IMeasureAttribute) => {
                    if (item.id>=(this.props.MeasureAttributeCount!=undefined?this.props.MeasureAttributeCount:0) )
                        return (
                            <IconButton iconProps={{
                                iconName: 'Delete',
                                style: { fontSize: 12 }
                            }}
                                title='Delete'
                                onClick={(event) => this._deleteERButtonClicked(item)}
                                ariaLabel='Delete'
                            />
                        );
                }
            }*/
    ];

    //Need to change this to API
    var FiscalyearList = [
      { key: 2020, text: "2020" },
      { key: 2021, text: "2021" },
    ];

    const dropDownFiscalYear: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Fiscal Year:",
      onChange: this._onDropDownFiscalyearValueChange,
      options: FiscalyearList,
      required: true,
      selectedKey: this.props.FiscalYearID,
      disabled: this.props.ActionType === "edit" ? true : false,
    };

    const dropDownPerspective: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Perspective Name:",
      onChange: this._onDropDownPerspectiveNameChange,
      options:
        this.props.PerspectiveDetails != undefined
          ? this.props.PerspectiveDetails
          : [],
      required: true,
      selectedKey: this.state.PerspectiveName,
      disabled: this.props.ActionType === "edit" ? true : false,
    };

    return (
      <div>
        <div>
          {/*<div className="new-domain-buttons-list">
            <PrimaryButton
              text="Clear"
              onClick={this._clearbuttonClicked}
              className="header-buttons"
              style={{ backgroundColor: "#288560" }}
            />
          </div>
          */}
          <div className="seperator-cls">
            <Label>Measure Name : {this.props.MeasureName} </Label>
          </div>
          <div className="seperator-cls">
            <Label>Measure Type Name : {this.props.MeasureTypeName} </Label>
          </div>
          <div className="seperator-cls">
            <Label>Unit Name : {this.props.UnitName} </Label>
          </div>
          <div className="seperator-cls">
            <Label>Unit Type Name : {this.props.UnitTypeName} </Label>
          </div>
          <div className="seperator-cls"></div>
          <div className="seperator-cls" style={{ color: "#EC0909" }}>
            All fields in the table below are mandatory*
          </div>

          <div>
            <DetailsList
              items={this.getMeasureAttributeRowData()}
              columns={_columns}
              selectionMode={SelectionMode.none}
              selectionPreservedOnEmptyClick={false}
              onRenderRow={this.onRenderRow}
            />
          </div>
        </div>
        <div className="new-domain-buttons-list">
          <PrimaryButton
            className="add-level-button"
            text="Add"
            iconProps={{ iconName: "Add" }}
            onClick={() => this._addLevelButtonClicked()}
            role="button"
            aria-label="Add"
          />
        </div>
      </div>
    );
  }

  private deleteButtonClicked = (params: any):void => 
  {
    var tempVal = window.confirm("Do you want to delete this Measure Attribute ?");
      if (tempVal == true) {
  var param:IPerspectiveMeasure=params;
  var id=param.id;
  
  var measureAttributes: IMeasureAttribute[] = getDeepCopy(
    this.state.MeasureAttributes
  );

  var index = measureAttributes.findIndex((e) => e.id == id);
  if (index != -1) measureAttributes.splice(index, 1);

  this.props._onMeasureAttributesChange(
    index,
    this.state.MeasureAttributes[index].EntityName,
    this.state.MeasureAttributes[index].MeasureAttributeName,
    this.props.MeasureRowID,
    measureAttributes
  );
  
  this.setState({
    MeasureAttributes : measureAttributes,
  });
      }
  };

  private _deleteERButtonClicked = (item: IMeasureAttribute) => {
    var MeasureAttributes: IMeasureAttribute[] = getDeepCopy(
      this.state.MeasureAttributes
    );
    var index = MeasureAttributes.findIndex((e) => e.id == item.id);
    if (index != -1) MeasureAttributes.splice(index, 1);

    this.setState({
      MeasureAttributes,
    });
  };

  private _renderItemColumn(
    item: IMeasureAttribute,
    index: number,
    column: IColumn
  ) {
    var columnName = column.name;
    var newAttributeset: IMeasureAttribute = {
      MeasureName: item.MeasureName,
      EntityID: item.EntityID,
      EntityName: item.EntityName,
      MeasureAttributeName: item.MeasureAttributeName,
      id: item.id,
    };
    if (columnName == "Entity Name") {
      if (!this.state.MeasureAttributes?.includes(newAttributeset)) {
      }
    } else if (columnName == "Attribute Name") {
    }

    //you just need to change code here
  }

  public _addLevelButtonClicked = () => {
    TelemetryProvider._trackEvent(newMeasureAddLevelButtonClickEvent);
    var measureList: IMeasureAttribute[] = getDeepCopy(
      this.state.MeasureAttributes
    );
    measureList.push({
      id:
        this.state.MeasureAttributes != undefined
          ? this.state.MeasureAttributes?.length
          : 0,
      MeasureAttributeName: "",
      EntityID: 0,
      EntityName: "",
      MeasureName: "",
    });
    this.setState({ MeasureAttributes: measureList });
  };

  private getMeasureAttributeRowData() {
    //var data=this.props.DatasetColumnDetails;
    return this.state.MeasureAttributes;
  }

  private getMsgDetails = (msg: IMeasureMsgInfo) => {
    return (
      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
        {msg.loading ? (
          <div>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : (
          ""
        )}
        {msg != undefined && msg.Result != undefined ? (
          msg.Result === 1 && msg.StatusCode === 200 ? (
            <span style={{ color: "green" }}> {msg.ErrorMessage} </span>
          ) : msg.Result === 0 ? (
            <span style={{ color: "red" }}> {msg.ErrorMessage} </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  componentWillUnmount() {
    this.props.clearSaveResultMsgProps(false);
    //this.props.clearDatasetColumnsProps();
    //this.props.clearAllEntitiesProps();

    this.setState({
      MeasureAttributes: this.state.MeasureAttributes,
    });
  }

  private onRenderRow(props?: IDetailsRowProps) {
    if (props != undefined)
      return <DetailsRow rowFieldsAs={this.renderRowFields} {...props} />;
    else return null;
  }

  private renderRowFields(props: IDetailsRowFieldsProps) {
    return (
      <span data-selection-disabled={true}>
        <DetailsRowFields {...props} />
      </span>
    );
  }
}

const mapStateToProps = (state: AppState): NewMeasureLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    PerspectiveDetails: state.measureDefinition.allPerspectiveDetails,
    MeasureTypes: state.measureDefinition.allMeasureTypes,
    UnitTypes: state.measureDefinition.allUnitTypes,
    Units: state.measureDefinition.allUnits,
    saveResultMsg: state.measureDefinition.saveResultMsg,
    DatasetColumnDetails: state.measureDefinition.datasetColumnNames,
    EntityIDNameOptions: state.measureDefinition.allEntitiesList,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): NewMeasureLinkDispatchProps => ({
  startGetAllMeasures: bindActionCreators(startGetAllMeasures, dispatch),
  startGetAllPerpectiveDetails: bindActionCreators(
    startGetAllPerpectiveDetails,
    dispatch
  ),
  startGetAllMeasuresTypes: bindActionCreators(
    startGetAllMeasuresTypes,
    dispatch
  ),
  startGetAllUnitTypes: bindActionCreators(startGetAllUnitTypes, dispatch),
  startGetAllUnits: bindActionCreators(startGetAllUnits, dispatch),
  clearDatasetColumnsProps: bindActionCreators(
    clearDatasetColumnsProps,
    dispatch
  ),
  clearAllEntitiesProps: bindActionCreators(clearAllEntitiesProps, dispatch),
  startGetDatasetColumns: bindActionCreators(startGetDatasetColumns, dispatch),
  clearSaveResultMsgProps: bindActionCreators(
    clearSaveResultMsgProps,
    dispatch
  ),
  startGetAllTransactionalEntities: bindActionCreators(
    startGetAllTransactionalEntities,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMeasure);
