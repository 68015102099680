import * as types from "../types/entity-onboarding-action-types";
import { EntityOnboardingActionTypes } from "../types/entity-onboarding-action-types";

const entityOnboardingReducerDefaultState: any = {};

const entityOnboardingReducer = (
    state = entityOnboardingReducerDefaultState,
    action: EntityOnboardingActionTypes
): any => {
    switch (action.type) {
        case types.GET_ALL_ENTITIES:
            return Object.assign({}, state, {
                totalEntities: action.totalEntities,
            });
        case types.DELETE_ENTITY:
            return Object.assign({}, state, {
                totalEntities: action.data,
            });
        default:
            return state;
    }
};

export default entityOnboardingReducer;