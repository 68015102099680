import { IPerspective } from "../../components/onboarding/perspective-definition/model/IPerspectiveDefinition";
import { IDomainAttributeDetails, IFlexGrain, IFlexGrainDomainAttribute, IPerspectiveMeasure, IPerspectiveMsgInfo } from "../../components/onboarding/perspective-definition/new-perspective/model/INewPerspective";

export const GET_ALL_PERSPECTIVES = "GET_ALL_PERSPECTIVES";
export const GET_FLEX_GRAINS = "GET_FLEX_GRAINS"
export const GET_ALL_DOMAIN_ATTRIBUTES = "GET_ALL_DOMAIN_ATTRIBUTES";
export const GET_NEW_PERSPECTIVE_SAVE_SUCCESS = "GET_NEW_PERSPECTIVE_SAVE_SUCCESS";
export const GET_MEASURES = "GET_MEASURES";
export const GET_FLEXGRAIN_DOMAIN_ATTRIBUTES = "GET_FLEXGRAIN_DOMAIN_ATTRIBUTES";
export const GET_FLEX_GRAIN_SAVE_SUCCESS = "GET_FLEX_GRAIN_SAVE_SUCCESS";

export interface GetAllPerspectives {
    type: typeof GET_ALL_PERSPECTIVES;
    allPerspectives: IPerspective[];
}

export interface GetFlexGrains {
    type: typeof GET_FLEX_GRAINS;
    allFlexGrains: IFlexGrain[];
}

export interface GetAllDomainAttributes {
    type: typeof GET_ALL_DOMAIN_ATTRIBUTES;
    allDomainAttributes: IDomainAttributeDetails[];
}

export interface GetFlexGrainDomainAttributes {
    type: typeof GET_FLEXGRAIN_DOMAIN_ATTRIBUTES;
    flexGrainDomainAttributes: IFlexGrainDomainAttribute[];
}

export interface GetNewPerspectiveSaveSuccess {
    type: typeof GET_NEW_PERSPECTIVE_SAVE_SUCCESS;
    saveResultMsg: IPerspectiveMsgInfo;
}

export interface GetflexGrainSaveSuccess {
    type: typeof GET_FLEX_GRAIN_SAVE_SUCCESS;
    saveFlexGrainResultMsg: IPerspectiveMsgInfo;
}

export interface GetMeasures {
    type: typeof GET_MEASURES;
    measures: IPerspectiveMeasure[];
}


export type PerspectiveDefinitionActionTypes = 
GetAllPerspectives
| GetFlexGrains
| GetAllDomainAttributes
| GetFlexGrainDomainAttributes
| GetNewPerspectiveSaveSuccess
| GetflexGrainSaveSuccess
| GetMeasures;