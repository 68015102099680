import sideNavBarHeaderDetails from "../../nav-bar-data/header-details";
import React from "react";
import "./side-nav-bar-header.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import toggleClickAction from "../../../../../redux/actions/toggle-click-action";
import { AppState } from "../../../../../redux/configureStore";

const SideNavHeader: React.FunctionComponent = (props: any) => {
  return (
    <ul>
      <li>
        <div className="nav-item toggle-item" tabIndex={-1}>
          <div className="nav-image-container toggle" tabIndex={-1}>
            <button 
              className="sidenav-toggle-button"
              onClick={() =>
                props.actions.updateSideNavAction(!props.isSideNavOpen)
              }
            >
              <img 
                className="sidenav-toggle-icon"
                src={sideNavBarHeaderDetails.toggleIcon}
                alt="No Image"
              ></img>
            </button>
          </div>

          <div className="toggle-side-image-container" tabIndex={-1}>
            <img
              className="toggle-side-image"
              src={sideNavBarHeaderDetails.sideIcon}
              alt="No image"
            />
          </div>
          </div>
      </li>
    </ul>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isSideNavOpen: state.isSideNavOpen,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      updateSideNavAction: bindActionCreators(toggleClickAction, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavHeader);
