import { Configurations } from "../App.global";
import { INewEntityAPI } from "./entity-onboarding-api";
import { GetToken } from "../auth/authorization-token";
import { handleResponse, handleError } from "./api.util";

const baseUrl = Configurations.mintSystemConfigApiBaseUrl + "/DataSet/";
let authToken = "";

export function saveEntityToDBAPI(entity: INewEntityAPI) {
    console.log(entity);
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'CreateNewEntity', {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(entity),
            });
        })
        .then(handleResponse)
        .catch(handleError);

}

export function updateEntityToDBAPI(entity: INewEntityAPI, DataSetId: number) {
    console.log(entity);
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'UpdateEntity?datasetID=' + DataSetId, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(entity),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function GetEntityLakeReferenceMetadata(datasetName: string, fiscalyear: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetEntityLakeReferenceMetadata?datasetName=' + datasetName + '&fiscalYear=' + fiscalyear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}
