import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const entityRelationshipPageView: IPageViewTelemetry = {
  name: "Entity Relationship",
  uri: "system/entity-relationship",
};

export const entityRelationshipExportButtonClickEvent: IEventTelemetry = {
  name: "EntityRelationship_ExportButtonClick",
};
export const entityRelationshipDeleteButtonClickEvent: IEventTelemetry = {
  name: "EntityRelationship_DeleteButtonClick",
};
export const newEntityRelationshipSaveButtonClickEvent: IEventTelemetry = {
  name: "EntityRelationship_NewEntityRelationship_SaveButtonClick",
};
export const newEntityRelationshipClearButtonClickEvent: IEventTelemetry = {
  name: "EntityRelationship_NewEntityRelationship_ClearButtonClick",
};
export const newTransactionSourceSaveButtonClickEvent: IEventTelemetry = {
  name: "EntityRelationship_NewTransactionSource_SaveButtonClick",
};
// Done
