import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const perspectiveDefinitionPageView: IPageViewTelemetry = {
  name: "Perspective Definition",
  uri: "system/perspective-definition",
};

export const perspectiveDefinitionExportButtonClickEvent: IEventTelemetry = {
  name: "PerspectiveDefinition_ExportButtonClick",
};
export const perspectiveDefinitionDeleteButtonClickEvent: IEventTelemetry = {
  name: "PerspectiveDefinition_DeleteButtonClick",
};
export const newPerspectiveDefinitionSaveButtonClickEvent: IEventTelemetry = {
  name: "PerspectiveDefinition_NewPerspectiveDefinition_SaveButtonClick",
};
export const newPerspectiveDefinitionClearButtonClickEvent: IEventTelemetry = {
  name: "PerspectiveDefinition_NewPerspectiveDefinition_ClearButtonClick",
};
// export const newTransactionSourceSaveButtonClickEvent: IEventTelemetry = {
//   name: "PerspectiveDefinition_NewTransactionSource_SaveButtonClick",
// };
export const measureDefinitionExportButtonClickEvent: IEventTelemetry = {
  name: "MeasureDefinition_ExportButtonClick",
};
export const newMeasureClearButtonClickEvent: IEventTelemetry = {
  name: "MeasureDefinition_NewMeasure_ClearButtonClick",
};
export const newMeasureAddLevelButtonClickEvent: IEventTelemetry = {
  name: "MeasureDefinition_NewMeasure_AddLevelButtonClick",
};
