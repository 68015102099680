import {
    GET_NEW_ENTITY_SAVE_SUCCESS,
    GET_ENTITY_UPDATE_SUCCESS,
    GET_UPDATE_ENTITY_MSGS,
    GET_SAVE_ENTITY_MSGS,
    GET_ELR_OBJECT_TYPE_NAME_DATA,
    GET_ELR_DATA_SOURCE_NAME_DATA,
    GET_ELR_SUBJECT_AREA_NAME_DATA
} from "../types/new-entity-action-types";

const initState = {
    loading: false,
    saveEntityMsg: {},
    previewLoading: true,
    ELRObjectTypeName: '',
    ELRDataSourceName: '',
    ELRSubjectAreaName: '',
    error: ''
}

const newEntityReducer = (
    state: any = initState,
    action: any
): any => {
    switch (action.type) {
        case GET_NEW_ENTITY_SAVE_SUCCESS:
            return Object.assign({}, state, {
                saveEntityMsg: action.payload,
            });
        case GET_ENTITY_UPDATE_SUCCESS:
            return Object.assign({}, state, {
                saveEntityMsg: action.payload,
            });
        case GET_UPDATE_ENTITY_MSGS:
            return Object.assign({}, state, {
                saveEntityMsg: action.payload,
            });
        case GET_SAVE_ENTITY_MSGS:
            return Object.assign({}, state, {
                saveEntityMsg: action.payload,
            });
        case GET_ELR_OBJECT_TYPE_NAME_DATA:
            return Object.assign({}, state, {
                ELRObjectTypeName: action.payload
            });
        case GET_ELR_DATA_SOURCE_NAME_DATA:
            return Object.assign({}, state, {
                ELRDataSourceName: action.payload
            });
        case GET_ELR_SUBJECT_AREA_NAME_DATA:
            return Object.assign({}, state, {
                ELRSubjectAreaName: action.payload
            });

        default:
            return state;
    }
}

export default newEntityReducer