import React from "react";
import {
  DomainDefinitionProps,
  DomainDefinitionLinkStateProps,
  DomainDefinitionLinkDispatchProps,
  IDomains,
} from "./model/IDomainDefinition";
import { AppState } from "../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import {
  startDeleteDomainById,
  startGetAllDomains,
} from "./domain-definition.service";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import { DomainDefinition } from "./domain-definition.view";
import { ColDef } from "ag-grid-community";
import { ModalView } from "../../common/modal/modal.view";
import IconButtonView from "../../common/button/icon-button/icon-button.view";
import { IconButton } from "office-ui-fabric-react";
import { getDeepCopy } from "../../../util/javascript-functions";
import NewDomain from "./new-domain/new-domain.controller";
import * as TelemetryProvider from "../../../TelemetryProvider";
import {
  domainDefinitionDeleteButtonClickEvent,
  domainDefinitionPageView,
} from "./domain-definition.telemtetry-constants";

type Props = DomainDefinitionProps &
  DomainDefinitionLinkStateProps &
  DomainDefinitionLinkDispatchProps;

export class DomainDefinitionList extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    TelemetryProvider._trackPageView(domainDefinitionPageView);
    this.state = {
      editModalParams: undefined,
      isEditModalVisible: false,
      isNewModal: false,
    };
    this.getEditControl = this.getEditControl.bind(this);
    this.updateIsEditModalVisible = this.updateIsEditModalVisible.bind(this);
  }

  componentDidMount() {
    this.props.startGetAllDomains();
  }

  public render(): JSX.Element {
    if (this.props.allDomains != undefined || this.props.allDomains != null) {
      var columnDefs: any[] = [];
      var rowData: any[] = [];
      columnDefs = this.getColDefinitions(columnDefs);
      rowData = this.getRowData(rowData, this.props.allDomains).filter(
        (d) => d.FiscalYear == this.props.fiscalYear
      );
      var params = {
        allDomains: this.props.allDomains,
        columnDefs: columnDefs,
        rowData: rowData,
        startGetAllDomains: this.props.startGetAllDomains,
        editModalParams: this.state.editModalParams,
        showModal: this.state.isEditModalVisible,
        updateIsEditModalVisible: this.updateIsEditModalVisible,
        gridCellKeyPress: this.gridCellKeyPress,
        isNewModal: this.state.isNewModal,
      };
      return <DomainDefinition {...params} />;
    } else {
      return (
        <>
          <div>Loading...</div>
        </>
      );
    }
  }

  private gridCellKeyPress = (event: any) => {
    let keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        // case "View":
        //   TelemetryProvider._trackEvent(viewActivityClickEvent);
        //   updateSelectedActivityDetails({
        //     activityId: event.data.ActivityId,
        //     activityName: event.data.ActivityName,
        //   });
        //   updateShowActivityDetailModal(true);
        //   break;
        case "Edit":
          // TelemetryProvider._trackEvent(editActivityClickEvent);
          const _editParams = {
            ActionType: "edit",
            FiscalYear: event.data.FiscalYear,
            DomainID: event.data.DomainID,
            DomainName: event.data.DomainName,
            DomainTypeID: event.data.DomainTypeID,
            DomainTypeName: event.data.DomainTypeName,
            DimTableName: event.data.DimTableName,
            DomainEntities: event.data.DomainEntities,
            DomainAttributes: event.data.DomainAttributes,
            IsCompensableMetricsEligible:
              event.data.IsCompensableMetricsEligible,
            startGetAllDomains: startGetAllDomains,
          };
          this.setState({
            editModalParams: _editParams,
            isEditModalVisible: true,
          });
          break;
        case "Delete":
          if (event.data.IsActive) {
            this.deleteButtonClicked(event.data);
          }
          break;
        default:
          return;
      }
    }
  };

  private deleteButtonClicked = (params: any) => {
    TelemetryProvider._trackEvent(domainDefinitionDeleteButtonClickEvent);
    var clonedDomainState = getDeepCopy(this.props.allDomains);
    var tempVal = window.confirm("Do you want to delete this Domain ?");
    if (tempVal == true) {
      this.props.startDeleteDomainById(params.DomainID, clonedDomainState);
    }
  };

  private updateIsEditModalVisible(isNewModal: boolean = false) {
    this.setState({
      isEditModalVisible: !this.state.isEditModalVisible,
      isNewModal: isNewModal,
    });
  }

  private getEditControl = (params: any) => {
    const _editParams = {
      ActionType: "edit",
      FiscalYear: params.data.FiscalYear,
      DomainID: params.data.DomainID,
      DomainName: params.data.DomainName,
      DomainTypeID: params.data.DomainTypeID,
      DomainTypeName: params.data.DomainTypeName,
      DimTableName: params.data.DimTableName,
      DomainEntities: params.data.DomainEntities,
      DomainAttributes: params.data.DomainAttributes,
      IsCompensableMetricsEligible: params.data.IsCompensableMetricsEligible,
      startGetAllDomains: startGetAllDomains,
    };
    return (

        <IconButtonView
          iconName="SingleColumnEdit"
          fontSize={12}
          title="Edit"
          onClick={() => {
            this.setState({
              editModalParams: _editParams,
              isEditModalVisible: true,
              isNewModal: false,
            });
          }}
          ariaLabel="Edit"
        />

    );
  };

  private getDeleteControl = (params: any) => {
    return (
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "20%" }}>
          <IconButton
            iconProps={{
              iconName: "Delete",
              style: { fontSize: 12 },
            }}
            title="Delete"
            onClick={(event) => this.deleteButtonClicked(params.data)}
            ariaLabel="Delete"
            disabled={!params.data.IsActive}
          />
        </div>
      </div>
    );
  };

  private getColDefinitions(colDefns: ColDef[]) {
    colDefns = [
      { field: "DomainID", hide: true },
      { field: "FiscalYear", sortable: true, filter: true, resizable: true, flex: 0.5 },
      { field: "DomainName", sortable: true, filter: true, resizable: true, flex: 1 },
      { field: "Entities", sortable: true, filter: true, resizable: true, flex: 1 },
      {
        field: "AttributeLevel0",
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      },
      {
        field: "AttributeLevel1",
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      },
      {
        field: "AttributeLevel2",
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      },
      { field: "IsActive", sortable: true, filter: true, resizable: true, flex: 0.5 },
      { field: "DomainTypeID", hide: true },
      { field: "DomainTypeName", hide: true },
      { field: "DimTableName", hide: true },
      { field: "DomainEntities", hide: true },
      { field: "DomainAttributes", hide: true },
      { field: "IsCompensableMetricsEligible", hide: true },
      {
        headerName: "Edit",
        width: 60,
        cellRendererFramework: this.getEditControl,
      },
      {
        headerName: "Delete",
        width: 60,
        cellRendererFramework: this.getDeleteControl,
      },
    ];
    return colDefns;
  }

  private getRowData(rowData: any[], domains: IDomains[]) {
    if (domains != undefined) {
      domains.forEach((domain) => {
        rowData.push({
          DomainID: domain.DomainID,
          DomainName: domain.DomainName,
          Entities: domain.EntityList,
          FiscalYear: domain.FiscalYearID,
          AttributeLevel0: domain.Level0 !== undefined ? domain.Level0 : "",
          AttributeLevel1: domain.Level1 !== undefined ? domain.Level1 : "",
          AttributeLevel2: domain.Level2 !== undefined ? domain.Level2 : "",
          DomainTypeID: domain.DomainTypeID,
          DomainTypeName: domain.DomainTypeName,
          DimTableName: domain.DimTableName,
          DomainEntities: domain.DomainEntities,
          DomainAttributes: domain.DomainAttributes,
          IsCompensableMetricsEligible: domain.IsCompensableMetricsEligible,
          IsActive: domain.IsActive,
        });
      });
    }
    return rowData;
  }
}

const mapStateToProps = (state: AppState): DomainDefinitionLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    allDomains: state.domainDefinition.allDomains,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): DomainDefinitionLinkDispatchProps => ({
  startGetAllDomains: bindActionCreators(startGetAllDomains, dispatch),
  startDeleteDomainById: bindActionCreators(startDeleteDomainById, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DomainDefinitionList);
