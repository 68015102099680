import * as types from "../types/perspective-definition-action-types";
import { PerspectiveDefinitionActionTypes } from "../types/perspective-definition-action-types";

const perspectiveDefinitionReducerDefaultState: any = {};

const PerspectiveDefinitionReducer = (
    state = perspectiveDefinitionReducerDefaultState,
    action: PerspectiveDefinitionActionTypes
): any => {
    switch (action.type) {
        case types.GET_ALL_PERSPECTIVES:
            return Object.assign({}, state, {
                allPerspectives: action.allPerspectives,
            });
        case types.GET_FLEX_GRAINS:
                return Object.assign({}, state, {
                    allFlexGrains: action.allFlexGrains,
                });
        case types.GET_ALL_DOMAIN_ATTRIBUTES:
            return Object.assign({}, state, {
                allDomainAttributes: action.allDomainAttributes,
            });
        case types.GET_FLEXGRAIN_DOMAIN_ATTRIBUTES:
            return Object.assign({}, state, {
                flexGrainDomainAttributes: action.flexGrainDomainAttributes,
            });
        case types.GET_NEW_PERSPECTIVE_SAVE_SUCCESS:
            return Object.assign({}, state, {
                saveResultMsg: action.saveResultMsg,
            });
        case types.GET_FLEX_GRAIN_SAVE_SUCCESS:
            return Object.assign({}, state, {
                saveFlexGrainResultMsg: action.saveFlexGrainResultMsg,
            });
        case types.GET_MEASURES:
            return Object.assign({}, state, {
                measures: action.measures,
            });
        default:
            return state;
    }
};

export default PerspectiveDefinitionReducer;