import {
    GET_NEW_ENTITY_SAVE_SUCCESS,
    GET_ENTITY_UPDATE_SUCCESS,
    GET_UPDATE_ENTITY_MSGS,
    GET_SAVE_ENTITY_MSGS,
    GET_ELR_OBJECT_TYPE_NAME_DATA,
    GET_ELR_DATA_SOURCE_NAME_DATA,
    GET_ELR_SUBJECT_AREA_NAME_DATA
} from "../types/new-entity-action-types";
import {GET_ALL_ENTITIES} from "../types/domain-definition-action-types"
import{getAllEntities} from "../actions/domain-definition-actions"
import { saveEntityToDBAPI, updateEntityToDBAPI, GetEntityLakeReferenceMetadata } from "../../api/new-entity-api";
import {getAllEntitiesList} from "../../api/domain-definition-api"
import { IDropdownOption } from "office-ui-fabric-react";
import { INewEntityState, defaultELRData } from "../../components/onboarding/entity-onboarding/new-entity"
import { INewEntityAPI } from "../../api/entity-onboarding-api";
import * as EntityRelationhshipDefinitionAPI from "../../api/entity-relationship-api"
import * as EntityRelationshipActions from "../../redux/actions/entity-relationship-actions";

import { IEntityPreview, IEntityMsgInfo, IEntityLakeReferenceMetadata } from "../../components/onboarding/entity-onboarding/model/IEntity";
import { getEnabledCategories } from "trace_events";

const fetchEntitySaveSuccessAction = (saveResultMsg: IEntityMsgInfo) => {
    return {
        type: GET_NEW_ENTITY_SAVE_SUCCESS,
        payload: saveResultMsg
    }
}

const fetchEntityUpdateSuccessAction = (updateResultMsg: IEntityMsgInfo) => {
    return {
        type: GET_ENTITY_UPDATE_SUCCESS,
        payload: updateResultMsg
    }
}

const fetchUpdateMsgsSuccessAction = (updateResultMsg: IEntityMsgInfo) => {
    return {
        type: GET_UPDATE_ENTITY_MSGS,
        payload: updateResultMsg
    }
}

const fetchObjectTypeNameSuccessAction = (data: string) => {
    return {
        type: GET_ELR_OBJECT_TYPE_NAME_DATA,
        payload: data
    }
}

const fetchDataSourceNameSuccessAction = (data: string) => {
    return {
        type: GET_ELR_DATA_SOURCE_NAME_DATA,
        payload: data
    }
}

const fetchSubjectAreaNameSuccessAction = (data: string) => {
    return {
        type: GET_ELR_SUBJECT_AREA_NAME_DATA,
        payload: data
    }
}

const fetchSaveMsgsSuccessAction = (updateResultMsg: IEntityMsgInfo) => {
    return {
        type: GET_SAVE_ENTITY_MSGS,
        payload: updateResultMsg
    }
}

export const fetchEntities= (fiscalyear: number) => {
    return (dispatch: any) => {
        getAllEntitiesList(fiscalyear)
        .then((response) => {
            //Response from API is in form: "<EntityID>,<EntityName>"
            const data: any[] = response.map((EntityIdName: string, index: any) => {
                return ({
                    key: EntityIdName.split(",")[1], 
                    text: EntityIdName.split(",")[1],
                    id: EntityIdName.split(",")[0]
                })
            });
                dispatch(getAllEntities(data));
            })
        //error needs to be handled
    }
}

export const startGetDatasetColumnsList = () => {
    return (dispatch: any) => {
        const res = EntityRelationhshipDefinitionAPI.getAllDataSetColumnList()
            .then((response) => {
                //Response from API is in form: "<EntityID>,<EntityName>"
               // const data: INewTranscationSource[] = response;
                dispatch(EntityRelationshipActions.getDatasetColumnsList(response));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

export const setEntityDetails = (newEntityState: INewEntityState) => {
    const entity: INewEntityAPI = {};
    entity.SourceTables='';
    //entity.DatasetName = (newEntityState.SparkViewRequired==true?'vw_'+newEntityState.DatasetName:newEntityState.DatasetName)
    entity.DatasetName = newEntityState.DatasetName;
    entity.ELRObjectTypeName = newEntityState.ELRObjectTypeName;
    entity.ELRDataSourceName = newEntityState.ELRDataSourceName;
    entity.ELRSubjectAreaName = newEntityState.ELRSubjectAreaName;
    entity.IsTransactionSource=newEntityState.IsTransactionSource;
    entity.FilterCondition=newEntityState.FilterCondition;
    entity.Precedence=newEntityState.Precedence;
    entity.ispersistable=newEntityState.ispersistable;
    entity.SparkSQLQuery=newEntityState.SparkSQLQuery;
    entity.TagName=newEntityState.TagName;
    entity.EntityList=newEntityState.columnList;
    entity.SourceTables=newEntityState.SelectedEntities!=undefined?newEntityState.SelectedEntities.join(','):'';
    entity.IsActive = true;
    entity.IsAlt = (newEntityState.IsAlt ? newEntityState.IsAlt : false);
    entity.FiscalYear = newEntityState.FiscalYear;
    return entity;
}

export const saveEntityToDB = (newEntityState: INewEntityState, getAllEntits: any, getDatasetColumnsList: any) => {
    return (dispatch: any) => {
        const entity1 = setEntityDetails(newEntityState);
        saveEntityToDBAPI(entity1)
            .then((response) => {
                const result: IEntityMsgInfo = response;
                let tempResult: IEntityMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.ErrorMessage = (result.Result === 1 ?
                        "Entity saved successfully" :
                        (result.Result === 0 ? "Entity not saved successfully" : ""));
                    dispatch(fetchEntitySaveSuccessAction(tempResult));
                    if (result != undefined && result.Result === 1) {
                        getAllEntits();
                        getDatasetColumnsList();
                    } 
                }
            })
    }
}

export const clearUpdateEntityMsgDetails = (msgInfo: IEntityMsgInfo, loading: boolean) => {
    return (dispatch: any) => {
        msgInfo.StatusCode = 0;
        msgInfo.Result = 5;
        msgInfo.ErrorMessage = "";
        msgInfo.loading = loading;
        dispatch(fetchUpdateMsgsSuccessAction(msgInfo));
    }
}

export const GetEntityLakeReference = (datasetName: string, fiscalyear: number) => {
    return (dispatch: any) => {
        GetEntityLakeReferenceMetadata(datasetName, fiscalyear)
            .then((response) => {
                const result: any = response;
                if (result != null && result != undefined) {
                    var elrMetaData: IEntityLakeReferenceMetadata = {
                        ObjectTypeName: result.ObjectTypeName,
                        DataSourceName: result.DataSourceName,
                        SubjectAreaName: result.SubjectAreaName !== undefined ? result.SubjectAreaName : ""
                    }
                    dispatch(fetchObjectTypeNameSuccessAction(elrMetaData.ObjectTypeName));
                    dispatch(fetchDataSourceNameSuccessAction(elrMetaData.DataSourceName));
                    dispatch(fetchSubjectAreaNameSuccessAction(elrMetaData.SubjectAreaName));
                }
            })
            //error needs to be handled
    }
}

export const ResetToDefaultELRData = () => {
    return (dispatch: any) => {
        var elrMetaData: IEntityLakeReferenceMetadata = defaultELRData;
        dispatch(fetchObjectTypeNameSuccessAction(elrMetaData.ObjectTypeName));
        dispatch(fetchDataSourceNameSuccessAction(elrMetaData.DataSourceName));
        dispatch(fetchSubjectAreaNameSuccessAction(elrMetaData.SubjectAreaName));
    }
}

export const clearSaveEntityMsgDetails = (loading: boolean) => {
    return (dispatch: any) => {
        var msgInfo : IEntityMsgInfo = {
            StatusCode: 0,
            Result: 5,
            ErrorMessage: "",
            loading: loading,
        };
        dispatch(fetchSaveMsgsSuccessAction(msgInfo));
    }
}

export const updateEntityToDB = (newEntityState: INewEntityState, DatasetId: number, getAllEntits: any, getDatasetColumnsList: any) => {
    return (dispatch: any) => {
        const entity1 = setEntityDetails(newEntityState);
        // saveEntityToDBAPI(entity1);
        updateEntityToDBAPI(entity1, DatasetId)
            .then((response) => {
                const result: IEntityMsgInfo = response;
                let tempResult: IEntityMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.ErrorMessage = (result.Result === 1 ?
                        "Entity updated successfully" :
                        (result.Result === 0 ? "Entity not updated successfully" :
                            ""));
                    dispatch(fetchEntityUpdateSuccessAction(tempResult));
                    if (result != undefined && result.Result === 1) {
                        getAllEntits();
                        getDatasetColumnsList();
                    }
                }


            })
        //error needs to be handled
    }
}

