import * as React from "react";
import { IDropdownPropsCustom } from "./models/IDropdown";
import DropdownView from "./dropdown.view";
import { IDropdownOption } from "office-ui-fabric-react";

const DropdownController = (Props: IDropdownPropsCustom) => {
  const _onSelectDefaultValue = (event: React.FormEvent<HTMLDivElement>, options?: IDropdownOption): void => {
    Props.onChange(event, options);
  };

  const _onDismiss = (): void => {
    if(Props.onDismiss != undefined) 
      Props.onDismiss();
  }

  return (
    <DropdownView
      placeholder={Props.placeholder}
      label={Props.label}
      ariaLabel={Props.ariaLabel}
      options={Props.options}
      onChange={_onSelectDefaultValue}
      disabled={Props.disabled}
      styles={Props.styles}
      defaultSelectedKey={Props.defaultSelectedKey}
      required={Props.required}
      selectedKey={Props.selectedKey}
      multiSelect={Props.multiSelect}
      defaultSelectedKeys={Props.defaultSelectedKeys}
      selectedKeys={Props.selectedKeys}
      onDismiss={_onDismiss}
      title={Props.multiSelect ? Props.selectedKeys?.join(' , ') : Props.selectedKey?.toString()}
      dropdownWidth={Props.dropdownWidth}
    />
  );
};

const Dropdown = (Props: IDropdownPropsCustom) => {
  return DropdownController(Props);
};
export default Dropdown;
