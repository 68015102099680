import { IPerspective } from "../../components/onboarding/perspective-definition/model/IPerspectiveDefinition";
import { IDomainAttributeDetails, IFlexGrain, IFlexGrainDomainAttribute, IPerspectiveMeasure, IPerspectiveMsgInfo } from "../../components/onboarding/perspective-definition/new-perspective/model/INewPerspective";
import { AppActions } from "../types/app-actions";
import * as types from "../types/perspective-definition-action-types";


export const getAllPerspectives = (
    allPerspectives: IPerspective[]
): AppActions => {
    return ({
        type: types.GET_ALL_PERSPECTIVES,
        allPerspectives,
    });
}

export const getAllFlexGrains = (
    allFlexGrains: IFlexGrain[]
): AppActions => {
    return ({
        type: types.GET_FLEX_GRAINS,
        allFlexGrains,
    });
}

export const getAllDomainAttributes = (
    allDomainAttributes: IDomainAttributeDetails[]
): AppActions => {
    return ({
        type: types.GET_ALL_DOMAIN_ATTRIBUTES,
        allDomainAttributes,
    });
}

export const getFlexGrainDomainAttributes = (
    flexGrainDomainAttributes: IFlexGrainDomainAttribute[]
): AppActions => {
    return ({
        type: types.GET_FLEXGRAIN_DOMAIN_ATTRIBUTES,
        flexGrainDomainAttributes,
    });
}

export const getNewPerspectiveSaveSuccessAction = (
    saveResultMsg: IPerspectiveMsgInfo
): AppActions => {
    return ({
        type: types.GET_NEW_PERSPECTIVE_SAVE_SUCCESS,
        saveResultMsg,
    });
}

export const getFlexGrainSaveSuccessAction = (
    saveFlexGrainResultMsg: IPerspectiveMsgInfo
): AppActions => {
    return ({
        type: types.GET_FLEX_GRAIN_SAVE_SUCCESS,
        saveFlexGrainResultMsg,
    });
}

export const getMeasures = (
    measures: IPerspectiveMeasure[]
): AppActions => {
    return ({
        type: types.GET_MEASURES,
        measures,
    });
}