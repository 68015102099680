import { combineReducers } from "redux";
import isSideNavOpen from "./side-nav-reducer";
import expandCollapseClick from "./expand-collapse-reducer";
import authentication from "./authentication-reducer";
import entityOnboardingReducer from "./entity-onboarding-reducer";
import newEntityReducer from "./new-entity-reducer";
import userProfile from "./user-profile-reducer";
import domainDefinition from "./domain-definition-reducer";
import perspectiveDefinition from "./perspective-definition-reducer";
import measureDefinition from "./measure-definition-reducer";
import entityRelationship from "./entity-relationship-reducer";
import fiscalYear from "./fiscal-year-reducer";

// import runScheduleJSON from "./run-schedule-JSON-reducer";

const rootReducer = combineReducers({
  authentication,
  userProfile,
  isSideNavOpen,
  expandCollapseClick,
  entityOnboardingReducer,
  newEntityReducer,
  domainDefinition,
  perspectiveDefinition,
  measureDefinition,
  entityRelationship,
  fiscalYear
});

export default rootReducer;
