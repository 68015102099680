import { IEntityRelationship, IERSummaryRowData } from "../../components/onboarding/entity-relationship/model/IEntityRelationship";
import { IEntityRelationshipMsgInfo } from "../../components/onboarding/entityrelationship-definition/new-entityrelationship/model/INewEntityRelationship";
import { ITransactionMsgInfo } from "../../components/onboarding/entityrelationship-definition/new-entityrelationship/model/INewTransactionSource";
import { AppActions } from "../types/app-actions";
import * as types from "../types/entity-relationship-action-types";

export const getAllEntities = (
    allEntitiesList: any
): AppActions => {
    return ({
        type: types.GET_ALL_ENTITIES,
        allEntitiesList,
    });
}

export const getAllEntityRelationships = (
    allEntityRelationships: IERSummaryRowData[]
): AppActions => {
    return ({
        type: types.GET_ALL_ENTITYRELATIONSHIPS,
        allEntityRelationships,
    });
}

export const getNewERSaveSuccessAction = (
    saveResultMsg: IEntityRelationshipMsgInfo
): AppActions => {
    return ({
        type: types.GET_NEW_ER_SAVE_SUCCESS,
        saveResultMsg,
    });
}

export const getNewTransactionSaveSuccessAction = (
    saveTransResultMsg: ITransactionMsgInfo
): AppActions => {
    return ({
        type: types.GET_NEW_TRANSACTIONSOURCE_SAVE_SUCCESS,
        saveTransResultMsg,
    });
}

export const clearDatasetColumns = (): AppActions => {
    return ({
        type: types.CLEAR_DATASET_COLUMNS,
    });
}

export const getDatasetColumns = (
    datasetColumnNames: string[]
): AppActions => {
    return ({
        type: types.GET_DATASET_COLUMNS,
        datasetColumnNames,
    });
}

export const getDatasetColumnsList = (
    datasetColumnNamesList: any
): AppActions => {
    return ({
        type: types.GET_DATASET_COLUMNS_LIST,
        datasetColumnNamesList,
    });
}


export const getDatasetColumnsWithAlias = (
    datasetColumnNamesWithAlias: string[]
): AppActions => {
    return ({
        type: types.GET_DATASET_COLUMNS_WITH_ALIAS,
        datasetColumnNamesWithAlias,
    });
}

export const getTransactionDatasetDetails = (
    transactionDatasetDetails: any
): AppActions => {
    return ({
        type: types.GET_TRANSACTION_DATASET_DETAILS,
        transactionDatasetDetails,
    });
}
