import { Dispatch } from "redux";
import { AppActions } from "./redux/types/app-actions";
import { AppState } from "./redux/configureStore";
import * as UserDetailsAPI from "./api/user-details-api";
import { UserRoleDetails } from "./models/user-profile";
import * as UserProfileActions from "./redux/actions/user-profile-actions";
import * as FiscalYearActions from "./redux/actions/fiscal-year-actions";
import * as TelemetryProvider from "./TelemetryProvider";

export const startGetUserRoleData = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    UserDetailsAPI.getUserRoleDetails()
      .then((value) => {
        const userRoleDetails: UserRoleDetails = value.Result;
        dispatch(UserProfileActions.getAuthControls(userRoleDetails));
      })
      .catch((error) => {
        TelemetryProvider._trackException(error);
        const err = error;
      });
  };
};

export const startGetFiscalYearList = () => {
  var FiscalyearList = [
    { key: 2023, text: "2023" },
    { key: 2024, text: "2024" },
    { key: 2025, text: "2025" },
  ];
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(FiscalYearActions.getFiscalYearList(FiscalyearList));
  };
};

export const startGetCurrentFiscalYear = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(FiscalYearActions.getFiscalYear(2025));
  };
};
