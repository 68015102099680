import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { IColumn } from "office-ui-fabric-react";
import { GetToken } from "../auth/authorization-token";

let authToken = "";
const baseUrl = Configurations.mintSystemConfigApiBaseUrl + "/DataSet/";

export interface INewEntityAPI {
  DatasetName?: string;
  EntityLakeReferenceName?: string;
  ELRObjectTypeName?: string;
  ELRDataSourceName?: string;
  ELRSubjectAreaName?: string;
  IsActive?: boolean;
  IsAlt?: boolean;
  FiscalYear?: number;
  IsTransactionSource?: boolean;
  SourceTables?:string;
  ViewName?:string;
  SparkSQLQuery?: string;
  TagName?: string;
  FilterCondition?: string;
  Precedence?: number;
  ispersistable?:boolean;
  EntityList?: string;
}

const columns: IColumn[] = [];
export function startGetAllEntities() {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "Entities", {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteEntityById(dataSetId: number) {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl + "DeleteEntity?datasetID=" + dataSetId, {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}
