import { AppActions } from "../types/app-actions";
import * as types from "../types/domain-definition-action-types";
import { IDomains } from "../../components/onboarding/domain-definition/model/IDomainDefinition";
import { IDomainMsgInfo } from "../../components/onboarding/domain-definition/new-domain/model/INewDomain";

export const getAllDomains = (
    allDomains: IDomains[]
): AppActions => {
    return ({
        type: types.GET_ALL_DOMAINS,
        allDomains,
    });
}

export const getDomainTypes = (
    domainTypesList: any
): AppActions => {
    return ({
        type: types.GET_DOMAIN_TYPES,
        domainTypesList,
    });
}

export const getAllEntities = (
    allEntitiesList: any
): AppActions => {
    return ({
        type: types.GET_ALL_ENTITIES,
        allEntitiesList,
    });
}

export const addNewEntity = (
    entity: string
): AppActions => {
    return ({
        type: types.ADD_NEW_ENTITY,
        entity,
    });
}

export const getDatasetColumns = (
    attributeLevelId: number,
    datasetColumnNames: string[]
): AppActions => {
    return ({
        type: types.GET_DATASET_COLUMNS,
        attributeLevelId,
        datasetColumnNames,
    });
}

export const getNewDomainSaveSuccessAction = (
    saveResultMsg: IDomainMsgInfo
): AppActions => {
    return ({
        type: types.GET_NEW_DOMAIN_SAVE_SUCCESS,
        saveResultMsg,
    });
}

export const clearDatasetColumns = (): AppActions => {
    return ({
        type: types.CLEAR_DATASET_COLUMNS,
    });
}

export const deleteDomain = (
    allDomains: IDomains[]
): AppActions => {
    return ({
        type: types.DELETE_DOMAIN,
        allDomains,
    });
}