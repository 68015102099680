import * as types from "../types/entity-relationship-action-types";
import { EntityRelationshipActionTypes } from "../types/entity-relationship-action-types";

const entityRelationshipReducerDefaultState: any = {};

const EntityRelationshipReducer = (
  state = entityRelationshipReducerDefaultState,
  action: EntityRelationshipActionTypes
): any => {
  switch (action.type) {
    case types.GET_ALL_ENTITYRELATIONSHIPS:
      return Object.assign({}, state, {
        allEntityRelationships: action.allEntityRelationships,
      });
    case types.GET_ALL_ENTITIES:
      return Object.assign({}, state, {
        allEntitiesList: action.allEntitiesList,
      });
    case types.GET_DATASET_COLUMNS:
      return Object.assign({}, state, {
        datasetColumnNames: action.datasetColumnNames,
      });
    case types.GET_DATASET_COLUMNS_LIST:
      return Object.assign({}, state, {
        datasetColumnNamesList: action.datasetColumnNamesList,
      });
    case types.GET_DATASET_COLUMNS_WITH_ALIAS:
      return Object.assign({}, state, {
        datasetColumnNamesWithAlias: action.datasetColumnNamesWithAlias,
      });
    case types.GET_DATASET_COLUMNS_WITH_ALIAS:
      return Object.assign({}, state, {
        datasetColumnNamesWithAlias: action.datasetColumnNamesWithAlias,
      });
    case types.GET_NEW_ER_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveResultMsg: action.saveResultMsg,
      });
    case types.GET_NEW_TRANSACTIONSOURCE_SAVE_SUCCESS:
      return Object.assign({}, state, {
        saveTransResultMsg: action.saveTransResultMsg,
      });
    case types.GET_TRANSACTION_DATASET_DETAILS:
      return Object.assign({}, state, {
        transactionDatasetDetails: action.transactionDatasetDetails,
      });
    default:
      return state;
  }
};

export default EntityRelationshipReducer;
