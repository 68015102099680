import React from "react";
import { ISideNavBarItem } from "../model/ISideNavBar";
import "./side-nav-bar-buttons.scss";
import { Link } from "react-router-dom";

const SideNavNavigateButton: React.FunctionComponent<ISideNavBarItem> = (
  data: ISideNavBarItem
) => {
  return (
    <button aria-label={data.name} className="side-nav-button" tabIndex={-1}>
      <a className="side-nav-button-link" href={data.url} target={data.target ?? "_self"} rel={data.rel ?? ""}>
        <div className="nav-node-name">{data.name}</div>
      </a>
    </button>
  );
};

export default SideNavNavigateButton;
