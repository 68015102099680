import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../../redux/types/app-actions";
import { AppState } from "../../../../redux/configureStore";
import * as MeasureAPI from "../../../../api/measure-definition-api";
import * as MeasureDefinitionActionTypes from "../../../../redux/actions/measure-definition-actions"
import { INewMeasureState,IMeasureInput,IMeasureAttribute} from "./model/INewMeasure";
import * as EntityRelationhshipDefinitionAPI from "../../../../api/entity-relationship-api";
import { IMeasure,IMeasureMsgInfo, IPerspectiveDetail,IMeasureInputToDB } from "./../model/IMeasure"
import { IPerspectiveMeasure,INewPerspectiveState } from "../../perspective-definition/new-perspective/model/INewPerspective";


export const startGetAllPerpectiveDetails = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = MeasureAPI.getPerspectiveDetails(fiscalYear)
            .then((response) => {
                const data: any[] = response.map((PerspectiveName: string, index: any) => {
                    return ({
                        key: PerspectiveName.split(",")[1],
                        text: PerspectiveName.split(",")[1],
                        id: PerspectiveName.split(",")[0]
                    })
                });
                dispatch(MeasureDefinitionActionTypes.getPerspectiveDetails(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

export const startGetAllMeasuresTypes = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = MeasureAPI.getMeasureTypes()
            .then((response) => {
                const data: any[] = response.map((MeasureTypeName: string, index: any) => {
                    return ({
                        key: MeasureTypeName.split(",")[1],
                        text: MeasureTypeName.split(",")[1],
                        id: MeasureTypeName.split(",")[0]
                    })
                });
                dispatch(MeasureDefinitionActionTypes.getMeasureTypes(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

export const startGetAllUnits = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = MeasureAPI.getAllUnits()
            .then((response) => {
                const data: any[] = response.map((MeasureTypeName: string, index: any) => {
                    return ({
                        key: MeasureTypeName.split(",")[1],
                        text: MeasureTypeName.split(",")[1],
                        id: MeasureTypeName.split(",")[0]
                    })
                });
                dispatch(MeasureDefinitionActionTypes.getAllUnits(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

export const startGetAllUnitTypes = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = MeasureAPI.getAllUnitTypes()
            .then((response) => {
                const data: any[] = response.map((MeasureTypeName: string, index: any) => {
                    return ({
                        key: MeasureTypeName.split(",")[1],
                        text: MeasureTypeName.split(",")[1],
                        id: MeasureTypeName.split(",")[0]
                    })
                });
                dispatch(MeasureDefinitionActionTypes.getAllUnitTypes(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

export const startGetAllTransactionalEntities = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = MeasureAPI.getAllTransactionalEntities(fiscalYear)
            .then((response) => {
                //Response from API is in form: "<EntityID>,<EntityName>"
                const data: any[] = response.map((EntityIdName: string, index: any) => {
                    return ({
                        key: EntityIdName.split(",")[1], 
                        text: EntityIdName.split(",")[1],
                        id: EntityIdName.split(",")[0]
                    })
                });
                dispatch(MeasureDefinitionActionTypes.getAllEntities(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

export const startGetDatasetColumns = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
       //Sending list of DatasetIDs to API in the form of a string separated by ','
        const res = EntityRelationhshipDefinitionAPI.getDatasetColumns(fiscalYear)
            .then((response) => {
                const data: any[] = response.map((EntityIdName: string, index: any) => {
                    return ({
                        key: EntityIdName.split(",")[1], 
                        text: EntityIdName.split(",")[1],
                        id: EntityIdName.split(",")[0]
                    })
                });
                dispatch(MeasureDefinitionActionTypes.getDatasetColumns(data.sort((a,b)=> a.text.localeCompare(b.text))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

export const clearAllEntitiesProps = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const data: any[] = [];
        dispatch(MeasureDefinitionActionTypes.getAllEntities(data));
    };
}

export const clearDatasetColumnsProps = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        dispatch(MeasureDefinitionActionTypes.clearDatasetColumns());
    };
};

export const clearSaveResultMsgProps = (loading: boolean) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        var tempResult: IMeasureMsgInfo = {
            Result: 5,
            StatusCode: 0,
            ErrorMessage: "",
            loading: loading,
        };
        dispatch(MeasureDefinitionActionTypes.getNewMeasureSaveSuccessAction(tempResult));
    };
};

const setERStateToApi = (measureState: INewPerspectiveState[]) => {
    const mInput : IMeasureInputToDB[] = [];
    measureState.forEach((element)=>{
        var count=element.MeasuresRowData.length;
    const mInputInner:IMeasureInputToDB={
        MeasureName:element.MeasureName!=undefined?element.MeasureName:"",
        MeasureTypeName:element.MeasureTypeName!=undefined?element.MeasureTypeName:"",
        PerspectiveName:element.PerspectiveName!=undefined?element.PerspectiveName:"",
        UnitName:element.UnitName!=undefined?element.UnitName:"",
        UnitTypeName:element.UnitTypeName!=undefined?element.UnitTypeName:"",
        FiscalYearID:element.FiscalYear,
        MeasureID:0,
        MeasureAttributes:element.MeasuresRowData[count].MeasureAttributes
    }
    count=count+1;
    mInput.push(mInputInner);
    })
 return mInput;
 }

export const startUpdateMeasureToDB = (newMeasureState: INewPerspectiveState[], startGetAllMeasures: ()=>void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const measure = setERStateToApi(newMeasureState);
        const res = MeasureAPI.updateMeasureToDB(measure)
            .then((response) => {
                const result: IMeasureMsgInfo = response;
                let tempResult: IMeasureMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "Domain updated successfully." :
                                                (result.Result === 0 ? "Domain NOT updated successfully." : ""));
    
                        dispatch(MeasureDefinitionActionTypes.getNewMeasureSaveSuccessAction(tempResult));

                        if (startGetAllMeasures && (result != undefined && result.Result === 1)) 
                        startGetAllMeasures();
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};


