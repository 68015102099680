import { IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import * as TelemetryProvider from "../TelemetryProvider";

export async function handleResponse(response: any) {
  if (response.ok) {
      return response.json();
  }

  const responseText = await response.text();

  if (response.status === 400 || response.status === 409) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    throw new Error(responseText);
  }
  
  if(response.status === 403)
  {
    throw new Error("User doesnt have access to perform this operation");
  }

  console.error("API call failed. " + responseText);
  let errorDetails: IExceptionTelemetry = {
      error: responseText,
      severityLevel: 3,
  };

  TelemetryProvider._trackException(errorDetails);

  throw new Error("Network response was not ok.");
}

export function handleError(error: any): never {
  console.error("API call failed. " + error);
  let errorDetails: IExceptionTelemetry = {
    error: error,
    severityLevel: 3,
  };
  TelemetryProvider._trackException(errorDetails);
  throw error;
}
