import {
  IEventTelemetry,
  IPageViewTelemetry,
} from "@microsoft/applicationinsights-web";

export const domainDefinitionPageView: IPageViewTelemetry = {
  name: "Domain Definition",
  uri: "system/domain-definition",
};

export const domainDefinitionExportButtonClickEvent: IEventTelemetry = {
  name: "DomainDefinition_ExportButtonClick",
};
export const domainDefinitionDeleteButtonClickEvent: IEventTelemetry = {
  name: "DomainDefinition_DeleteButtonClick",
};
export const newDomainSaveButtonClickEvent: IEventTelemetry = {
  name: "DomainDefinition_NewDomain_SaveButtonClick",
};
export const newDomainClearButtonClickEvent: IEventTelemetry = {
  name: "DomainDefinition_NewDomain_ClearButtonClick",
};
// Done
