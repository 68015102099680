import { getDeepCopy } from "../../util/javascript-functions";
import { utcToAnyTimeZone } from "../../util/time-zone-util";
import * as types from "../types/domain-definition-action-types";
import { DomainDefinitionActionTypes } from "../types/domain-definition-action-types";

const initState = {
    DatasetColumnDetails: [{
        attributeLevelId: 0,
        datasetColumnNames: []
    }]
};
const domainDefinitionReducerDefaultState: any = {};

const DomainDefinitionReducer = (
    state = domainDefinitionReducerDefaultState,
    action: DomainDefinitionActionTypes
): any => {
    switch (action.type) {
        case types.GET_ALL_DOMAINS:
            return Object.assign({}, state, {
                allDomains: action.allDomains,
            });
        case types.GET_DOMAIN_TYPES:
            return Object.assign({}, state, {
                domainTypesList: action.domainTypesList,
            });
        case types.GET_ALL_ENTITIES:
            return Object.assign({}, state, {
                allEntitiesList: action.allEntitiesList,
            });
        case types.ADD_NEW_ENTITY:
            {   
                var entityDropdownOption = {
                    key: action.entity,
                    text: action.entity,
                    id: 0,
                };
                return Object.assign({}, state, {
                    allEntitiesList: [...state.allEntitiesList, entityDropdownOption],
                });
            }
        case types.GET_DATASET_COLUMNS:
            {
                var index = state.DatasetColumnDetails.findIndex((d: any) => d.attributeLevelId == action.attributeLevelId);
                if (index == -1) { //Append into array
                    return Object.assign({}, state, {
                        DatasetColumnDetails: [...state.DatasetColumnDetails, {
                            attributeLevelId: action.attributeLevelId,
                            datasetColumnNames: action.datasetColumnNames
                        }]
                    });
                }
                else { //Update in array
                    const newArray = getDeepCopy(state.DatasetColumnDetails);
                    newArray[index].datasetColumnNames = [...action.datasetColumnNames];
                    return Object.assign({}, state, {
                        DatasetColumnDetails: newArray,
                    });
                }
            }
        case types.GET_NEW_DOMAIN_SAVE_SUCCESS:
            return Object.assign({}, state, {
                saveResultMsg: action.saveResultMsg,
            });
        case types.CLEAR_DATASET_COLUMNS:
            return Object.assign({}, state, {
                DatasetColumnDetails: [],
            });
        case types.DELETE_DOMAIN:
            return Object.assign({}, state, {
                allDomains: action.allDomains,
            });
        default:
            return state;
    }
};

export default DomainDefinitionReducer;