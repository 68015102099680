import React from "react";
import productIcon from "./../../../assests/image/Mint_SystemConfig_Icon_Black.jpg";
import "./mint-header.scss";
import { FontIcon, IStackTokens, Stack } from "office-ui-fabric-react";
import Dropdown from "../dropdown/dropdown.controller";
import { IDropdownOption } from "office-ui-fabric-react";
import { connect } from "react-redux";
import {
  getFiscalYear,
  getFiscalYearList,
} from "../../../redux/actions/fiscal-year-actions";
import { AppState } from "../../../redux/configureStore";
import { AppActions } from "../../../redux/types/app-actions";
import { ThunkDispatch } from "redux-thunk";
import { Link } from "react-router-dom";

export const stackTokens: IStackTokens = { childrenGap: 20 };

interface IMintHeaderProps {
  account: any;
}

interface IMintHeaderLinkStateProps {
  fiscalYearList?: any[];
  fiscalYear?: number;
}

interface IMintHeaderLinkDispatchProps {
  getFiscalYearList: (fiscalyearList: any[]) => any;
  getFiscalYear: (fiscalYear: number) => any;
}

type Props = IMintHeaderProps &
  IMintHeaderLinkStateProps &
  IMintHeaderLinkDispatchProps;

const MintHeader: React.FunctionComponent<Props> = (props) => {
  const _onDropDownFiscalyearChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const FiscalYear = Number(item == null ? "" : item.text);
    props.getFiscalYear(FiscalYear);
  };

  return (
    <div className="header">
      <div className="product-details">
        <div className="toggle-header"></div>
        <div>
		  <Link className="side-nav-button-link" to={"/"}>
			<img
				className="product-image"
				style={{ display: "block" }}
				src={productIcon}
				alt="No Image"
			></img>
		  </Link>
        </div>
        <h1></h1>
      </div>
      <Stack
        className="user"
        horizontal
        tokens={stackTokens}
        horizontalAlign="end"
      >
        Fiscal Year: &nbsp;
        
        <span className="fiscal-year-drop-down">
          <Dropdown
            placeholder={""}
            options={
              props.fiscalYearList != undefined ? props.fiscalYearList : []
            }
            //defaultSelectedKey={2021}
            // label={"Fiscal Year"}
            selectedKey={props.fiscalYear}
            onChange={_onDropDownFiscalyearChange}
            ariaLabel={"Fiscal Year selection"}
          />
        </span>
        
        <div className="user-details">
          <FontIcon iconName="Contact" className="user-icon"></FontIcon>
          <div className="name-role">
            {props.account.name}
            <br />
            Role
          </div>
        </div>
      </Stack>
    </div>
  );
};

const mapStateToProps = (state: AppState): IMintHeaderLinkStateProps => {
  return {
    fiscalYearList: state.fiscalYear.fiscalYearList,
    fiscalYear: state.fiscalYear.fiscalYear,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): IMintHeaderLinkDispatchProps => {
  return {
    getFiscalYearList: (fiscalyearList: any[]) =>
      dispatch(getFiscalYearList(fiscalyearList)),
    getFiscalYear: (fiscalYear: number) => dispatch(getFiscalYear(fiscalYear)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MintHeader);
