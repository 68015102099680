import { AppActions } from "../types/app-actions";
import * as types from "../types/entity-onboarding-action-types";
import { IEntityOnboarding, IEntityOnboardingList } from "../../components/onboarding/entity-onboarding/model/IEntityOnboarding";

export const getAllEntities = (
  totalEntities: IEntityOnboardingList
): AppActions => {
  return ({
    type: types.GET_ALL_ENTITIES,
    totalEntities,
  });
};
export const deleteEntity = (
  data: IEntityOnboardingList
): AppActions => {
  return ({
    type: types.DELETE_ENTITY,
    data,
  });
};
