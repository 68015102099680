import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { IColumn } from "office-ui-fabric-react";
import { GetToken } from "../auth/authorization-token";
import { IMeasureInput } from "../components/onboarding/measure-definition/new-measure/model/INewMeasure";
import { IMeasureInputToDB } from "../components/onboarding/measure-definition/model/IMeasure";

let authToken = "";
const baseUrl = Configurations.mintSystemConfigApiBaseUrl + "/Measure/";

const columns: IColumn[] = [];

export function getAllMeasures() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'Measures', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getAllTransactionalEntities(fiscalYear: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetAllTransactionalEntities?fiscalYear=' + fiscalYear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getPerspectiveDetails(fiscalYear: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetPerspectiveDetails?fiscalYear=' + fiscalYear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getMeasureTypes() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetAllMeasureTypes', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getAllUnits() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetAllUnits', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getAllUnitTypes() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetAllUnitTypes', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function updateMeasureToDB(measureInput: IMeasureInputToDB[]) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'EditMeasure', {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(measureInput),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}