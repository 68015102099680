import * as React from "react";
import { PrimaryButton } from "office-ui-fabric-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import PrimaryButtonView from "../../common/button/primary-button/primary-button.view";
import { ModalView } from "../../common/modal/modal.view";
import NewER from "../entityrelationship-definition/new-entityrelationship/new-entityrelationship.controller";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import {
  IDetailsListProps,
  IDetailsRowStyles,
  IDetailsRowBaseProps,
  SearchBox,
  DetailsRowCheck,
  ScrollablePane,
  ScrollbarVisibility,
} from "office-ui-fabric-react";
import NewTransactionControl from "../../onboarding/entityrelationship-definition/new-TransactionSource";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { entityRelationshipExportButtonClickEvent } from "./entity-relationship.telemtetry-constants";

type EntityRelationshipViewProps = {
  allEntityRelationships: any;
  columnDefs: any[];
  rowData: any[];
  startGetAllEntityRelationships: () => void;
  editModalParams: any;
  showModal: boolean;
  updateIsEditModalVisible: any;
  gridCellKeyPress: any;
  isNewModal: boolean;
};

var newTranscationControl = () => {
  return (
    <PrimaryButton
      text="+ New Transaction Source"
      onClick={() => {}}
    ></PrimaryButton>
  );
};

var newTransactionSourceModal = {
  title: "Create Transaction Source",
  control: newTranscationControl(),
  onDismissCloseModal: false,
};

export class EntityRelationship extends React.Component<
  EntityRelationshipViewProps
> {
  public render(): JSX.Element {
    var editParams = {
      ActionType: "new",
      EntityRelationshipID: 0,
      Entity1_ID: 0,
      Entity2_ID: 0,
    };

    return (
      <div>
        <div className="new-button-div">
          <PrimaryButtonView
            text="Create New Entity Relationship"
            onClick={() => {
              this.props.updateIsEditModalVisible(true);
            }}
          />
        </div>
        <div>
          <div
            className="ag-theme-balham"
            style={{ height: "450px", width: "100%", marginTop: "11px" }}
          >
            <AgGridReact
              colWidth={130}
              columnDefs={this.props.columnDefs}
              rowData={this.props.rowData}
              paginationPageSize={15}
              enableRangeSelection={true}
              pagination={true}
              onCellKeyDown={(event: any) => {
                this.props.gridCellKeyPress(event);
              }}
            ></AgGridReact>
          </div>
        </div>

        <div className="new-button-div" style={{ marginTop: "11px" }}>
          <PrimaryButtonView
            text="Export to Excel"
            onClick={this._onExportButtonClicked}
          />
        </div>
        {this.props.showModal === true ? (
          this.props.isNewModal ? (
            <NewER
              {...{
                ActionType: "new",
                EntityRelationshipID: 0,
                Entity1_ID: 0,
                Entity2_ID: 0,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
                startGetAllEntityRelationships: this.props.startGetAllEntityRelationships,
              }}
            />
          ) : (
            <NewER
              {...{
                ...this.props.editModalParams,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          )
        ) : (
          <></>
        )}
      </div>
    );
  }

  private _onExportButtonClicked = () => {
    TelemetryProvider._trackEvent(entityRelationshipExportButtonClickEvent);
    OnboardingSummaryUtility._onExportButtonClicked(
      this.props.rowData,
      "EntityRelationship"
    );
  };
}

export default EntityRelationship;
