import React from "react";
import { IDetailsListProps, IDetailsRowStyles, DetailsRow, SelectionMode,
  IDetailsRowBaseProps, SearchBox, DetailsRowCheck } from "office-ui-fabric-react";
import "./onboarding-summary.scss";
import { ICustomDetailsHeaderProps } from "./model/ICustomDetailHeaderProps";
import XLSX from "xlsx";

export class OnboardingSummaryUtility extends React.Component<{}, {}> {
public _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};    
    if (props) {
      if (props.itemIndex % 2 !== 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: '#fbfbfe' };
      }
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  public _onRenderDetailsHeader(detailsFooterProps?: ICustomDetailsHeaderProps): JSX.Element {
    return (
      <DetailsRow className="header-div"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selectionMode={SelectionMode.single}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderDetailsHeaderItemColumn}
        onRenderCheck={_onRenderCheckForHeaderRow}
      />
    );
  }

  public static _onExportButtonClicked(data: any[], filename: string) {
    var SheetNames: any[] = [];
    var Sheets: { [paramname: string]: any } = {};
    var wb = { SheetNames, Sheets };
    const ws = XLSX.utils.json_to_sheet(data);
    wb.SheetNames.push(filename);
    wb.Sheets[filename] = ws;
    const wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
    });
    let url = window.URL.createObjectURL(
        new Blob([_s2ab(wbout)], { type: "application/octet-stream" })
    );
    _download(url, filename + "_Summary.xlsx");
  }

}

const _renderDetailsHeaderItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
  if (column?.fieldName !== "actions") {
    return (
      <div>
          <div className="column-names-div" >
        <b>{column?.name}</b></div>
        <div> 
          <SearchBox
          styles={{ root: { borderRadius: 10, marginTop: 5,  border:'1px solid #F2F2F0',} }}
          placeholder="Search"
          onEscape={ev => {
            console.log('Custom onEscape Called');
          }}
          onClear={ev => {
            console.log('Custom onClear Called');
          }}
          onChange={(_, newValue) => console.log('SearchBox onChange fired: ' + newValue)}
          onSearch={newValue => console.log('SearchBox onSearch fired: ' + newValue)}
          onFocus={() => console.log('onFocus called')}
          onBlur={() => console.log('onBlur called')}/>
          </div>
      </div>
    );
  } else {
    return (<div className="action-col-div">
        <b>{column?.name}</b></div>);    
  }
  return null;
};

const _onRenderCheckForHeaderRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
  return <DetailsRowCheck {...props} styles={{ root: { visibility: 'hidden' } }} selected={true} />;
};

const _s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

const _download = (url: any, name: any) => {
  let a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
};