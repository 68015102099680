import * as React from "react";
import { PrimaryButton } from "office-ui-fabric-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import editImage from "./../../../../src/assests/image/MintSystemConfig_Edit-icon.png";
import PrimaryButtonView from "../../common/button/primary-button/primary-button.view";
import { ModalView } from "../../common/modal/modal.view";
import NewDomain from "./new-domain/new-domain.controller";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import * as TelemetryProvider from "../../../TelemetryProvider";
import { domainDefinitionExportButtonClickEvent } from "./domain-definition.telemtetry-constants";

type DomainDefinitionViewProps = {
  allDomains: any;
  columnDefs: any[];
  rowData: any[];
  startGetAllDomains: () => void;
  editModalParams: any;
  showModal: boolean;
  updateIsEditModalVisible: any;
  gridCellKeyPress: any;
  isNewModal: boolean;
};

export class DomainDefinition extends React.Component<
  DomainDefinitionViewProps
> {
  public render(): JSX.Element {
    return (
      <div>
        <div className="new-button-div">
          <PrimaryButtonView
            text="Create New Domain"
            onClick={() => {
              this.props.updateIsEditModalVisible(true);
            }}
          />
        </div>

        <div>
          <div
            className="ag-theme-balham"
            style={{ height: "450px", width: "100%", marginTop: "11px" }}
          >
            <AgGridReact
              columnDefs={this.props.columnDefs}
              rowData={this.props.rowData}
              paginationPageSize={15}
              enableRangeSelection={true}
              pagination={true}
              onCellKeyDown={(event: any) => {
                this.props.gridCellKeyPress(event);
              }}
            ></AgGridReact>
          </div>
        </div>

        <div className="new-button-div" style={{ marginTop: "11px" }}>
          <PrimaryButtonView
            text="Export to Excel"
            onClick={this._onExportButtonClicked}
          />
        </div>
        {this.props.showModal === true ? (
          this.props.isNewModal ? (
            <NewDomain
              {...{
                startGetAllDomains: this.props.startGetAllDomains,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          ) : (
            <NewDomain
              {...{
                ...this.props.editModalParams,
                showModal: this.props.showModal,
                updateIsEditModalVisible: this.props.updateIsEditModalVisible,
              }}
            />
          )
        ) : (
          <></>
        )}
      </div>
    );
  }

  private _onExportButtonClicked = () => {
    TelemetryProvider._trackEvent(domainDefinitionExportButtonClickEvent);
    OnboardingSummaryUtility._onExportButtonClicked(
      this.props.rowData,
      "DomainDefinition"
    );
  };
}

export default DomainDefinition;
