import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, ColDef, RowSelectedEvent } from "ag-grid-community";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './new-domain.scss';
import { IDropdownOption, Label, TextField, IconButton, FontIcon, PrimaryButton, Checkbox, Spinner, SpinnerSize, fitContentToBounds, arraysEqual } from 'office-ui-fabric-react';
import { IDropdownPropsCustom } from '../../../common/dropdown/models/IDropdown';
import Dropdown from '../../../common/dropdown/dropdown.controller';
import { connect } from 'react-redux';
import { AppState } from '../../../../redux/configureStore';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../../../redux/types/app-actions';
import { bindActionCreators } from 'redux';
import { startGetDatasetColumns } from './domain-attribute-level.service';
import { IDomainAttributeLevel, IDomainAttribute } from './model/INewDomain';
import { getDeepCopy } from '../../../../util/javascript-functions';

interface DomainAttributeLevelProps {
    id: number;
    index: number;
    InitDomainAttributeLevels?: IDomainAttributeLevel; 
    EntityIDNameOptions: IDropdownOption[] | undefined;
    _onAttributeLevelEntityChange: (attributeLevelID: number, item: IDropdownOption) => void;
    _onHierarchyLevelNameChange: (attributeLevelID: number, hierarchyLevelName: string) => void;
    _onDomainAttributesDetailsChange: (attributeLevelID: number, oldEntityName: string, oldDomainAttributeName: string,domainAttribute: any, isSelected: boolean) => void;
    _deleteLevelButtonClicked: (attributeLevelID: number) => void;
       
    store?: any; //Unit testing
}

interface DomainAttributeLevelState {
    gridApi: any;
    HierarchyLevelName: string;
    rowData?: any[];
    SelectedEntities?: string[];
    SelectedEntitiesIDName?: any[];
    DatasetColumnNames?: string[];
    SelectedAttributes: string[];
}

export interface IDomainAttributeLevelLinkStateProps {
    DatasetColumnDetails: any[];
}

export interface IDomainAttributeLevelLinkDispatchProps {
    startGetDatasetColumns: (hierarchyLevel: number, datasetIds: number[]) => void;
}

type Props = DomainAttributeLevelProps & IDomainAttributeLevelLinkStateProps & IDomainAttributeLevelLinkDispatchProps;

export class DomainAttributeLevel extends React.Component<Props, DomainAttributeLevelState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            gridApi: undefined,
            HierarchyLevelName: this.props.InitDomainAttributeLevels == undefined ? "" : 
                                this.props.InitDomainAttributeLevels.HierarchyLevelName,
            SelectedEntities: this.props.InitDomainAttributeLevels == undefined ? [] : 
                                this.props.InitDomainAttributeLevels.Entities?.map((value) => { return value.name }),
            SelectedEntitiesIDName: this.props.InitDomainAttributeLevels == undefined ? [] : 
                                this.props.InitDomainAttributeLevels.Entities,
            rowData: this.setRowData(),
            SelectedAttributes: this.setSelectedAttributes(),
            DatasetColumnNames: this.props.DatasetColumnDetails[this.props.id],
        };
    }

    componentWillMount(){
        this._onDismissEntityDropDown(); //to call API to receive DatasetColumns
    }

    componentDidUpdate(){
        if (this.state.gridApi != undefined) {
            this.state.gridApi.forEachNode((node: any) => {
                node.setSelected(this.state.SelectedAttributes?.includes(node.data.EntityName + ',' + node.data.DomainAttributeName));
            });
        }
    }

    private setRowData = (): any => {
        var rowData: any[] =[];

        if (this.props.InitDomainAttributeLevels != undefined) {
            var attributes = this.props.InitDomainAttributeLevels.DomainAttributes;
            attributes.forEach(a => {
                rowData.push(
                    {
                    "EntityID": a.EntityID,
                    "EntityName": a.EntityName,
                    "DomainAttributeName": a.DomainAttributeName,
                    "DomainAttributeQualifier": a.DomainAttributeQualifier != 'NULL' ? a.DomainAttributeQualifier : "",
                    "AttributeDisplayName": a.AttributeDisplayName != 'NULL' ? a.AttributeDisplayName : "",
                    "DefaultValue": a.DefaultValue != 'NULL' ? a.DefaultValue : "",
                    "DataTypeName": a.DataTypeName != 'NULL' ? a.DataTypeName : "",
                    }
                );
            })
        }
        return rowData;
    }

    private setSelectedAttributes = (): string[] => {
        var SelectedAttributes : string[] = [];

        if(this.props.InitDomainAttributeLevels != undefined) {
            var attributes = this.props.InitDomainAttributeLevels.DomainAttributes;
            attributes.forEach(a => {
                SelectedAttributes.push(a.EntityName + ',' + a.DomainAttributeName);
            })
        }
        return SelectedAttributes;
    } 

    private getColDefs() {
        var colDefs : ColDef[] = [
            {field: 'EntityID', hide: true, width: 50},
            {field: '', checkboxSelection: true, width: 30},
            {field: 'EntityName', editable: true, singleClickEdit: true, resizable: true, flex: 1, tooltipField:'EntityName'},
            {headerName: "Entity Attribute Name", field: 'DomainAttributeQualifier', editable: true, singleClickEdit: true, resizable: true, flex: 1, tooltipField:'DomainAttributeQualifier'},
            {field: 'DomainAttributeName', editable: true, singleClickEdit: true, resizable: true, flex: 1, tooltipField:'DomainAttributeName'},
            {headerName: "Attribute Display Name (Crediting)",field: 'AttributeDisplayName', editable: true, singleClickEdit: true, resizable: true, flex: 1, tooltipField:'AttributeDisplayName'},
            {field: 'DefaultValue', editable: true, singleClickEdit: true, resizable: true, flex: 1, tooltipField:'DefaultValue'},
            {field: 'DataTypeName', editable: true, singleClickEdit: true, resizable: true, flex: 1, tooltipField:'DataTypeName'},
        ];
        return colDefs;
    }

    private getRowData() {
        if (this.props.DatasetColumnDetails != undefined) {
            var columns = this.props.DatasetColumnDetails.find((d:any) => d.attributeLevelId == this.props.id);
            
            if (columns != undefined && 
                JSON.stringify(columns.datasetColumnNames) != JSON.stringify(this.state.DatasetColumnNames)) {
                
                var DatasetColumnNames = columns.datasetColumnNames;
                var rowData = this.state.rowData != undefined ? getDeepCopy(this.state.rowData) : [];

                //If current Entity is not selected, remove its Attributes from rowData
                for (var i = rowData.length-1; i >= 0; i--) {
                    if (this.state.SelectedEntitiesIDName?.find((a: any) => a.id == rowData[i].EntityID) == undefined) {
                        rowData.splice(i, 1);
                    }
                }

                //If Entity is selected for first time, append it to rowData
                for (var i = 0; i < DatasetColumnNames.length; i++) {
                    var entityID = Number((DatasetColumnNames[i].split(',')[0]));
                    var domainAttributeName = DatasetColumnNames[i].split(',')[1];
                    
                    var attribute = rowData.find((a: any) => a.EntityID == entityID && a.DomainAttributeName == domainAttributeName);
                    if (attribute == undefined) {
                        rowData.push(
                            {
                            "EntityID": entityID,
                            "EntityName": this.state.SelectedEntitiesIDName?.find(e => e.id == entityID)?.name,
                            "DomainAttributeName": domainAttributeName,
                            "DomainAttributeQualifier": domainAttributeName,
                            "AttributeDisplayName": "[Click to edit]",
                            "DefaultValue": "[Click to edit]",
                            "DataTypeName": "[Click to edit]",
                            }
                        );
                    }
                }
                this.setState({
                    DatasetColumnNames,
                    rowData,
                });
                return rowData;
            }
            else {
                return this.state.rowData;
            }
        }
        else {
            return this.state.rowData;
        }
    }

    private onGridReady = (params: { api: any; }) => {
        this.setState({ gridApi: params.api });

        if (this.state.gridApi != undefined) {        
            this.state.gridApi.forEachNode((node: any) => {
                node.setSelected(this.state.SelectedAttributes?.includes(node.data.EntityName + ',' + node.data.DomainAttributeName));
            });
        }
    }

    private _onCellValueChanged = (event: CellValueChangedEvent) => {   
        if (event.node.isSelected()) {
            var field = event.colDef.field;
    
            if (field == 'EntityName') {
                if (!this.state.SelectedEntities?.includes(event.data.EntityName)) {
                    event.node.setData({
                        'DomainAttributeName': event.data.DomainAttributeName,
                        'DomainAttributeQualifier': event.data.DomainAttributeQualifier,
                        'AttributeDisplayName': event.data.AttributeDisplayName,
                        'DefaultValue': event.data.DefaultValue,
                        'EntityID': event.data.EntityID,
                        'EntityName': event.oldValue,
                        'DataTypeName': event.data.DataTypeName,
                    });
                    //We need to explicitly set the state.rowData back to its original value
                    var rowData = this.state.rowData != undefined ? getDeepCopy(this.state.rowData) : [];
                    rowData[event.node.id].EntityName = event.oldValue;
                    this.setState({
                        rowData
                    });
                    alert(event.data.EntityName + ' is not a valid Entity. Please enter a valid Entity Name.');
                    return;
                }

                var oldAttribute = event.oldValue + ',' + event.data.DomainAttributeName;
                var newAttribute = event.data.EntityName + ',' + event.data.DomainAttributeName;
                const newArray = [...this.state.SelectedAttributes];
                var index = newArray.findIndex(a => a == oldAttribute);
                if (index != -1) newArray.splice(index, 1);
                newArray.push(newAttribute);
                
                this.setState({
                    SelectedAttributes: newArray
                });
                this.props._onDomainAttributesDetailsChange(this.props.id, event.oldValue, event.data.DomainAttributeName, 
                    event.data, event.node.isSelected());
            }
            else if (field == 'DomainAttributeName') {
                var oldAttribute = event.data.EntityName + ',' + event.oldValue;
                var newAttribute = event.data.EntityName + ',' + event.data.DomainAttributeName;
                const newArray = [...this.state.SelectedAttributes];
                var index = newArray.findIndex(a => a == oldAttribute);
                if (index != -1) newArray.splice(index, 1);
                newArray.push(newAttribute);
                
                this.setState({
                    SelectedAttributes: newArray
                });
                this.props._onDomainAttributesDetailsChange(this.props.id, event.data.EntityName, event.oldValue, 
                    event.data, event.node.isSelected());
            }
            else {
                this.props._onDomainAttributesDetailsChange(this.props.id, event.data.EntityName, event.data.DomainAttributeName, 
                    event.data, event.node.isSelected());
            }
        }
    }

    private _onRowSelected = (event: RowSelectedEvent) => {
        //Row can be selected only when EntityName and DomainAttributeName is defined.
        if (event.data.EntityName == '[Click to edit]' || 
            event.data.DomainAttributeQualifier == '[Click to edit]' ||
            event.data.DomainAttributeName == '[Click to edit]') 
        {
            if (event.node.isSelected())
                alert('Please enter Entity Name, Domain Attribute Qualifier and Domain Attribute Name.');
            event.node.setSelected(false);
            return;
        }

        //Row can be selected only when we enter a valid Entity Name.
        if (event.node.isSelected() && !this.state.SelectedEntities?.includes(event.data.EntityName)) {
            event.node.setSelected(false);
            alert(event.data.EntityName + ' is not a valid Entity. Please enter a valid Entity Name.');
            return;
        }

        var attribute = event.data.EntityName + ',' + event.data.DomainAttributeName;
        if (this.state.SelectedAttributes != undefined) {
            this.setState({
                SelectedAttributes: event.node.isSelected() ? [...this.state.SelectedAttributes, attribute as string] :
                                    this.state.SelectedAttributes.filter(a => a != attribute) 
            });
        }
        this.props._onDomainAttributesDetailsChange(this.props.id, event.data.EntityName, event.data.DomainAttributeName, 
            event.data, event.node.isSelected());
    }

    private _onDropDownEntityChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number): void => {
        var EntityKey = (item == null ? "" : item.key);
        var EntityID = Number((item == null ? "" : item.id));
        var EntityName = (item == null ? "" : item.text);
        var obj = { id: EntityID, name: EntityName };

        if (item != undefined)
            this.props._onAttributeLevelEntityChange(this.props.id, item);

        //If Entity is deselected, remove & de-select its corresponding DomainAttributes
        if (item?.selected == false) {
            if (this.state.gridApi != undefined) {            
                var selectedNodes = this.state.gridApi.getSelectedNodes();
                selectedNodes?.forEach((node: any) => {
                    if (node.data.EntityName == EntityName) {
                        this.setState(function(currentState) {
                            return { SelectedAttributes: currentState.SelectedAttributes?.
                                                        filter(a => a != node.data.EntityName + ',' + node.data.DomainAttributeName)};
                        });
                        node.setSelected(false);
                    }  
                });
            }
        }

        if(this.state.SelectedEntities != undefined && this.state.SelectedEntitiesIDName != undefined){
            this.setState({
                SelectedEntities: (item?.selected ? [...this.state.SelectedEntities, EntityKey as string] : 
                                                    this.state.SelectedEntities.filter( (key:string) => key != EntityKey) ),

                SelectedEntitiesIDName: (item?.selected ? [...this.state.SelectedEntitiesIDName, obj] :
                                                            this.state.SelectedEntitiesIDName.filter( obj => obj.name != EntityName) ),
            });
        }
    }

    private _onDismissEntityDropDown = (): void => {
        
        if(this.state.SelectedEntitiesIDName != undefined && this.state.SelectedEntitiesIDName.length != 0){
            var EntityIDs: number[] = this.state.SelectedEntitiesIDName.map((obj: any, index:number) => {
                return (obj.id)
            });
            this.props.startGetDatasetColumns(this.props.id, EntityIDs);
        }
    }

    private _onHierarchyLevelNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        const HierarchyLevelName = (newValue == null ? "" : newValue);
        this.props._onHierarchyLevelNameChange(this.props.id, HierarchyLevelName);
        this.setState({
            HierarchyLevelName,
        });
    }

    private _newDomainAttributeButtonClicked = (): void => {
        var rowData = this.state.rowData != undefined ? getDeepCopy(this.state.rowData) : [];
        rowData.push({
            "EntityID": 0,
            "EntityName": "[Click to edit]",
            "DomainAttributeName": "[Click to edit]",
            "DomainAttributeQualifier": "[Click to edit]",
            "AttributeDisplayName": "[Click to edit]",
            "DefaultValue": "[Click to edit]",
            "DataTypeName": "[Click to edit]",
        });
        this.setState({
            rowData,
        });
    }

    private _deleteButtonClicked = (): void => {
        this.props._deleteLevelButtonClicked(this.props.id);
    }


    public render(): JSX.Element {

        const dropDownEntity: IDropdownPropsCustom = {
            placeholder: "Select a value",
            label: "Entity:",
            onChange: this._onDropDownEntityChange,
            options: this.props.EntityIDNameOptions != undefined ? this.props.EntityIDNameOptions : [],
            required: true,
            multiSelect: true,
            selectedKeys: this.state.SelectedEntities,
            onDismiss: this._onDismissEntityDropDown,
        };

        return (
        <div>
            <fieldset className="attribute-level">
            <legend> Attribute Level: {this.props.index} </legend>
                
                <div className="new-domain-buttons-list">
                    <IconButton iconProps={{
                        iconName: 'ChromeClose',
                        style: { fontSize: 12 }
                    }}
                        title='Delete'
                        onClick={this._deleteButtonClicked}
                        ariaLabel='Delete Attribute Level'
                    />
                </div>

                <div className="combine-cls">
                    <div style={{width: '240px'}}>
                    <Dropdown {...dropDownEntity} />
                    </div>

                    <div style={{width: '240px'}}>
                    <TextField
                        label={'Hierarchy Level Name:'}
                        required={true}
                        value={this.state.HierarchyLevelName}
                        onChange={this._onHierarchyLevelNameChange}
                    />
                    </div>        
                </div>

                <div className="ag-theme-balham" style={{ width: '100%', marginTop: '11px' }} >
                    <AgGridReact
                        columnDefs={this.getColDefs()}
                        rowData={this.getRowData()}
                        domLayout={'autoHeight'}
                        onGridReady={this.onGridReady}
                        rowSelection={'multiple'}
                        suppressRowClickSelection={true}
                        onCellValueChanged={this._onCellValueChanged}
                        onRowSelected={this._onRowSelected}
                        stopEditingWhenGridLosesFocus={true}
                        enableBrowserTooltips={true}
                        rowBuffer={0}
                    />
                </div>

                <div className="new-domain-buttons-list">
                    <PrimaryButton
                        className="add-row-button"
                        text={'Domain Attribute'}
                        title={'New Domain Attribute'}
                        iconProps={ { iconName: 'Add', style: { fontSize: 12 } } }
                        onClick={this._newDomainAttributeButtonClicked}
                    />
                </div>

            </fieldset>
        </div>
        );
    }

}

const mapStateToProps = (
    state: AppState
): IDomainAttributeLevelLinkStateProps => {
    return {
        DatasetColumnDetails: state.domainDefinition.DatasetColumnDetails,
    };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, AppActions>
): IDomainAttributeLevelLinkDispatchProps => ({
    startGetDatasetColumns: bindActionCreators(startGetDatasetColumns, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DomainAttributeLevel);