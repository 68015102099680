import React from "react";
import {
  IDropdownOption,
  Label,
  TextField,
  PrimaryButton,
  FontIcon,
  Checkbox,
  Spinner,
  SpinnerSize,
  DefaultButton,
  DetailsList,
  IColumn,
  Selection,
  SelectionMode,
  IDetailsRowProps,
  IRenderFunction,
  DetailsRow,
  DetailsRowFields,
  IDetailsRowFieldsProps,
  keyframes,
  Modal,
} from "office-ui-fabric-react";
import { IDropdownPropsCustom } from "../../../common/dropdown/models/IDropdown";
import Dropdown from "../../../common/dropdown/dropdown.controller";
import { connect } from "react-redux";
import {
  startGetDatasetColumnsWithAlias,
  startGetAllEntities,
  startSaveEntityRelationshipToDB,
  startUpdateERToDB,
  clearSaveResultMsgProps,
  clearDatasetColumnsProps,
  startGetDatasetColumns,
  clearAllEntitiesProps,
  startGetTransactionDatasetDetails,
} from "./new-entityrelationship.service";
import {
  IERRowData,
  INewERLinkDispatchProps,
  INewEntityRelationshipLinkStateProps,
  IEntityRelationshipInput,
  IEntityRelationshipMsgInfo,
  INewEntityRelationshipState,
  INewEntityRelationshipProps,
  IDataSetDetails,
} from "./model/INewEntityRelationship";
import { AppState } from "../../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import { bindActionCreators } from "redux";
import { getDeepCopy } from "../../../../util/javascript-functions";
import ComboBox from "../../../common/combo-box/combo-box.controller";
import * as TelemetryProvider from "../../../../TelemetryProvider";

import { ModalView } from "../../../common/modal/modal.view";
import {
  IDetailsListProps,
  IDetailsRowStyles,
  IDetailsRowBaseProps,
  SearchBox,
  DetailsRowCheck,
  ScrollablePane,
  ScrollbarVisibility,
} from "office-ui-fabric-react";
import {
  newEntityRelationshipClearButtonClickEvent,
  newEntityRelationshipSaveButtonClickEvent,
} from "../../entity-relationship/entity-relationship.telemtetry-constants";
import {
  contentStyles,
  iconButtonStyles,
} from "../../../common/modal/modal.style";
import IconButton from "../../../common/button/icon-button/icon-button.controller";

type Props = INewEntityRelationshipProps &
  INewEntityRelationshipLinkStateProps &
  INewERLinkDispatchProps;

class NewER extends React.Component<Props, INewEntityRelationshipState> {
  private ERRowsCount: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      ActionType: this.props.ActionType,
      FiscalYear: this.props.fiscalYear,
      EntityID: this.props.EntityID,
      EntityName: this.props.EntityName,
      EntityRelationshipRowData: this.setEntityRelationshipRowData(),
    };

    this.props.startGetAllEntities(this.props.fiscalYear);
    this.props.startGetDatasetColumns(this.props.fiscalYear);
    this.props.startGetDatasetColumnsWithAlias(this.props.fiscalYear);
    this.props.startGetTransactionDatasetDetails(this.props.fiscalYear);
    this.onRenderRow = this.onRenderRow.bind(this);
    this.renderRowFields = this.renderRowFields.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  private setEntityRelationshipRowData(): IERRowData[] {
    var EntityRelationshipRowData: IERRowData[] = [];
    var relatedEntityObjects = this.props.allEntityRelationships.find(e => 
                                e.EntityID == this.props.EntityID && e.FiscalYear == this.props.fiscalYear)?.RelatedEntityObjects;

    if (relatedEntityObjects != undefined) {
      relatedEntityObjects.forEach((er) => {
        EntityRelationshipRowData.push({
          id: 0,
          EntityRelationshipID: er.EntityRelationshipID,
          EntityID_1:
            this.props.EntityID != undefined ? Number(this.props.EntityID) : 0,
          EntityName_1:
            this.props.EntityName != undefined ? this.props.EntityName : "",
          AttributeName_1:
            er.Attribute1_Name != undefined ? er.Attribute1_Name : "",
          Attribute1_Qualifier:
            er.Attribute1_Qualifier != undefined ? er.Attribute1_Qualifier : "",
          EntityID_2: er.Entity2_ID,
          EntityName_2: er.Entity2_Name,
          Attribute2_Qualifier:
            er.Attribute2_Qualifier != undefined ? er.Attribute2_Qualifier : "",
          AttributeName_2:
            er.Attribute2_Name != undefined ? er.Attribute2_Name : "",
          DimRoleName: er.DimRoleName,
          JoinType: er.JoinType,
        });
      });

      EntityRelationshipRowData.sort((a, b) =>
        a.EntityName_2 < b.EntityName_2 ? -1 : 1
      );
      EntityRelationshipRowData.forEach((er) => {
        er.id = this.ERRowsCount;
        this.ERRowsCount++;
      });
    }
    console.log(EntityRelationshipRowData);
    return EntityRelationshipRowData;
  }

  private getAttributeNameOptions_1(item: IERRowData): IDropdownOption[] {
    if (this.props.DatasetColumnDetailsWithAlias == undefined) return [];

    return this.props.DatasetColumnDetailsWithAlias.filter(
      (d) => d.id == item.EntityID_1?.toString()
    );
  }

  private getAttributeNameOptions_2(item: IERRowData): IDropdownOption[] {
    if (this.props.DatasetColumnDetailsWithAlias == undefined) return [];

    return this.props.DatasetColumnDetailsWithAlias.filter(
      (d) => d.id == item.EntityID_2?.toString()
    );
  }

  private _onDropDownFiscalyearValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const FiscalYear = Number(item == null ? "" : item.text);
    var data: any = [];
    data = this.props.startGetAllEntities(FiscalYear);
    this.props.clearDatasetColumnsProps();
    this.setState({ FiscalYear });
  };

  private _clearbuttonClicked = (): void => {
    TelemetryProvider._trackEvent(newEntityRelationshipClearButtonClickEvent);
    this.props.clearSaveResultMsgProps(false);
    this.setState({
      EntityRelationshipRowData: [],
    });
  };

  private _savebuttonClicked = (): void => {
    //console.log(this.state); //for debugging

    TelemetryProvider._trackEvent(newEntityRelationshipSaveButtonClickEvent);
    if (this.state.EntityRelationshipRowData.length == 0) {
      alert("Please enter all the mandatory fields.");
      return;
    }

    //Validations
    for (var i = 0; i < this.state.EntityRelationshipRowData.length; i++) {
      var entityID_1 = this.state.EntityRelationshipRowData[i].EntityID_1;
      var entityID_2 = this.state.EntityRelationshipRowData[i].EntityID_2;
      var entityName_1 = this.state.EntityRelationshipRowData[i].EntityName_1;
      var entityName_2 = this.state.EntityRelationshipRowData[i].EntityName_2;

      var IsTransactionSource_1 = this.props.TransactionDatasetDetails?.find((d: any) => d.DatasetID == entityID_1)?.IsTransactionSource;
      var IsTransactionSource_2 = this.props.TransactionDatasetDetails?.find((d: any) => d.DatasetID == entityID_2)?.IsTransactionSource;

      if (
        IsTransactionSource_1 == false &&
        IsTransactionSource_2 == false &&
        this.state.EntityRelationshipRowData[i].DimRoleName !== ""
        ) {
          alert("Entity Filtering Rule can only be set in case of a transactional entity. Please check " + entityName_1 + " and " + entityName_2 + ".");
          return;
      }

      if (IsTransactionSource_1 == true && IsTransactionSource_2 == true) {
        alert("Both the entities in a relationship cannot be transactional. Please check " + entityName_1 + " and " + entityName_2 + ".");
        return;
      }
    }

    if (this.props.ActionType == "edit") {
      this.props.clearSaveResultMsgProps(true);
      this.props.startUpdateERToDB(
        this.state,
        this.props.startGetAllEntityRelationships
      );
    } else {
      this.props.clearSaveResultMsgProps(true);
      this.props.startSaveEntityRelationshipToDB(
        this.state,
        this.props.startGetAllEntityRelationships
      );
    }
  };

  private handleDismiss() {
    this.props.updateIsEditModalVisible(false);
  }

  public render(): JSX.Element {
    var _columns: IColumn[];

    _columns = [
      {
        key: "column1",
        name: "Entity 1",
        fieldName: "Entity1",
        minWidth: 180,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IERRowData) => {
          return (
            <ComboBox
              options={this.props.EntityIDNameOptions}
              ariaLabel={"Entity1"}
              placeholder={"Select a value"}
              dropdownWidth={350}
              selectedKey={item.EntityName_1}
              title={item.EntityName_1}
              onChange={(event, option) =>
                this._onDropDownEntity1Change(item.id, option)
              }
              disabled={this.props.ActionType === "edit" ? true : false}
            />
          );
        },
      },
      {
        key: "column2",
        name: "Entity 1 Attribute",
        fieldName: "Entity1 Attribute",
        minWidth: 180,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IERRowData) => {
          return (
            <ComboBox
              options={this.getAttributeNameOptions_1(item)}
              ariaLabel={"Entity1 Attribute"}
              placeholder={"Select a value"}
              dropdownWidth={200}
              selectedKey={
                this.getSelectedOption(
                  item.AttributeName_1,
                  this.getAttributeNameOptions_1(item)
                ) != undefined
                  ? this.getSelectedOption(
                      item.AttributeName_1,
                      this.getAttributeNameOptions_1(item)
                    )
                  : ""
              }
              onChange={(event, option) =>
                this._onDropDownAttribute1Change(item.id, option)
              }
            />
          );
        },
      },
      {
        key: "column3",
        name: "Entity 2",
        fieldName: "Entity2",
        minWidth: 180,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IERRowData) => {
          return (
            <ComboBox
              options={this.props.EntityIDNameOptions}
              ariaLabel={"Entity2"}
              placeholder={"Select a value"}
              dropdownWidth={350}
              selectedKey={item.EntityName_2}
              title={item.EntityName_2}
              onChange={(event, option) =>
                this._onDropDownEntity2Change(item.id, option)
              }
              disabled={
                this.props.ActionType === "edit" &&
                item.EntityRelationshipID != 0
                  ? true
                  : false
              }
            />
          );
        },
      },
      {
        key: "column4",
        name: "Entity 2 Attribute",
        fieldName: "Entity2 Attribute",
        minWidth: 180,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IERRowData) => {
          return (
            <ComboBox
              options={this.getAttributeNameOptions_2(item)}
              ariaLabel={"Entity2 Attribute"}
              placeholder={"Select a value"}
              dropdownWidth={200}
              selectedKey={
                this.getSelectedOption(
                  item.AttributeName_2,
                  this.getAttributeNameOptions_2(item)
                ) != undefined
                  ? this.getSelectedOption(
                      item.AttributeName_2,
                      this.getAttributeNameOptions_2(item)
                    )
                  : ""
              }
              onChange={(event, option) =>
                this._onDropDownAttribute2Change(item.id, option)
              }
            />
          );
        },
      },
      {
        key: "column8",
        name: "Entity 1 Attribute Custom Query",
        fieldName: "Entity1 Attribute Custom Query",
        minWidth: 180,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IERRowData) => {
          var qualifierValue = item.Attribute1_Qualifier != "" ? true : false;
          return (
            <TextField
              value={
                qualifierValue
                  ? item.Attribute1_Qualifier
                  : item.Attribute2_Qualifier
              }
              title={
                qualifierValue
                  ? item.Attribute1_Qualifier
                  : item.Attribute2_Qualifier
              }
              disabled={true}
              ariaLabel={"Entity 1 Attribute Custom Query"}
            />
          );
        },
      },
      {
        key: "column5",
        name: "Entity 2 Filtering Rule",
        fieldName: "Entity2 Filtering Rule",
        minWidth: 180,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <TextField
              value={item.DimRoleName}
              ariaLabel={"Entity2 Filtering Rule"}
              width={200}
              onChange={(event, newValue) =>
                this._onDimRoleNameChange(item.id, newValue)
              }
            />
          );
        },
      },
      {
        key: "column6",
        name: "Entity Linkage Behavior",
        fieldName: "Entity Linkage Behavior",
        minWidth: 180,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IERRowData) => {
          var a = "Left Join ";

          var optionList = [
            {
              key: item.EntityName_1 + " Left Join " + item.EntityName_2,
              text: item.EntityName_1 + " Left Join " + item.EntityName_2,
            },
          ];
          return (
            <ComboBox
              options={optionList}
              ariaLabel={"Entity Linkage Behavior"}
              placeholder={"Select a value"}
              dropdownWidth={350}
              selectedKey={item.JoinType}
              title={item.JoinType}
              onChange={(event, option) =>
                this._onDropDownJoinTypeChange(item.id, option)
              }
            />
          );
        },
      },
      {
        key: "column7",
        name: "Delete",
        minWidth: 30,
        maxWidth: 150,
        isResizable: true,
        onRender: (item: IERRowData) => {
         // if (item.EntityRelationshipID == 0)
            return (
              <IconButton
                styles={iconButtonStyles}
                iconName="Delete"
                title="Delete"
                fontSize={12}
                ariaLabel="Delete"
                onClick={(event) => this._deleteERButtonClicked(item)}
              />
            );
        },
      },
    ];

    //Need to change this to API
    var FiscalyearList = [
      { key: 2020, text: "2020" },
      { key: 2021, text: "2021" },
    ];
    //adding comment

    const dropDownFiscalYear: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Fiscal Year:",
      onChange: this._onDropDownFiscalyearValueChange,
      options: FiscalyearList,
      required: true,
      selectedKey: this.state.FiscalYear,
      disabled: this.props.ActionType === "edit" ? true : false,
    };

    return (
      <Modal
        isOpen={this.props.showModal}
        //onDismiss={this.handleDismiss}
        containerClassName={contentStyles.container}
        isBlocking={true}
      >
        <div className={contentStyles.header}>
          <span id="preview">New/Edit Entity Relationship</span>
          <IconButton
            styles={iconButtonStyles}
            iconName="Cancel"
            title="Close Modal"
            fontSize={12}
            ariaLabel="Close popup modal"
            onClick={this.handleDismiss}
          />
        </div>
        <div id="children" className={contentStyles.body}>
          <div>
            <div>
              <div className="new-domain-buttons-list">
                <PrimaryButton
                  text="Clear"
                  onClick={this._clearbuttonClicked}
                  className="header-buttons"
                  style={{ backgroundColor: "#288560" }}
                />
                <PrimaryButton
                  text="Save"
                  onClick={this._savebuttonClicked}
                  className="header-buttons"
                  style={{ backgroundColor: "#288560" }}
                  disabled={this.isSaveButtonDisabled()}
                />
              </div>

              {this.getMsgDetails(
                this.props.saveResultMsg != undefined
                  ? this.props.saveResultMsg
                  : {}
              )}

              {/*<div className="seperator-cls">
                    <Dropdown dropdownWidth={100} {...dropDownFiscalYear} />
                </div>*/}

              <div>
                <DetailsList
                  items={this.getERRowData()}
                  columns={_columns}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={false}
                  onRenderRow={this.onRenderRow}
                />
              </div>
            </div>
            <div className="new-domain-buttons-list">
              <PrimaryButton
                className="add-level-button"
                text="ADD"
                iconProps={{ iconName: "Add" }}
                onClick={() => this._addERButtonClicked()}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  private getSelectedOption(selectedKey: string, options: IDropdownOption[]) {
    var result = "";
    options.forEach((element) => {
      if (element.text.toLowerCase() == selectedKey.toLowerCase())
        result = element.text;
    });
    return result;
  }

  private getERRowData() {
    //var data=this.props.DatasetColumnDetails;
    return this.state.EntityRelationshipRowData;
  }

  private isSaveButtonDisabled(): boolean {
    if (this.props.ActionType !== "edit") 
      if (this.props.saveResultMsg != undefined) 
        if (this.props.saveResultMsg.ErrorMessage != "")
          return true;
        else 
          return false;
      else 
        return false;
    else
      return false;
  }

  private _onDropDownEntity1Change(id: number, item?: IDropdownOption) {
    var entityName1 = item != undefined ? item.text : "";
    var entityID1 = item != undefined ? Number(item.id) : 0;

    var newArray: IERRowData[] = getDeepCopy(
      this.state.EntityRelationshipRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    if (index != -1) {
      newArray[index].EntityID_1 = entityID1;
      newArray[index].EntityName_1 = entityName1;
      newArray[index].AttributeName_1 = "";
    }
    this.setState({
      EntityRelationshipRowData: newArray,
    });
  }

  private _onDropDownEntity2Change(id: number, item?: IDropdownOption) {
    var entityName2 = item != undefined ? item.text : "";
    var entityID2 = item != undefined ? Number(item.id) : 0;

    var newArray: IERRowData[] = getDeepCopy(
      this.state.EntityRelationshipRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    if (index != -1) {
      newArray[index].EntityID_2 = entityID2;
      newArray[index].EntityName_2 = entityName2;
      newArray[index].AttributeName_2 = "";
    }
    this.setState({
      EntityRelationshipRowData: newArray,
    });
  }

  private _onDropDownAttribute1Change(id: number, item?: IDropdownOption) {
    var attribName1 = item != undefined ? item.text : "";
    var newArray: IERRowData[] = getDeepCopy(
      this.state.EntityRelationshipRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    if (index != -1) newArray[index].AttributeName_1 = attribName1;
    this.setState({
      EntityRelationshipRowData: newArray,
    });
  }

  private _onDropDownAttribute2Change(id: number, item?: IDropdownOption) {
    var attribName2 = item != undefined ? item.text : "";
    var newArray: IERRowData[] = getDeepCopy(
      this.state.EntityRelationshipRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    if (index != -1) newArray[index].AttributeName_2 = attribName2;
    this.setState({
      EntityRelationshipRowData: newArray,
    });
  }

  private _onDimRoleNameChange(id: number, newValue?: string) {
    var dimRoleName = newValue != undefined ? newValue : "";
    var newArray: IERRowData[] = getDeepCopy(
      this.state.EntityRelationshipRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    if (index != -1) newArray[index].DimRoleName = dimRoleName;
    this.setState({
      EntityRelationshipRowData: newArray,
    });
  }

  private _onDropDownJoinTypeChange(id: number, item?: IDropdownOption) {
    var joinType = item != undefined ? item.text : "";
    var newArray: IERRowData[] = getDeepCopy(
      this.state.EntityRelationshipRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    if (index != -1) newArray[index].JoinType = joinType;
    this.setState({
      EntityRelationshipRowData: newArray,
    });
  }

  public _addERButtonClicked = () => {
    var EntityRelationshipRowData: IERRowData[] = getDeepCopy(
      this.state.EntityRelationshipRowData
    );
    EntityRelationshipRowData.push({
      id: this.ERRowsCount,
      EntityRelationshipID: 0,
      EntityID_1:
        this.props.EntityID != undefined ? Number(this.props.EntityID) : 0,
      EntityName_1:
        this.props.EntityName != undefined ? this.props.EntityName : "",
      AttributeName_1: "",
      EntityID_2: 0,
      EntityName_2: "",
      AttributeName_2: "",
      DimRoleName: "",
      JoinType: "",
    });
    this.ERRowsCount++;
    this.setState({ EntityRelationshipRowData });
  };

  private _deleteERButtonClicked = (item: IERRowData) => {
    var EntityRelationshipRowData: IERRowData[] = getDeepCopy(
      this.state.EntityRelationshipRowData
    );
    var index = EntityRelationshipRowData.findIndex((e) => e.id == item.id);
    if (index != -1) EntityRelationshipRowData.splice(index, 1);

    this.setState({
      EntityRelationshipRowData,
    });
  };

  private getMsgDetails = (msg: IEntityRelationshipMsgInfo) => {
    return (
      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
        {msg.loading ? (
          <div>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : (
          ""
        )}
        {msg != undefined && msg.Result != undefined ? (
          msg.Result === 1 && msg.StatusCode === 200 ? (
            <span style={{ color: "green" }}> {msg.ErrorMessage} </span>
          ) : msg.Result === 0 ? (
            <span style={{ color: "red" }}> {msg.ErrorMessage} </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<INewEntityRelationshipState>) {
    
    if (this.props.ActionType == "edit" && prevProps.allEntityRelationships != this.props.allEntityRelationships) {
      //row data created using new props
      var newRowData =  this.setEntityRelationshipRowData();

      this.state.EntityRelationshipRowData.forEach(er => 
      {
        if (er.EntityRelationshipID == 0) {
          var newER = newRowData.find(r => 
            r.EntityID_1 == er.EntityID_1 && 
            r.EntityID_2 == er.EntityID_2 && 
            r.EntityName_1 == er.EntityName_1 &&
            r.EntityName_2 == er.EntityName_2 &&
            (r.AttributeName_1 == er.AttributeName_1 || r.Attribute1_Qualifier?.includes(er.AttributeName_1)) &&
            (r.AttributeName_2 == er.AttributeName_2 || r.Attribute2_Qualifier?.includes(er.AttributeName_2)) &&
            (r.DimRoleName == undefined || r.DimRoleName == er.DimRoleName)
          );
          //adding failed ERs to the row data
          if (newER == undefined) {
            newRowData.push(er);
          }
        }     
      });

      this.setState({
        EntityRelationshipRowData: newRowData
      });
    }
  }

  componentWillUnmount() {
    this.props.clearDatasetColumnsProps();
    this.props.clearSaveResultMsgProps(false);
    this.props.clearAllEntitiesProps();
  }

  private onRenderRow(props?: IDetailsRowProps) {
    if (props != undefined)
      return <DetailsRow rowFieldsAs={this.renderRowFields} {...props} />;
    else return null;
  }

  private renderRowFields(props: IDetailsRowFieldsProps) {
    return (
      <span data-selection-disabled={true}>
        <DetailsRowFields {...props} />
      </span>
    );
  }
}

const mapStateToProps = (
  state: AppState
): INewEntityRelationshipLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    allEntityRelationships: state.entityRelationship.allEntityRelationships,
    EntityIDNameOptions: state.entityRelationship.allEntitiesList,
    saveResultMsg: state.entityRelationship.saveResultMsg,
    DatasetColumnDetails: state.entityRelationship.datasetColumnNames,
    DatasetColumnDetailsWithAlias:
      state.entityRelationship.datasetColumnNamesWithAlias,
    TransactionDatasetDetails: state.entityRelationship.transactionDatasetDetails,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): INewERLinkDispatchProps => ({
  startGetAllEntities: bindActionCreators(startGetAllEntities, dispatch),
  startSaveEntityRelationshipToDB: bindActionCreators(startSaveEntityRelationshipToDB,dispatch),
  startUpdateERToDB: bindActionCreators(startUpdateERToDB, dispatch),
  clearSaveResultMsgProps: bindActionCreators(
    clearSaveResultMsgProps,
    dispatch
  ),
  clearDatasetColumnsProps: bindActionCreators(
    clearDatasetColumnsProps,
    dispatch
  ),
  clearAllEntitiesProps: bindActionCreators(clearAllEntitiesProps, dispatch),
  startGetDatasetColumns: bindActionCreators(startGetDatasetColumns, dispatch),
  startGetDatasetColumnsWithAlias: bindActionCreators(
    startGetDatasetColumnsWithAlias,
    dispatch
  ),
  startGetTransactionDatasetDetails: bindActionCreators(startGetTransactionDatasetDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewER);
