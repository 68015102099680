import React from "react";
import { Accordion } from "../../../../Accordion";
import {
  IDropdownOption,
  Label,
  TextField,
  PrimaryButton,
  FontIcon,
  Checkbox,
  Spinner,
  SpinnerSize,
  DefaultButton,
  DetailsList,
  IColumn,
  Selection,
  SelectionMode,
  IDetailsRowProps,
  IRenderFunction,
  DetailsRow,
  DetailsRowFields,
  IDetailsRowFieldsProps,
  Text,
  ComboBox,
  Overlay,
  Modal,
} from "office-ui-fabric-react";
import { IDropdownPropsCustom } from "../../../common/dropdown/models/IDropdown";
import Dropdown from "../../../common/dropdown/dropdown.controller";
import "./new-perspective.scss";
import { connect } from "react-redux";
import {
  clearAllDomainAttributesProps,
  clearMeasuresProps,
  clearSaveResultMsgProps,
  startGetAllDomainAttributes,
  startGetFlexGrains,
  startGetMeasures,
  startGetFlexGrainDomainAttributes,
  startSavePerspectiveToDB,
  startUpdatePerspectiveToDB,
  startSaveFlexGrainToDB,
  startUpdateFlexGrainToDB,
  clearSaveFlexGrainResultMsgProps,
  startGetMeasuresAndFlexGrains,
} from "./new-perspective.service";
import {
  INewPerspectiveState,
  INewPerspectiveProps,
  INewPerspectiveLinkStateProps,
  INewPerspectiveLinkDispatchProps,
  IPerspectiveMsgInfo,
  IDomainsRowData,
  IDomainGrainRowData,
  IGroupsRowData,
  IPerspectiveMeasure,
  IMeasureAttribute,
  IFlexGrain,
  QuotaFactAttributeDetails,
} from "./model/INewPerspective";
import {
  startGetAllMeasuresTypes,
  startGetAllPerpectiveDetails,
  startGetAllUnitTypes,
  startGetAllUnits,
  startGetDatasetColumns,
  startGetAllTransactionalEntities,
} from "../../measure-definition/new-measure/new-measure.service";
import { AppState } from "../../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../../redux/types/app-actions";
import { bindActionCreators } from "redux";
import { getDeepCopy } from "../../../../util/javascript-functions";
import {
  IMeasure,
  IPerspectiveDetail,
} from "../../measure-definition/model/IMeasure";
import { IMeasureInput } from "../../measure-definition/new-measure/model/INewMeasure";
import { ModalView } from "../../../common/modal/modal.view";
import NewMeasure from "../../measure-definition/new-measure/new-measure.controller";
import * as TelemetryProvider from "../../../../TelemetryProvider";
import {
  newPerspectiveDefinitionClearButtonClickEvent,
  newPerspectiveDefinitionSaveButtonClickEvent,
} from "../perspective-definition.telemtetry-constants";
import { IDomains } from "../../domain-definition/model/IDomainDefinition";
import { IDomainAttribute } from "../../domain-definition/new-domain/model/INewDomain";

import {
  contentStyles,
  iconButtonStyles,
} from "../../../common/modal/modal.style";
import IconButton from "../../../common/button/icon-button/icon-button.controller";
import IconButtonView from "../../../common/button/icon-button/icon-button.view";
import { List } from "office-ui-fabric-react";
type Props = INewPerspectiveProps &
  INewPerspectiveLinkStateProps &
  INewPerspectiveLinkDispatchProps;

var addEditMeasureattributes = () => {
  return (
    <PrimaryButton
      className="add-level-button"
      text="Map Transaction Source"
      onClick={() => { }}
    />
  );
};

var addMeasureAttributesModal = {
  title: "Add/Edit Transaction Mapping",
  control: addEditMeasureattributes(),
};

class NewPerspective extends React.Component<Props, INewPerspectiveState> {
  private _DomainAttributesColumns: IColumn[];
  private _GroupsColumns: IColumn[];
  private _MeasuresColumns: IColumn[];
  private _FlexGrainMeasureColumns: IColumn[];
  private _DomainGrainColumns: IColumn[];
  private _QuotaOnlyDomainGrainColumns: IColumn[];
  private _DomainGrainSelection: Selection;
  private _FlexGrainColumns: IColumn[];
  private DomainsCount: number = 0;
  private MeasuresCount: number = 0;
  private Ref = React.createRef<HTMLDivElement>();
  private Flag: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      ActionType: this.props.ActionType,
      FiscalYear: this.props.fiscalYear,
      PerspectiveID: this.props.PerspectiveID,
      PerspectiveName: this.props.PerspectiveName,
      FactTableName: this.props.FactTableName,
      FlexGrains: [],
      SelectedFlexGrainID: undefined,
      FlexGrainName: '',
      FlexGrainMeasures: [],
      FlexGrainDomains: [],
      DomainsRowData: this.setDomainsRowData(),
      GroupsRowData: this.setGroupsRowData(),
      NewGroupsCount: 1,
      IsDistributedQuotaApplicable: this.props.IsDistributedQuotaApplicable,
      MeasuresRowData: [],
      measureList: [],
      MeasureAttribute: []
    };
    this.onRenderRow = this.onRenderRow.bind(this);
    this.renderRowFields = this.renderRowFields.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);

    this._DomainAttributesColumns = [
      {
        key: "DomainName",
        name: "Domain Name",
        //fieldName: "DomainName",
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
        onRender: (item: IDomainsRowData) => {
          return (
            <Dropdown
              options={this.props.allDomainAttributes?.map((d) => {
                return {
                  key: d.DomainName,
                  text: d.DomainName,
                  id: d.DomainID,
                };
              })}
              ariaLabel={"DomainName"}
              placeholder={"Select a domain"}
              dropdownWidth={250}
              selectedKey={item.DomainName}
              onChange={(event, option) =>
                this._onDropDownDomainNameChange(item.id, option)
              }
            //disabled={this.props.ActionType === "edit" ? true : false}
            />
          );
        },
      },
      {
        key: "HierarchyLevelName",
        name: "Hierarchy Level Name",
        //fieldName: "HierarchyLevelName",
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
        onRender: (item: IDomainsRowData) => {
          return (
            <Dropdown
              options={this.getHierarchyLevelNameOptions(item)}
              ariaLabel={"HierarchyLevelName"}
              placeholder={"Select a hierarchy level"}
              dropdownWidth={200}
              selectedKey={item.HierarchyLevelName}
              onChange={(event, option) =>
                this._onDropDownHierarchyLevelNameChange(item.id, option)
              }

            //disabled={this.props.ActionType === "edit" ? true : false}
            />
          );
        },
      },
      {
        key: "DomainDefaultAttribute",
        name: "Hierarchy Level Attribute",
        //fieldName: "DomainDefaultAttribute",
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
        onRender: (item: IDomainsRowData) => {
          return (
            <Dropdown
              options={this.getDomainAttributeOptions(item)}
              ariaLabel={"DomainDefaultAttribute"}
              placeholder={"Select a domain attribute"}
              selectedKey={item.DomainDefaultAttribute}
              dropdownWidth={200}
              onChange={(event, option) =>
                this._onDropDownDomainAttributeChange(item.id, option)
              }
            //disabled={this.props.ActionType === "edit" ? true : false}
            />

            /*
                       <TextField
                            value={item.DomainDefaultAttribute}
                            onChange={(event, newValue) => this._onDomainDefaultAttributeChange(item.id, newValue)}
                            //disabled={this.props.ActionType === "edit" ? true : false}
                        />
                        */
          );
        },
      },
      {
        key: "DomainDefaultValue",
        name: "Perspective Default Hierarchy Value",
        //fieldName: "DomainDefaultValue",
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <TextField
              value={item.DomainDefaultValue}
              onChange={(event, newValue) =>
                this._onDomainDefaultValueChange(item.id, newValue)
              }
              ariaLabel="DomainDefaultValue"
              width={200}
              disabled={true}
            />
          );
        },
      },
      {
        key: "DimRoleName",
        name: "Domain Filtering Rule",
        //fieldName: "DimRoleName",
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <TextField
              value={item.DimRoleName}
              onChange={(event, newValue) =>
                this._onDimRoleNameChange(item.id, newValue)
              }
              ariaLabel="DimRoleName"
              width={200}
            />
          );
        },
      },
      {
        key: "MandatoryGroupID",
        name: "Adjustment Group ID",
        fieldName: "MandatoryGroupID",
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
      },
      {
        key: "MandatoryGroupName",
        name: "Adjustment Group Name",
        fieldName: "MandatoryGroupName",
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
      },
      {
        key: "Delete",
        name: "",
        minWidth: 30,
        maxWidth: 150,
        isResizable: true,
        onRender: (item: IDomainsRowData) => {
          return (
            <IconButton
              styles={iconButtonStyles}
              iconName="Delete"
              title="Delete"
              fontSize={12}
              ariaLabel="Delete"
              onClick={(event) =>
                this._deleteDomainAttributeButtonClicked(item)
              }
            />
          );
        },
      },
    ];

    this._GroupsColumns = [
      {
        key: "GroupID",
        name: "Adjustment Group ID",
        fieldName: "GroupID",
        minWidth: 50,
        maxWidth: 70,
        isResizable: true,
      },
      {
        key: "GroupName",
        name: "Adjustment Group Name",
        fieldName: "GroupName",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: (item: IGroupsRowData) => {
          return (
            <TextField
              value={item.GroupName}
              onChange={(event, newValue) =>
                this._onMandatoryGroupNameChange(item.GroupID, newValue)
              }
              ariaLabel="GroupName"
            />
          );
        },
      },
      {
        key: "MandatoryAdjustmentGroup",
        name: "Adjustment Group Domains",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: (item: IGroupsRowData) => {
          return (
            <Dropdown
              options={this.getMandatoryAdjustmentGroupOptions(item.GroupID)}
              ariaLabel={"MandatoryAdjustmentGroup"}
              placeholder={"Select domain attributes"}
              multiSelect={true}
              selectedKeys={item.SelectedDomainAttributes.map((d) => {
                return d.id.toString();
              })}
              onChange={(event, option) =>
                this._onDropMandatoryAdjustmentGroupChange(item, option)
              }
            />
          );
        },
      },
    ];

    this._MeasuresColumns = [
      {
        key: "PerspectiveMeasure",
        name: "Perspective Measure",
        fieldName: "PerspectiveMeasure",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <TextField
              onChange={(event, newValue) =>
                this._onMeasureNameChange(item.id, newValue)
              }
              value={item.PerspectiveMeasure}
              title={item.PerspectiveMeasure}
              ariaLabel={"PerspectiveMeasure"}
            />
          );
        },
      },
      {
        key: "MeasureTypeName",
        name: "Measure Type Name",
        fieldName: "MeasureTypeName",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <Dropdown
              options={this.props.MeasureTypes}
              ariaLabel={"MeasureTypeName"}
              placeholder={"Select a value"}
              selectedKey={item.MeasureTypeName}
              onChange={(event, option) =>
                this._onDropDownMeasureTypeNameChange(item.id, option)
              }
            />
          );
        },
      },
      {
        key: "UnitName",
        name: "Unit Name",
        fieldName: "UnitName",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <Dropdown
              options={this.props.Units}
              ariaLabel={"UnitName"}
              placeholder={"Select a value"}
              selectedKey={item.UnitName}
              onChange={(event, option) =>
                this._onDropDownUnitChange(item.id, option)
              }
            />
          );
        },
      },
      {
        key: "UnitTypeName",
        name: "Unit Type Name",
        fieldName: "UnitTypeName",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <Dropdown
              options={this.props.UnitTypes}
              ariaLabel={"UnitTypeName"}
              placeholder={"Select a value"}
              selectedKey={item.UnitTypeName}
              onChange={(event, option) =>
                this._onDropDownUnitTypeNameChange(item.id, option)
              }
            />
          );
        },
      },
      
      {
        key: "Delete",
        name:"Delete",
        minWidth: 60,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <IconButton
              styles={iconButtonStyles}
              iconName="Delete"
              title="Delete"
              fontSize={12}
              ariaLabel="Delete"
              onClick={(event) =>
                this.deleteButtonClicked(item)
              }
            />
          );
        },
      },
      {
        key: "MeasureAttributes",
        name: "Measure Attributes",
        fieldName: "MeasureAttributes",
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          var measureAttributes = this.state.MeasuresRowData.find(
            (m) => m.id == item.id
          )?.MeasureAttributes;
          var params = {
            MeasureRowID: item.id,
            MeasureName: item.PerspectiveMeasure,
            MeasureTypeName: item.MeasureTypeName,
            UnitName: item.UnitName,
            UnitTypeName: item.UnitTypeName,
            MeasureAttributeCount:
              measureAttributes != undefined ? measureAttributes.length : 0,
            MeasureAttributes:
              measureAttributes != undefined ? measureAttributes : [],
            _onMeasureAttributesChange: this._onMeasureAttributesChange,
          };
          return (
            <ModalView {...addMeasureAttributesModal}>
              <NewMeasure {...params} />
            </ModalView>
          );
        },
      },
    ];


    this._FlexGrainMeasureColumns = [
      {
        key: "PerspectiveMeasure",
        name: "Perspective Measure",
        fieldName: "PerspectiveMeasure",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <TextField
              onChange={(event, newValue) =>
                this._onMeasureNameChange(item.id, newValue)
              }
              value={item.PerspectiveMeasure}
              title={"PerspectiveMeasure"}
              ariaLabel={"PerspectiveMeasure"}
            />
          );
        },
      },
      {
        key: "MeasureTypeName",
        name: "Measure Type Name",
        fieldName: "MeasureTypeName",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <Dropdown
              options={this.props.MeasureTypes}
              ariaLabel={"MeasureTypeName"}
              placeholder={"Select a value"}
              selectedKey={item.MeasureTypeName}
              onChange={(event, option) =>
                this._onDropDownMeasureTypeNameChange(item.id, option)
              }
            />
          );
        },
      },
      {
        key: "UnitName",
        name: "Unit Name",
        fieldName: "UnitName",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <Dropdown
              options={this.props.Units}
              ariaLabel={"UnitName"}
              placeholder={"Select a value"}
              selectedKey={item.UnitName}
              onChange={(event, option) =>
                this._onDropDownUnitChange(item.id, option)
              }
            />
          );
        },
      },
      {
        key: "UnitTypeName",
        name: "Unit Type Name",
        fieldName: "UnitTypeName",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IPerspectiveMeasure) => {
          return (
            <Dropdown
              options={this.props.UnitTypes}
              ariaLabel={"UnitTypeName"}
              placeholder={"Select a value"}
              selectedKey={item.UnitTypeName}
              onChange={(event, option) =>
                this._onDropDownUnitTypeNameChange(item.id, option)
              }
            />
          );
        },
      },

    ];

    

    this._DomainGrainColumns = [
      {
        key: "Domain",
        name: "Domain",
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <Dropdown
              options={this.getFlexGrainDomainOptions()}
              ariaLabel={"Domain"}
              placeholder={"Select a Domain"}
              dropdownWidth={200}
              selectedKey={item.DomainName}
              onChange={(event, option) =>
                this._onFlexGrainDomainDropdownChange(item.id, option)
              }
              disabled={item.IsSelected == true ? true : false}
            />
          );
        },
      },
      {
        key: "DomainAttribute",
        name: "DomainAttribute",
        minWidth: 180,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <Dropdown
              options={this.getFlexGrainDomainAttributeOptions(item)}
              ariaLabel={"DomainAttribute"}
              placeholder={"Select a DomainAttribute"}
              dropdownWidth={200}
              selectedKey={item.QuotaFactAttributeName}
              onChange={(event, option) =>
                this._onFlexGrainDomainAttributeDropdownChange(item.id, option)
              }
              disabled={item.IsSelected == true ? true : false}
            />
          );
        },
      },
      {
        key: "AttributeDisplayName",
        name: "AttributeDisplayName",
        minWidth: 180,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <TextField
              value={item.AttributeDisplayName}
              ariaLabel={"AttributeDisplayName"}
              onChange={(event, newValue) => this._onFlexGrainAttributeDisplayChange(item.id, newValue)}
            />
          );
        },
      },
      {
        key: "IsDefault",
        name: "IsDefault",
        fieldName: "IsDefault",
        minWidth: 60,
        maxWidth: 100,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <Checkbox
              ariaLabel={"IsDefault"}
              checked={item.QuotaFactAttributeValue != undefined ? true : false}
              onChange={(event, checked) => this._onFlexGrainIsDefaultCheckboxChange(item.id, checked)}
              boxSide={"end"}
            />
          );
        }
      },
      {
        key: "DefaultValue",
        name: "DefaultValue",
        fieldName: "DefaultValue",
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <TextField
              value={item.QuotaFactAttributeValue}
              title={"DomainAttribute"}
              ariaLabel={"DomainAttribute"}
              onChange={(event, newValue) => this._onFlexGrainDefaultValueChange(item.id, newValue)}
              disabled={item.QuotaFactAttributeValue != undefined ? false : true}
            />
          );
        }
      },
      {
        key: "ColumnOrder",
        name: "ColumnOrder",
        fieldName: "ColumnOrder",
        minWidth: 60,
        maxWidth: 100,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <TextField
              value={item.ColumnOrder != undefined ? item.ColumnOrder.toString() : undefined}
              title={item.ColumnOrder?.toString()}
              ariaLabel={"ColumnOrder"}
              onChange={(event, newValue) => this._onFlexGrainColumnOrderChange(item.id, newValue)}
            />
          );
        }
      },
      {
        key: "Delete",
        name: "",
        minWidth: 30,
        maxWidth: 150,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          if (item.IsSelected != true)
            return (
              <IconButton
                styles={iconButtonStyles}
                iconName="Delete"
                title="Delete"
                fontSize={12}
                ariaLabel="Delete"
                onClick={(event) =>
                  this._deleteFlexGrainDomainButtonClicked(item)
                }
              />
            );
        }
      }
    ];

    this._QuotaOnlyDomainGrainColumns = [
      /*{
        key: "Domain",
        name: "Domain",
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <TextField
              value={item.DomainName}
              ariaLabel={"Domain"}
              onChange={(event, newValue) => this._onFlexGrainQuotaOnlyDomainChange(item.id, newValue)}
            />
          );
        },
      },*/
      {
        key: "DomainAttribute",
        name: "DomainAttribute",
        minWidth: 250,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <TextField
              value={item.QuotaFactAttributeName}
              ariaLabel={"DomainAttribute"}
              onChange={(event, newValue) => this._onFlexGrainQuotaOnlyDomainAttributeChange(item.id, newValue)}
            />
          );
        },
      },
      {
        key: "AttributeDisplayName",
        name: "AttributeDisplayName",
        minWidth: 250,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <TextField
              value={item.AttributeDisplayName}
              ariaLabel={"AttributeDisplayName"}
              onChange={(event, newValue) => this._onFlexGrainQuotaOnlyAttributeDisplayChange(item.id, newValue)}
            />
          );
        },
      },
      {
        key: "IsDefault",
        name: "IsDefault",
        fieldName: "IsDefault",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <Checkbox
              ariaLabel={"IsDefault"}
              checked={item.QuotaFactAttributeValue != undefined ? true : false}
              onChange={(event, checked) => this._onFlexGrainQuotaOnlyIsDefaultCheckboxChange(item.id, checked)}
              boxSide={"end"}
            />
          );
        }
      },
      {
        key: "DefaultValue",
        name: "DefaultValue",
        fieldName: "DefaultValue",
        minWidth: 250,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <TextField
              value={item.QuotaFactAttributeValue}
              title={"DomainAttribute"}
              ariaLabel={"DomainAttribute"}
              onChange={(event, newValue) => this._onFlexGrainQuotaOnlyDefaultValueChange(item.id, newValue)}
              disabled={item.QuotaFactAttributeValue != undefined ? false : true}
            />
          );
        }
      },
      {
        key: "ColumnOrder",
        name: "ColumnOrder",
        fieldName: "ColumnOrder",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: (item: QuotaFactAttributeDetails) => {
          return (
            <TextField
              value={item.ColumnOrder != undefined ? item.ColumnOrder.toString() : undefined}
              title={item.ColumnOrder?.toString()}
              ariaLabel={"ColumnOrder"}
              onChange={(event, newValue) => this._onFlexGrainQuotaOnlyColumnOrderChange(item.id, newValue)}
            />
          );
        }
      },

    ];

    this._DomainGrainSelection = new Selection({
      onSelectionChanged: () => { console.log(this._DomainGrainSelection.getSelection()[0] as QuotaFactAttributeDetails); }
    });

    this._FlexGrainColumns = [
      {
        key: "FlexGrain",
        name: "FlexGrain",
        fieldName: "FlexGrain",
        minWidth: 300,
        maxWidth: 400,
        isResizable: true,
        onRender: (item: IFlexGrain) => {
          return (
            <TextField
              value={item.EntityName}
              title={"Flex Grain Name"}
              ariaLabel={"Flex Grain Name"}
              disabled={true}
            />
          );
        },
      },
      {
        key: "Edit",
        name: "Edit",
        minWidth: 30,
        maxWidth: 150,
        isResizable: true,
        onRender: (item: IFlexGrain) => {
          return (
            <span>
              <IconButtonView
                iconName="SingleColumnEdit"
                fontSize={12}
                title="Edit"
                onClick={(event) => {
                  this._editFlexGrainButtonClicked(item)
                }}
                ariaLabel="Flex Grain Edit"
              />
            </span>

          );
        },
      }
    ]
    if (
      this.props.ActionType === "edit" &&
      this.props.PerspectiveID != undefined
    ) {
      this.props.startGetMeasuresAndFlexGrains(this.props.PerspectiveID);
    }

    //this.props.startGetFlexGrains(this.props.fiscalYear);
    this.props.startGetAllDomainAttributes(this.props.fiscalYear);
    this.props.startGetFlexGrainDomainAttributes(this.props.fiscalYear);
    this.props.startGetAllMeasuresTypes();
    this.props.startGetAllPerpectiveDetails(this.props.fiscalYear);
    this.props.startGetAllUnits();
    this.props.startGetAllUnitTypes();
    //this.props.startGetAllEntities(this.props.fiscalYear);
    //this.props.startGetAllTransactionalEntities(this.props.fiscalYear);
    //this.props.startGetDatasetColumns(this.props.fiscalYear);
  }

  private _deleteMeasureButtonClicked = (item: IPerspectiveMeasure) => {
    var MeasuresRowData: IPerspectiveMeasure[] = getDeepCopy(
      this.state.MeasuresRowData
    );
    var index = MeasuresRowData.findIndex((e) => e.id == item.id);
    if (index != -1) MeasuresRowData.splice(index, 1);

    this.setState({
      MeasuresRowData,
    });
  };

  

private deleteButtonClicked = (params: any):void => 
{
  var tempVal = window.confirm("Do you want to delete this Measure ?");
    if (tempVal == true) {
var param:IPerspectiveMeasure=params;
var id=param.id;

var MeasuresRowData: IPerspectiveMeasure[] = getDeepCopy(
  this.state.MeasuresRowData
);
var index = MeasuresRowData.findIndex((e) => e.id == id);
if (index != -1) MeasuresRowData.splice(index, 1);

this.setState({
  MeasuresRowData,
});
    }
};



  private _onMeasureAttributesChange = (
    attributeID: number,
    oldEntityName: string,
    oldAttributeName: string,
    measureRowID: number,
    measureAttribute: IMeasureAttribute[]
  ): void => {
    var newArray: IPerspectiveMeasure[] = getDeepCopy(
      this.state.MeasuresRowData
    );
    var id = newArray.findIndex((b) => b.id == measureRowID);

    var index =
      newArray[id].MeasureAttributes != undefined
        ? newArray[id].MeasureAttributes.findIndex(
          (a) =>
            a.EntityName == oldEntityName &&
            a.MeasureAttributeName == oldAttributeName
        )
        : -1;

        if(measureAttribute.length < newArray[measureRowID].MeasureAttributes.length)
        {
          var a=0;
          newArray[measureRowID].MeasureAttributes.splice(attributeID,1);
        }

        if (index != -1 && newArray[id].MeasureAttributes[index]!=undefined) {
          var attribute = newArray[id].MeasureAttributes[index];
          attribute.MeasureName = measureAttribute[attributeID].MeasureName;
          attribute.EntityID = measureAttribute[attributeID].EntityID;
          attribute.EntityName = measureAttribute[attributeID].EntityName;
          attribute.MeasureAttributeName =
            measureAttribute[attributeID].MeasureAttributeName;
        } else if(measureAttribute[attributeID]!=undefined) {
          var attribute1: IMeasureAttribute = {
            id: measureAttribute[attributeID].id,
            MeasureName: measureAttribute[attributeID].MeasureName,
            EntityID: measureAttribute[attributeID].EntityID,
            EntityName: measureAttribute[attributeID].EntityName,
            MeasureAttributeName:
              measureAttribute[attributeID].MeasureAttributeName,
          };
          newArray[id].MeasureAttributes.push(attribute1);
        }
    this.setState({
      MeasuresRowData: newArray,
    });
  };

  private setDomainsRowData(): IDomainsRowData[] {
    var DomainsRowData: IDomainsRowData[] = [];
    if (this.props.DomainAttributes != undefined) {
      this.props.DomainAttributes.forEach((d) => {
        DomainsRowData.push({
          id: this.DomainsCount,
          DomainID: d.DomainID != undefined ? d.DomainID : 0,
          DomainName: d.DomainName != undefined ? d.DomainName : "",
          HierarchyLevelName:
            d.HierarchyLevelName != undefined ? d.HierarchyLevelName : "",
          DomainDefaultAttribute:
            d.DomainDefaultAttribute != undefined
              ? d.DomainDefaultAttribute
              : "",
          DomainDefaultValue:
            d.DomainDefaultValue != undefined ? d.DomainDefaultValue : "",
          DimRoleName: d.DimRoleName != undefined ? d.DimRoleName : "",
          MandatoryGroupID: d.MandatoryGroupID,
          MandatoryGroupName: d.MandatoryGroupName,
          IsDefault: false
        });
        this.DomainsCount++;
      });
    }
    return DomainsRowData.sort((a,b)=> a.DomainName.localeCompare(b.DomainName));
  }

  private getDomainsRowData() {
    /* var DomainsRowData: IDomainsRowData[] = [];
     DomainsRowData = this.state.DomainsRowData;
     DomainsRowData.forEach((d)=>{
       if(d.IsDefault == false)
         d.DefaultValue 
     })*/
    return this.state.DomainsRowData;
  }

  private setGroupsRowData(): IGroupsRowData[] {
    var GroupsRowData: IGroupsRowData[] = [];
    if (this.props.DomainAttributes != undefined) {
      var map = new Map<number, IGroupsRowData["SelectedDomainAttributes"]>();

      for (var i = 0; i < this.props.DomainAttributes.length; i++) {
        var attribute = this.props.DomainAttributes[i];
        if (attribute.MandatoryGroupID != undefined) {
          var value = map.get(attribute.MandatoryGroupID);
          if (value == undefined) value = [];
          value.push({
            id: i,
            DomainDefaultAttribute:
              attribute.DomainDefaultAttribute != undefined
                ? attribute.DomainDefaultAttribute
                : "",
          });
          map.set(attribute.MandatoryGroupID, value);
        }
      }

      map.forEach((value, key, map) => {
        GroupsRowData.push({
          GroupID: key,
          GroupName: this.props.DomainAttributes?.find(
            (d) => d.MandatoryGroupID == key
          )?.MandatoryGroupName,
          SelectedDomainAttributes: value != undefined ? value : [],
        });
      });
    }
    return GroupsRowData;
  }

  private getDomainsGrainRowData() {
    if (this.state.SelectedFlexGrainID != undefined) {
      return this.state.FlexGrains[this.state.SelectedFlexGrainID].QuotaFactAttributes;
    }
    return [];
  }

  private getQuotaOnlyDomainsGrainRowData() {
    if (this.state.SelectedFlexGrainID != undefined) {
      var data: any[] = [];
      data.push(this.state.FlexGrains[this.state.SelectedFlexGrainID].QuotaOnlyDomainAttributes);
      return data;
    }
    return [];
  }

  private setFlexGrainData() {
    var count = 0;
    var FlexGrains: IFlexGrain[] = [];
    /*if (this.props.allFlexGrains != undefined) {

      var allFlexGrains: IFlexGrain[] = getDeepCopy(this.props.allFlexGrains);
      allFlexGrains.forEach((af) => {
        if (this.props.PerspectiveID != undefined && this.props.PerspectiveID == af.PerspectiveID) {
                    
          af.id = count;
          FlexGrains.push(af);
          count++;
        }
      });
    }*/
    return FlexGrains;
  }

  private getFlexGrainData(): IFlexGrain[] {
    // map flex grain measure to measure by id
    /*var FlexGrains: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    FlexGrains.forEach(f => {
      f.Measures?.forEach(m => {
        var id = this.state.MeasuresRowData.find(md => md.MeasureID == m.MeasureID && md.PerspectiveMeasure == m.PerspectiveMeasure)?.id;
        if (id != undefined)
          m.id = id;
      });
    });
    this.setState({
      FlexGrains
    });*/
    return this.state.FlexGrains;
  }

  private setMeasuresRowData(): IPerspectiveMeasure[] {
    var MeasuresRowData: IPerspectiveMeasure[] = [];
    /*if (this.props.allMeasures != undefined) {

      var allMeasures: IPerspectiveMeasure[] = getDeepCopy(this.props.allMeasures);
      allMeasures.forEach((m) => {
        if (this.props.PerspectiveID != undefined && this.props.PerspectiveID == m.PerspectiveID) {
                    
          m.id = this.MeasuresCount;
          MeasuresRowData.push(m);
          this.MeasuresCount++;
        }
      });
    }*/
    return MeasuresRowData;
  }

  private getMeasuresRowData() {
    /*const data: IPerspectiveMeasure[] = [];
    if (
      this.props.measures != undefined &&
      this.props.measures.length != this.state.MeasuresRowData.length
    ) {
      if (this.props.measures.length > this.state.MeasuresRowData.length) {
        this.props.measures.forEach((element) => {
          const eachData: IPerspectiveMeasure = {
            PerspectiveMeasure: element.PerspectiveMeasure,
            MeasureID: element.MeasureID,
            MeasureTypeName: element.MeasureTypeName,
            UnitName: element.UnitName,
            UnitTypeName: element.UnitTypeName,
            MeasureAttributes: element.MeasureAttributes,
            id: this.MeasuresCount,
          };
          data.push(eachData);
          this.MeasuresCount++;
        });
        this.setState({
          MeasuresRowData: data,
        });
        //  return this.props.measures;
      }
    }*/
    return this.state.MeasuresRowData;
  }

  private getMeasureNameOptions = (): IDropdownOption[] => {
    var data: IDropdownOption[] = [];

    if (this.props.measures != undefined) {
      var allmeasures = this.state.MeasuresRowData;
      data = allmeasures.map((measure: IPerspectiveMeasure) =>
      ({
        id: measure.id.toString(),
        text: measure.PerspectiveMeasure.trim(),
        key: measure.PerspectiveMeasure.trim()
      }));
    }
    return data.sort((a, b) => a.text.localeCompare(b.text));
  }

  private getFlexGrainDomainOptions(): IDropdownOption[] {
    var options = this.state.DomainsRowData.map(d => ({
      key: d.DomainName,
      text: d.DomainName,
      id: d.DomainID.toString()
    }));
    return options.sort((a,b)=> a.text.localeCompare(b.text));
  }

  private getFlexGrainDomainAttributeOptions(
    item: QuotaFactAttributeDetails
  ): IDropdownOption[] {
    if (this.props.flexGrainDomainAttributes == undefined) return [];

    var options = this.props.flexGrainDomainAttributes.filter(da => da.DomainID == item.DomainID)
      .map(da => ({
        key: da.DomainAttributeName,
        text: da.DomainAttributeName,
        id: da.DomainAttributeID.toString(),
        data: da.AttributeDisplayName
      }));

    return options.sort((a,b)=> a.text.localeCompare(b.text));
  }


  private getHierarchyLevelNameOptions(
    item: IDomainsRowData
  ): IDropdownOption[] {
    if (this.props.allDomainAttributes == undefined) return [];

    var domain = this.props.allDomainAttributes.find(
      (d) => d.DomainName == item.DomainName
    );
    if (
      domain == undefined ||
      domain.AttributeHierarchyLevels == undefined ||
      domain.AttributeHierarchyLevels.length == 0
    )
      return [];

    var hierarchyLevelNameOptions = domain.AttributeHierarchyLevels.map((h) => {
      return {
        key: h.HierarchyLevelName,
        text: h.HierarchyLevelName,
      };
    });
    return hierarchyLevelNameOptions.sort((a,b)=> a.text.localeCompare(b.text));
  }

  private getDomainAttributeOptions(item: IDomainsRowData): IDropdownOption[] {
    if (this.props.allDomainAttributes == undefined) return [];

    var domain = this.props.allDomainAttributes.find(
      (d) => d.DomainName == item.DomainName
    );
    if (
      domain == undefined ||
      domain.AttributeHierarchyLevels == undefined ||
      domain.AttributeHierarchyLevels.length == 0
    )
      return [];

    var hierarchy = domain.AttributeHierarchyLevels.find(
      (h) => h.HierarchyLevelName == item.HierarchyLevelName
    );
    if (hierarchy == undefined) return [];

    var domainAttributeOptions = hierarchy.DomainAttributes.map((da) => {
      return {
        key: da.AttributeDisplayName,
        text: da.AttributeDisplayName,
        id: da.DomainAttributeID,
      };
    });
    return domainAttributeOptions.sort((a,b)=> a.text.localeCompare(b.text));
  }

  private getMandatoryAdjustmentGroupOptions(
    GroupID: number
  ): IDropdownOption[] {
    var domainAttributes = this.state.DomainsRowData;
    var options = domainAttributes
      .filter(
        (da) =>
          da.MandatoryGroupID == undefined || da.MandatoryGroupID == GroupID
      )
      .map((d) => {
        return {
          key: d.id.toString(),
          text: d.DomainDefaultAttribute,
          id: d.id.toString(),
        };
      });
    options = options.filter((op: any) => op !== undefined);
    return options;
  }

  private _onDropDownDomainNameChange(id: number, item?: IDropdownOption) {
    var domainName = item != undefined ? item.text : "";
    var domainID = Number(item != undefined ? item.id : 0);
    var newArray: IDomainsRowData[] = getDeepCopy(this.state.DomainsRowData);
    var index = newArray.findIndex((d) => d.id == id);

    if (index != -1)
      newArray[index] = {
        id: id,
        DomainID: domainID,
        DomainName: domainName,
        HierarchyLevelName: "",
        DomainDefaultAttribute: "",
        DomainDefaultValue: "",
        DimRoleName: newArray[index].DimRoleName,
      };
    this.setState({
      DomainsRowData: newArray,
    });
  }

  private _onDropDownHierarchyLevelNameChange(
    id: number,
    item?: IDropdownOption
  ) {
    var hierarchyName = item != undefined ? item.text : "";
    var newArray: IDomainsRowData[] = getDeepCopy(this.state.DomainsRowData);
    var index = newArray.findIndex((d) => d.id == id);

    if (index != -1) {
      newArray[index].HierarchyLevelName = hierarchyName;
      newArray[index].DomainDefaultAttribute = "";
      newArray[index].DomainDefaultValue = "";
    }
    this.setState({
      DomainsRowData: newArray,
    });
  }

  private _onDropDownDomainAttributeChange(id: number, item?: IDropdownOption) {
    var domainDefaultAttribute = item != undefined ? item.text : "";
    var newArray: IDomainsRowData[] = getDeepCopy(this.state.DomainsRowData);
    var index = newArray.findIndex((d) => d.id == id);

    if (index != -1) {
      newArray[index].DomainDefaultAttribute = domainDefaultAttribute;

      var domain = this.props.allDomainAttributes.find(
        (d) => d.DomainName == newArray[index].DomainName
      );
      if (domain !== undefined) {
        var hierarchy = domain.AttributeHierarchyLevels.find(
          (h) => h.HierarchyLevelName == newArray[index].HierarchyLevelName
        );

        if (hierarchy !== undefined) {
          var domainAttribute = hierarchy.DomainAttributes.find(
            (da) => da.AttributeDisplayName == domainDefaultAttribute
          );

          if (domainAttribute !== undefined && domainAttribute.DefaultValue !== undefined) {
            newArray[index].DomainDefaultValue = domainAttribute.DefaultValue;
          }
        }
      }

    }

    var GroupsRowData: IGroupsRowData[] = getDeepCopy(this.state.GroupsRowData);
    this.setState({
      DomainsRowData: newArray,
      GroupsRowData,
    });
  }

  private _onDomainDefaultAttributeChange(id: number, newValue?: string) {
    var newArray: IDomainsRowData[] = getDeepCopy(this.state.DomainsRowData);
    var index = newArray.findIndex((d) => d.id == id);

    if (index != -1)
      newArray[index].DomainDefaultAttribute =
        newValue != undefined ? newValue : "";

    var GroupsRowData: IGroupsRowData[] = getDeepCopy(this.state.GroupsRowData);
    this.setState({
      DomainsRowData: newArray,
      GroupsRowData,
    });
  }

  private _onDomainDefaultValueChange(id: number, newValue?: string) {
    var newArray: IDomainsRowData[] = getDeepCopy(this.state.DomainsRowData);
    var index = newArray.findIndex((d) => d.id == id);

    if (index != -1)
      newArray[index].DomainDefaultValue =
        newValue != undefined ? newValue : "";
    this.setState({
      DomainsRowData: newArray,
    });
  }

  private _onDimRoleNameChange(id: number, newValue?: string) {
    var newArray: IDomainsRowData[] = getDeepCopy(this.state.DomainsRowData);
    var index = newArray.findIndex((d) => d.id == id);

    if (index != -1)
      newArray[index].DimRoleName = newValue != undefined ? newValue : "";
    this.setState({
      DomainsRowData: newArray,
    });
  }

  private _onMandatoryGroupNameChange(groupID: number, newValue?: string) {
    var GroupsRowData: IGroupsRowData[] = getDeepCopy(this.state.GroupsRowData);
    var index = GroupsRowData.findIndex((d) => d.GroupID == groupID);
    if (index != -1)
      GroupsRowData[index].GroupName = newValue != undefined ? newValue : "";

    var DomainsRowData: IDomainsRowData[] = getDeepCopy(
      this.state.DomainsRowData
    );
    DomainsRowData.forEach((d) => {
      if (d.MandatoryGroupID == groupID)
        d.MandatoryGroupName = newValue != undefined ? newValue : "";
    });
    this.setState({
      DomainsRowData,
      GroupsRowData,
    });
  }

  private _onDropMandatoryAdjustmentGroupChange(
    group: IGroupsRowData,
    item?: IDropdownOption
  ) {
    //Saving the option in this.state.GroupsRowData
    var option = {
      id: Number(item?.id),
      DomainDefaultAttribute: item != undefined ? item.key.toString() : "",
    };
    var GroupsRowData: IGroupsRowData[] = getDeepCopy(this.state.GroupsRowData);
    var index = GroupsRowData.findIndex((g) => g.GroupID == group.GroupID);

    if (index != -1) {
      var selectedDomainAttributes =
        GroupsRowData[index].SelectedDomainAttributes;
      selectedDomainAttributes = item?.selected
        ? [...selectedDomainAttributes, option]
        : selectedDomainAttributes.filter((o: any) => o.id != option.id);
      GroupsRowData[index].SelectedDomainAttributes = selectedDomainAttributes;

      //reflecting the change in GroupID & GroupName in Domains grid
      var DomainsRowData: IDomainsRowData[] = getDeepCopy(
        this.state.DomainsRowData
      );
      var domainsIndex = DomainsRowData.findIndex((d) => d.id == option.id);
      if (domainsIndex != -1) {
        DomainsRowData[domainsIndex].MandatoryGroupID = item?.selected
          ? group.GroupID
          : undefined;
        DomainsRowData[domainsIndex].MandatoryGroupName = item?.selected
          ? group.GroupName
          : undefined;
      }

      this.setState({
        DomainsRowData,
        GroupsRowData,
      });
    }
  }

  private _onDropDownFiscalyearValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const FiscalYear = Number(item == null ? "" : item.text);
    this.props.startGetAllDomainAttributes(FiscalYear);
    this.props.startGetAllMeasuresTypes();
    this.props.startGetAllPerpectiveDetails(FiscalYear);
    this.props.startGetAllUnits();
    this.props.startGetAllUnitTypes();
    //this.props.startGetAllEntities(FiscalYear);
    this.props.startGetDatasetColumns(FiscalYear);
    this.setState({
      FiscalYear,
      DomainsRowData: [],
      GroupsRowData: [],
    });
  };

  private _onPerspectiveNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const PerspectiveName = newValue != undefined ? newValue : "";
    this.setState({
      PerspectiveName,
    });
  };

  private _onFactTableNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const FactTableName = newValue != undefined ? newValue : "";
    this.setState({
      FactTableName,
    });
  };

  private _onMeasureNameChange(id: number, newValue?: string) {
    var measureName = newValue != undefined ? newValue : "";
    var MeasuresRowData: IPerspectiveMeasure[] = getDeepCopy(
      this.state.MeasuresRowData
    );
    var index = MeasuresRowData.findIndex((e) => e.id == id);
    MeasuresRowData[index].PerspectiveMeasure = measureName;

    var FlexGrains: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    FlexGrains.forEach(f => {
      f.Measures?.forEach(m => {
        if (m.id == id)
          m.PerspectiveMeasure = measureName;
      });
    });

    this.setState({
      MeasuresRowData,
      MeasureName: measureName,
      FlexGrains,
    });
  }

  private _onDropDownMeasureTypeNameChange(id: number, item?: IDropdownOption) {
    var measureTypeName = item != undefined ? item.text : "";
    var newArray: IPerspectiveMeasure[] = getDeepCopy(
      this.state.MeasuresRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    newArray[index].MeasureTypeName = measureTypeName;

    this.setState({
      MeasuresRowData: newArray,
      MeasureTypeName: measureTypeName,
    });
  }

  private _onDropDownUnitTypeNameChange(id: number, item?: IDropdownOption) {
    var unitTypeName = item != undefined ? item.text : "";
    var newArray: IPerspectiveMeasure[] = getDeepCopy(
      this.state.MeasuresRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    newArray[index].UnitTypeName = unitTypeName;

    this.setState({
      MeasuresRowData: newArray,
      UnitTypeName: unitTypeName,
    });
  }

  private _onDropDownUnitChange(id: number, item?: IDropdownOption) {
    var unit = item != undefined ? item.text : "";
    var newArray: IPerspectiveMeasure[] = getDeepCopy(
      this.state.MeasuresRowData
    );
    var index = newArray.findIndex((e) => e.id == id);
    newArray[index].UnitName = unit;

    this.setState({
      MeasuresRowData: newArray,
      UnitName: unit,
    });
  }

  private _onIsDistributedQuotaApplicableValueChange = (
    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ): void => {
    this.setState({
      IsDistributedQuotaApplicable: checked,
    });
  };

  private _onSourceChange = (
    event?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ): void => {
    this.setState({
      // IsDistributedQuotaApplicable: checked,
    });
    if (checked == true) {

    }
  };

  private _onFlexGrainNameChange(newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;
    if (index != undefined) {
      newArray[index].EntityName = newValue != undefined ? newValue : "";
      this.setState({
        FlexGrains: newArray,
      });
    }
  }

  private _onFlexGrainMeasureDropdownChange(item?: IDropdownOption) {
    var measureName = item != undefined ? item.text : "";
    var measureRowId = item != undefined ? item.id : "";
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      if (item?.selected) {
        var measure = this.state.MeasuresRowData.find(m => m.PerspectiveMeasure == measureName && m.id.toString() == measureRowId);
        if (measure != undefined) {
          if (newArray[index].Measures == undefined)
            newArray[index].Measures = [];

          newArray[index].Measures?.push(measure);
        }
      }
      else {
        var mIndex = newArray[index].Measures?.findIndex(m => m.PerspectiveMeasure == measureName && m.id.toString() == measureRowId);
        if (mIndex != undefined && mIndex != -1) {
          newArray[index].Measures?.splice(mIndex, 1);
        }
      }

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainIsAutomatedChange(checked?: boolean) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;
    if (index != undefined) {
      newArray[index].IsAutomated = checked != undefined ? checked : false;
      this.setState({
        FlexGrains: newArray,
      });
    }
  }

  private _onFlexGrainDomainDropdownChange(id: number, item?: IDropdownOption) {
    var domainName: string = item != undefined ? item.text : "";
    var domainID = item != undefined ? Number((item.id)) : 0;
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      var dIndex = newArray[index].QuotaFactAttributes.findIndex(a => a.id == id);
      if (dIndex != -1) {
        newArray[index].QuotaFactAttributes[dIndex].DomainID = domainID;
        newArray[index].QuotaFactAttributes[dIndex].DomainName = domainName;
      }

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainDomainAttributeDropdownChange(id: number, item?: IDropdownOption) {
    var domainAttributeName: string = item != undefined ? item.text : "";
    var attributeDisplayName = item != undefined ? item.data : "";
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      var dIndex = newArray[index].QuotaFactAttributes.findIndex(a => a.id == id);
      if (dIndex != -1) {
        newArray[index].QuotaFactAttributes[dIndex].QuotaFactAttributeName = domainAttributeName;
        newArray[index].QuotaFactAttributes[dIndex].AttributeDisplayName = attributeDisplayName;
      }

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainAttributeDisplayChange(id: number, newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      var dIndex = newArray[index].QuotaFactAttributes.findIndex(a => a.id == id);
      if (dIndex != -1) {
        newArray[index].QuotaFactAttributes[dIndex].AttributeDisplayName = newValue != undefined ? newValue : '';
      }

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainIsDefaultCheckboxChange(id: number, checked?: boolean) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      var dIndex = newArray[index].QuotaFactAttributes.findIndex(a => a.id == id);
      if (dIndex != -1) {
        if (checked)
          newArray[index].QuotaFactAttributes[dIndex].QuotaFactAttributeValue = '';
        else
          newArray[index].QuotaFactAttributes[dIndex].QuotaFactAttributeValue = undefined;
      }

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainDefaultValueChange(id: number, newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      var dIndex = newArray[index].QuotaFactAttributes.findIndex(a => a.id == id);
      if (dIndex != -1) {
        newArray[index].QuotaFactAttributes[dIndex].QuotaFactAttributeValue = newValue;
      }

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainColumnOrderChange(id: number, newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;
    var order = newValue != undefined ? Number((newValue)) : undefined;

    if (index != undefined) {
      var dIndex = newArray[index].QuotaFactAttributes.findIndex(a => a.id == id);
      if (dIndex != -1) {
        newArray[index].QuotaFactAttributes[dIndex].ColumnOrder = order;
      }
      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainQuotaOnlyDomainChange(id: number, newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined && newArray[index].QuotaOnlyDomainAttributes != undefined) {
      newArray[index].QuotaOnlyDomainAttributes!.DomainName = newValue != undefined ? newValue : '';

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainQuotaOnlyDomainAttributeChange(id: number, newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined && newArray[index].QuotaOnlyDomainAttributes != undefined) {
      newArray[index].QuotaOnlyDomainAttributes!.QuotaFactAttributeName = newValue != undefined ? newValue : '';

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainQuotaOnlyAttributeDisplayChange(id: number, newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined && newArray[index].QuotaOnlyDomainAttributes != undefined) {
      newArray[index].QuotaOnlyDomainAttributes!.AttributeDisplayName = newValue != undefined ? newValue : '';

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainQuotaOnlyIsDefaultCheckboxChange(id: number, checked?: boolean) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined && newArray[index].QuotaOnlyDomainAttributes != undefined) {
      if (checked)
        newArray[index].QuotaOnlyDomainAttributes!.QuotaFactAttributeValue = '';
      else
        newArray[index].QuotaOnlyDomainAttributes!.QuotaFactAttributeValue = undefined;

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainQuotaOnlyDefaultValueChange(id: number, newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined && newArray[index].QuotaOnlyDomainAttributes != undefined) {
      newArray[index].QuotaOnlyDomainAttributes!.QuotaFactAttributeValue = newValue != undefined ? newValue : '';

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _onFlexGrainQuotaOnlyColumnOrderChange(id: number, newValue?: string) {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;
    var order = newValue != undefined ? Number((newValue)) : undefined;

    if (index != undefined && newArray[index].QuotaOnlyDomainAttributes != undefined) {
      newArray[index].QuotaOnlyDomainAttributes!.ColumnOrder = order;

      this.setState({
        FlexGrains: newArray
      });
    }
  }

  private _clearFlexGrainbuttonClicked = () => {

  }

  private _saveFlexGrainbuttonClicked = () => {
    var FlexGrains: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      var f = FlexGrains[index];
      //validation
      if (f.EntityName == '' || f.Measures == undefined || f.Measures.length == 0) {
        alert("Please enter all the mandatory fields.");
        return;
      }

      //update measures
      f.Measures?.forEach(m => {
        var measure = this.state.MeasuresRowData.find(mdata => mdata.id == m.id);
        if (measure != undefined) {
          m.FiscalYearID = this.props.fiscalYear;
          m.PerspectiveID = this.props.PerspectiveID;
          m.PerspectiveName = this.props.PerspectiveName;
          m.MeasureID = measure.MeasureID;
          m.PerspectiveMeasure = measure.PerspectiveMeasure;
          m.MeasureTypeName = measure.MeasureTypeName;
          m.UnitName = measure.UnitName;
          m.UnitTypeName = measure.UnitTypeName;
          m.MeasureAttributes = measure.MeasureAttributes;
        }
      });

      //updating domain attributes
      if (f.QuotaOnlyDomainAttributes != undefined
        && f.QuotaOnlyDomainAttributes.QuotaFactAttributeName != ""
      ) {
        f.QuotaFactAttributes.push(f.QuotaOnlyDomainAttributes);
      }
      
      for (var i = 0; i < f.QuotaFactAttributes.length; i++) {
        var a = f.QuotaFactAttributes[i];
        if (a.AttributeDisplayName == undefined || a.AttributeDisplayName == "") {
          alert("Please enter the Attribute Display Name. It is a mandatory field.");
          return;
        }
      }

      console.log('FlexGrain:', f);

      this.props.clearSaveFlexGrainResultMsgProps(true);
      if (f.EntityID == 0) {
        //Create FlexGrain
        this.props.startSaveFlexGrainToDB(f, this.props.startGetMeasuresAndFlexGrains);
      }
      else {
        //Update FlexGrain
        this.props.startUpdateFlexGrainToDB(f, this.props.startGetMeasuresAndFlexGrains);
      }
    }
  }

  private _clearbuttonClicked = () => {
    TelemetryProvider._trackEvent(
      newPerspectiveDefinitionClearButtonClickEvent
    );
    this.props.clearSaveResultMsgProps(false);

    if (this.props.ActionType == "edit") {
      this.setState({
        FactTableName: "",
        DomainsRowData: [],
        GroupsRowData: [],
        IsDistributedQuotaApplicable: false,
      });
    } else {
      this.setState({
        PerspectiveID: 0,
        PerspectiveName: "",
        FactTableName: "",
        DomainsRowData: [],
        GroupsRowData: [],
        MeasuresRowData: [],
        IsDistributedQuotaApplicable: false,
      });
    }
  };

  private _savebuttonClicked = () => {
    TelemetryProvider._trackEvent(newPerspectiveDefinitionSaveButtonClickEvent);
    //console.log(this.state);
    if (
      this.state.PerspectiveName == undefined ||
      this.state.PerspectiveName == "" ||
      this.state.FactTableName == undefined ||
      this.state.FactTableName == ""
    ) {
      alert("Please enter all the mandatory fields.");
      return;
    }

    if (this.state.DomainsRowData.length == 0) {
      alert("Please add Domains to the Perspective.");
      return;
    }

    for (var m1 = 0; m1 < this.state.MeasuresRowData.length; m1++) {
      for (var m2 = 0; m2 < this.state.MeasuresRowData.length; m2++) {
        if (
          this.state.MeasuresRowData[m1].id != this.state.MeasuresRowData[m2].id &&
          this.state.MeasuresRowData[m1].PerspectiveMeasure == this.state.MeasuresRowData[m2].PerspectiveMeasure
        ) {
          alert("Measures with same name cannot be created. Please provide unique Perspective Measure names.");
          return;
        }
      }
    }

    if (this.props.ActionType == "edit") {
      this.props.clearSaveResultMsgProps(true);
      if (
        this.state.MeasuresRowData == undefined ||
        this.state.MeasuresRowData.length == 0
      ) {
        var tempVal = window.confirm(
          "Perspective definition should have Measures. Do you want to save without defining Measures?"
        );
        if (tempVal == true) {
          this.props.startUpdatePerspectiveToDB(
            this.state,
            this.props.startGetAllPerspectives
          );
        }
      } else {
        this.props.startUpdatePerspectiveToDB(
          this.state,
          this.props.startGetAllPerspectives
        );
      }
    } else {
      this.props.clearSaveResultMsgProps(true);
      if (
        this.state.MeasuresRowData == undefined ||
        this.state.MeasuresRowData.length == 0
      ) {
        var tempVal = window.confirm(
          "Perspective definition should have Measures. Do you want to save without defining Measures?"
        );
        if (tempVal == true) {
          this.props.startSavePerspectiveToDB(
            this.state,
            this.props.startGetAllPerspectives
          );
        }
      } else {
        this.props.startSavePerspectiveToDB(
          this.state,
          this.props.startGetAllPerspectives
        );
      }
    }
  };

  private _newDomainAttributeButtonClicked = () => {
    var DomainsRowData: IDomainsRowData[] = getDeepCopy(
      this.state.DomainsRowData
    );
    DomainsRowData.push({
      id: this.DomainsCount,
      DomainID: 0,
      DomainName: "",
      HierarchyLevelName: "",
      DomainDefaultAttribute: "",
      DomainDefaultValue: "",
      DimRoleName: "",
      MandatoryGroupID: undefined,
      MandatoryGroupName: "",
    });
    this.DomainsCount++;

    this.setState({
      DomainsRowData,
    });
  };

  private _deleteDomainAttributeButtonClicked = (item: IDomainsRowData) => {
    var DomainsRowData: IDomainsRowData[] = getDeepCopy(
      this.state.DomainsRowData
    );
    var index = DomainsRowData.findIndex((d) => d.id == item.id);
    if (index != -1) DomainsRowData.splice(index, 1);

    var GroupsRowData: IGroupsRowData[] = getDeepCopy(this.state.GroupsRowData);
    if (item.MandatoryGroupID != undefined) {
      var groupsIndex = GroupsRowData.findIndex(
        (g) => g.GroupID == item.MandatoryGroupID
      );
      GroupsRowData[groupsIndex].SelectedDomainAttributes = GroupsRowData[
        groupsIndex
      ].SelectedDomainAttributes.filter((da) => da.id != item.id);
    }

    this.setState({
      DomainsRowData,
      GroupsRowData,
    });
  };

  private _newGroupButtonClicked = () => {
    var GroupsRowData: IGroupsRowData[] = getDeepCopy(this.state.GroupsRowData);
    GroupsRowData.push({
      GroupID: this.state.NewGroupsCount * -1,
      GroupName: "",
      SelectedDomainAttributes: [],
    });
    this.setState({
      GroupsRowData,
      NewGroupsCount: this.state.NewGroupsCount + 1,
    });
  };

  private _newFlexGrainDomainButtonClicked = () => {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      newArray[index].QuotaFactAttributes.push({
        id: newArray[index].RowsCount,
        DomainID: 0,
        DomainName: '',
        QuotaFactAttributeName: '',
        QuotaFactAttributeValue: undefined,
        AttributeDisplayName: '',
        ColumnOrder: 0,
        IsSelected: false,
      });
      newArray[index].RowsCount++;
    }
    this.setState({
      FlexGrains: newArray
    });
  }

  private _deleteFlexGrainDomainButtonClicked = (item: QuotaFactAttributeDetails) => {
    var newArray: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;

    if (index != undefined) {
      var qfIndex = newArray[index].QuotaFactAttributes.findIndex(qf => qf.id == item.id);
      if (qfIndex != -1) {
        newArray[index].QuotaFactAttributes.splice(qfIndex, 1);
      }
    }
    this.setState({
      FlexGrains: newArray
    });
  }

  private handleDismiss() {
    this.props.updateIsEditModalVisible(false);
  }

  public render(): JSX.Element {
    //Need to change this to API
    var FiscalyearList = [
      { key: 2020, text: "2020" },
      { key: 2021, text: "2021" },
    ];

    const dropDownFiscalYear: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Fiscal Year:",
      onChange: this._onDropDownFiscalyearValueChange,
      options: FiscalyearList,
      required: true,
      selectedKey: this.state.FiscalYear,
      disabled: this.props.ActionType === "edit" ? true : false,
    };

    return (
      <Modal
        isOpen={this.props.showModal}
        //onDismiss={this.handleDismiss}
        containerClassName={contentStyles.container}
        isBlocking={true}
      >
        <div className={contentStyles.header}>
          <span id="preview">New/Edit Perspective</span>
          <IconButton
            styles={iconButtonStyles}
            iconName="Cancel"
            title="Close Modal"
            fontSize={12}
            ariaLabel="Close popup modal"
            onClick={this.handleDismiss}
          />
        </div>
        <div id="children" className={contentStyles.body}>
          <div className="new-perspective">
            <div className="new-perspective-buttons-list">
              <PrimaryButton
                text="Clear"
                onClick={this._clearbuttonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
              <PrimaryButton
                text="Save"
                onClick={this._savebuttonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
            </div>

            {this.getMsgDetails(
              this.props.saveResultMsg != undefined
                ? this.props.saveResultMsg
                : {}
            )}

            {/*<div className="seperator-cls">
                    <Dropdown {...dropDownFiscalYear} />
                </div>*/}

            <div className="seperator-cls">
              <div className="combine-cls">
                <div className="single-cls">
                  <TextField
                    label={"Perspective Name:"}
                    required={true}
                    value={this.state.PerspectiveName}
                    disabled={this.props.ActionType === "edit" ? true : false}
                    onChange={this._onPerspectiveNameChange}
                    ariaLabel={"Perspective Name"}
                  />
                </div>

                <div className="single-cls">
                  <TextField
                    label={"Fact Table Name:"}
                    required={true}
                    value={this.state.FactTableName}
                    disabled={this.props.ActionType === "edit" ? true : false}
                    onChange={this._onFactTableNameChange}
                    ariaLabel={"Fact Table Name"}
                  />
                </div>
              </div>
            </div>

            <div className="seperator-cls">
              <Label required={true}>Domains:</Label>
            </div>
            <Accordion header="Domains">
              <DetailsList
                items={this.getDomainsRowData()}
                columns={this._DomainAttributesColumns}
                selectionMode={SelectionMode.none}
                onRenderRow={this.onRenderRow}
              />

              <div className="new-perspective-buttons-list">
                <PrimaryButton
                  className="add-row-button"
                  text={"ADD"}
                  iconProps={{ iconName: "Add" }}
                  onClick={this._newDomainAttributeButtonClicked}
                />
              </div>
            </Accordion>
            <div className="seperator-cls">
              <Label>Adjustment Group Definition:</Label>
            </div>
            <Accordion header="Adjustment Group Definition">
              <DetailsList
                items={this.state.GroupsRowData}
                columns={this._GroupsColumns}
                selectionMode={SelectionMode.none}
                onRenderRow={this.onRenderRow}
              />

              <div className="new-perspective-buttons-list">
                <PrimaryButton
                  className="add-row-button"
                  text={'ADD'}
                  iconProps={{ iconName: 'Add' }}
                  onClick={this._newGroupButtonClicked}
                />
              </div>

              <div className="seperator-cls">
                <Checkbox
                  label={"IsDistributedQuotaApplicable"}
                  checked={this.state.IsDistributedQuotaApplicable}
                  onChange={this._onIsDistributedQuotaApplicableValueChange}
                  boxSide={"end"}
                />
              </div>
            </Accordion>

            <div className="seperator-cls">
              <Label>Measures:</Label>
            </div>



            <div ref={this.Ref}>
              <DetailsList items={this.getMeasuresRowData()}
                columns={this._MeasuresColumns}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={false}
                onRenderRow={this.onRenderRow}
              />

            </div>
            <div className="new-perspective-buttons-list">
              <PrimaryButton
                className="add-row-button"
                text="ADD"
                iconProps={{ iconName: 'Add' }}
                onClick={() => this._addMeasureButtonClicked()}
              />
            </div>



            <DetailsList items={this.getFlexGrainData()}
              columns={this._FlexGrainColumns}
              selectionMode={SelectionMode.none}
              // selectionPreservedOnEmptyClick={false}
              onRenderRow={this.onRenderRow}
            />

            <div className="new-perspective-buttons-list">
              <PrimaryButton
                className="add-row-button"
                text="Add New Flex Grain"
                iconProps={{ iconName: 'Add' }}
                onClick={() => this._addFlexGrainButtonClicked()}
              />
            </div>
            <Accordion header="Flex Grain Edit/Create" >
              <TextField
                label={"Submission Grain Name:"}
                required={true}
                height={40}
                width={10}
                value={this.state.SelectedFlexGrainID != undefined ? this.state.FlexGrains[this.state.SelectedFlexGrainID].EntityName : ''}
                onChange={(event, newValue) => this._onFlexGrainNameChange(newValue)}
                disabled={this.state.SelectedFlexGrainID != undefined
                  ? this.state.FlexGrains[this.state.SelectedFlexGrainID].EntityID != 0
                    ? true : false
                  : false}
              />

              <div className="seperator-cls">
                <ComboBox
                  options={this.getMeasureNameOptions()}
                  ariaLabel={"Measure:"}
                  label={"Measures:"}
                  placeholder={"Select a value"}
                  dropdownWidth={350}
                  required={true}
                  selectedKey={this.state.SelectedFlexGrainID != undefined
                    ? this.state.FlexGrains[this.state.SelectedFlexGrainID].Measures?.map(f => { return f.PerspectiveMeasure })
                    : ''}
                  onChange={(event, option) => this._onFlexGrainMeasureDropdownChange(option)}
                  multiSelect={true}
                />
              </div>
              <div className="new-perspective-buttons-list">
                <PrimaryButton
                  className="add-row-button"
                  text="Add new Measure"
                  iconProps={{ iconName: 'Add' }}
                  //  onClick={() =>this._addMeasureButtonClicked()}
                  onClick={() => {
                    if (this.Ref && this.Ref.current) {
                      this._addFlexGrainMeasureButtonClicked();
                      this.Ref.current.scrollIntoView();
                    }
                  }}
                />
              </div>

              <div className="seperator-cls">
                <Checkbox
                  label={"IsAutomated: "}
                  checked={this.state.SelectedFlexGrainID != undefined ? this.state.FlexGrains[this.state.SelectedFlexGrainID].IsAutomated : false}
                  onChange={(event, checked) => this._onFlexGrainIsAutomatedChange(checked)}
                  boxSide={"end"}
                />
              </div>

              <div className="seperator-cls">
                <Label>Domains for Flex Grain:</Label>
              </div>

              <div>
                <DetailsList
                  items={this.getDomainsGrainRowData()}
                  columns={this._DomainGrainColumns}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey='set'
                  selection={this._DomainGrainSelection}
                  onRenderRow={this.onRenderRow}
                />
              </div>

              <div className="new-perspective-buttons-list">
                <PrimaryButton
                  className="add-row-button"
                  text={"ADD"}
                  iconProps={{ iconName: "Add" }}
                  onClick={this._newFlexGrainDomainButtonClicked}
                />
              </div>

              <div className="seperator-cls">
                <Label>Quota Only Domains for Flex Grain:</Label>
              </div>

              <div>
                <DetailsList
                  items={this.getQuotaOnlyDomainsGrainRowData()}
                  columns={this._QuotaOnlyDomainGrainColumns}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey='set'
                  selection={this._DomainGrainSelection}
                  onRenderRow={this.onRenderRow}
                />
              </div>

              <div className="new-perspective">
                <div className="center-align">

                  {this.getFlexGrainMsgDetails(
                    this.props.saveFlexGrainResultMsg != undefined
                      ? this.props.saveFlexGrainResultMsg
                      : {}
                  )}

                  <PrimaryButton
                    text="Clear"
                    onClick={this._clearFlexGrainbuttonClicked}
                    className="header-buttons"
                    style={{ backgroundColor: "#288560" }}
                  />
                  <PrimaryButton
                    text="Save"
                    onClick={this._saveFlexGrainbuttonClicked}
                    className="header-buttons"
                    style={{ backgroundColor: "#288560" }}
                  />
                </div>
              </div>

            </Accordion>
          </div>
        </div>

      </Modal>
    );
  }

  private _addFlexGrainButtonClicked = () => {
    var da = {
      id: 0,
      DomainID: 0,
      DomainName: '',
      QuotaFactAttributeName: '',
      QuotaFactAttributeValue: undefined,
      AttributeDisplayName: '',
      ColumnOrder: 0,
      IsSelected: false,
    }
    var Fdomains: QuotaFactAttributeDetails[] = []
    Fdomains.push(da);

    var newFlexGrain: IFlexGrain = {
      id: this.state.FlexGrains.length,
      EntityID: 0,
      EntityName: '',
      PerspectiveID: this.state.PerspectiveID != undefined ? this.state.PerspectiveID : 0,
      PerspectiveName: this.state.PerspectiveName != undefined ? this.state.PerspectiveName : '',
      IsAutomated: false,
      Measures: [],
      QuotaFactAttributes: Fdomains,
      QuotaOnlyDomainAttributes: da,
      FiscalYear: this.props.fiscalYear,
      RowsCount: 1,
    };
    var newArray = getDeepCopy(this.state.FlexGrains);
    newArray.push(newFlexGrain);

    this.setState({
      SelectedFlexGrainID: newFlexGrain.id,
      FlexGrains: newArray
    });
    this.props.clearSaveFlexGrainResultMsgProps(false);
  }

  private _editFlexGrainButtonClicked = (item: IFlexGrain) => {
    /*var measures : IPerspectiveMeasure[] = [];
    this.state.MeasuresRowData.forEach(m => {
      var attribute = m.MeasureAttributes.find(ma => ma.EntityID == item.EntityID);
      if (attribute != undefined) {
        measures.push(m);
      }
    });*/

    var SelectedFlexGrainID = item.id != undefined ? item.id : 0;
    var FlexGrains: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    //FlexGrains[SelectedFlexGrainID].Measures = measures;

    this.setState({
      SelectedFlexGrainID,
      FlexGrains
    });
    this.props.clearSaveFlexGrainResultMsgProps(false);
  }

  private _addFlexGrainMeasureButtonClicked = () => {
    var MeasuresRowData: IPerspectiveMeasure[] = getDeepCopy(
      this.state.MeasuresRowData
    );
    var measure: IPerspectiveMeasure = {
      id: this.MeasuresCount,
      PerspectiveMeasure: "",
      MeasureTypeName: "",
      UnitName: "",
      UnitTypeName: "",
      MeasureID: 0,
      MeasureAttributes: [],
      IsFlexGrainMeasure: true,
    };
    MeasuresRowData.push(measure);
    this.MeasuresCount++;

    var FlexGrains: IFlexGrain[] = getDeepCopy(this.state.FlexGrains);
    var index = this.state.SelectedFlexGrainID;
    if (index != undefined) {
      if (FlexGrains[index].Measures == undefined)
        FlexGrains[index].Measures = [];

      FlexGrains[index].Measures?.push(measure);
    }
    this.setState({
      MeasuresRowData,
      FlexGrains,
    });
  }

  private _addMeasureButtonClicked = () => {
    //console.log("Testing....!");
    var MeasuresRowData: IPerspectiveMeasure[] = getDeepCopy(
      this.state.MeasuresRowData
    );
    MeasuresRowData.push({
      id: this.MeasuresCount,
      PerspectiveMeasure: "",
      MeasureTypeName: "",
      UnitName: "",
      UnitTypeName: "",
      MeasureID: 0,
      MeasureAttributes: [],
      IsFlexGrainMeasure: false,
    });
    this.MeasuresCount++;
    this.setState({ MeasuresRowData });
  };

  private onRenderRow(props?: IDetailsRowProps) {
    if (props != undefined)
      return <DetailsRow rowFieldsAs={this.renderRowFields} {...props} />;
    else return null;
  }

  private renderRowFields(props: IDetailsRowFieldsProps) {
    return (
      <span data-selection-disabled={true}>
        <DetailsRowFields {...props} />
      </span>
    );
  }

  private getMsgDetails = (msg: IPerspectiveMsgInfo) => {
    return (
      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
        {msg.loading ? (
          <div>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : (
          ""
        )}
        {msg != undefined && msg.Result != undefined ? (
          msg.Result === 1 && msg.StatusCode === 200 ? (
            <span style={{ color: "green" }}> {msg.ErrorMessage} </span>
          ) : msg.Result === 0 || msg.Result === -2 ? (
            <span style={{ color: "red" }}> {msg.ErrorMessage} </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  private getFlexGrainMsgDetails = (msg: IPerspectiveMsgInfo) => {
    return (
      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
        {msg.loading ? (
          <div>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : (
          ""
        )}
        {msg != undefined && msg.Result != undefined ? (
          msg.Result === 1 && msg.StatusCode === 200 ? (
            <span style={{ color: "green" }}> {msg.ErrorMessage} </span>
          ) : msg.Result === 0 ? (
            <span style={{ color: "red" }}> {msg.ErrorMessage} </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<INewPerspectiveState>) {
    //setting Measures data
    if (prevProps.measures != this.props.measures) {

      const data: IPerspectiveMeasure[] = [];
      this.props.measures.forEach((element) => {
        const eachData: IPerspectiveMeasure = {
          PerspectiveMeasure: element.PerspectiveMeasure,
          MeasureID: element.MeasureID,
          MeasureTypeName: element.MeasureTypeName,
          UnitName: element.UnitName,
          UnitTypeName: element.UnitTypeName,
          MeasureAttributes: element.MeasureAttributes,
          id: this.MeasuresCount,
        };
        data.push(eachData);
        this.MeasuresCount++;
      });
      this.setState({
        MeasuresRowData: data,
      });

    }

    //setting All Flex Grains data
    if (this.state.MeasuresRowData.length > 0 && prevProps.allFlexGrains != this.props.allFlexGrains) {

      var count = 0;
      var FlexGrains: IFlexGrain[] = [];
      if (this.props.allFlexGrains != undefined) {
        var allFlexGrains: IFlexGrain[] = getDeepCopy(this.props.allFlexGrains);
        allFlexGrains.forEach((fg) => {

          //adding Domains & QuotaOnlyDomains
          var domainAttributes: QuotaFactAttributeDetails[] = [];
          var a_count = 0;
          fg.QuotaFactAttributes.forEach(qf => {
            if (qf.DomainID == 0) {
              fg.QuotaOnlyDomainAttributes = qf;
            }
            else {
              qf.id = a_count;
              qf.IsSelected = true;
              a_count++;

              domainAttributes.push(qf);
            }
          });

          //adding Quota only domain, in case empty
          if (fg.QuotaOnlyDomainAttributes == undefined) {
            fg.QuotaOnlyDomainAttributes = {
              id: 0,
              DomainID: 0,
              DomainName: '',
              QuotaFactAttributeName: '',
              QuotaFactAttributeValue: undefined,
              AttributeDisplayName: '',
              ColumnOrder: 0,
            }
          }

          fg.QuotaFactAttributes = domainAttributes;
          fg.FiscalYear = this.props.fiscalYear != undefined ? this.props.fiscalYear : 0;
          fg.id = count;
          fg.RowsCount = a_count;
          FlexGrains.push(fg);
          count++;
        });

        //adding Measures to flex grains
        FlexGrains.forEach(f => {
          var measures: IPerspectiveMeasure[] = [];
          this.state.MeasuresRowData.forEach(m => {
            var attribute = m.MeasureAttributes.find(ma => ma.EntityID == f.EntityID);
            if (attribute != undefined) {
              measures.push(m);
            }
          });
          f.Measures = measures;
        });

        console.log(FlexGrains);
        this.setState({ FlexGrains });
      }
    }

  }

  componentWillUnmount() {
    this.props.clearAllDomainAttributesProps();
    this.props.clearMeasuresProps();
    this.props.clearSaveResultMsgProps(false);
  }
}

const mapStateToProps = (state: AppState): INewPerspectiveLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    allDomainAttributes: state.perspectiveDefinition.allDomainAttributes,
    flexGrainDomainAttributes: state.perspectiveDefinition.flexGrainDomainAttributes,
    allFlexGrains: state.perspectiveDefinition.allFlexGrains,
    saveResultMsg: state.perspectiveDefinition.saveResultMsg,
    saveFlexGrainResultMsg: state.perspectiveDefinition.saveFlexGrainResultMsg,
    measures: state.perspectiveDefinition.measures,
    allMeasures: state.measureDefinition.allMeasures,
    PerspectiveDetails: state.measureDefinition.allPerspectiveDetails,
    MeasureTypes: state.measureDefinition.allMeasureTypes,
    UnitTypes: state.measureDefinition.allUnitTypes,
    Units: state.measureDefinition.allUnits,
    DatasetColumnDetails: state.measureDefinition.datasetColumnNames,
    EntityIDNameOptions: state.measureDefinition.allEntitiesList,
    MeasureInputs: state.measureDefinition.allMeasures,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): INewPerspectiveLinkDispatchProps => ({
  startGetAllDomainAttributes: bindActionCreators(
    startGetAllDomainAttributes,
    dispatch
  ),
  startGetFlexGrains: bindActionCreators(
    startGetFlexGrains,
    dispatch
  ),
  startGetFlexGrainDomainAttributes: bindActionCreators(
    startGetFlexGrainDomainAttributes,
    dispatch
  ),
  startGetMeasures: bindActionCreators(startGetMeasures, dispatch),
  startSavePerspectiveToDB: bindActionCreators(
    startSavePerspectiveToDB,
    dispatch
  ),
  startGetMeasuresAndFlexGrains: bindActionCreators(
    startGetMeasuresAndFlexGrains,
    dispatch
  ),
  startUpdatePerspectiveToDB: bindActionCreators(
    startUpdatePerspectiveToDB,
    dispatch
  ),
  startSaveFlexGrainToDB: bindActionCreators(
    startSaveFlexGrainToDB,
    dispatch
  ),
  startUpdateFlexGrainToDB: bindActionCreators(
    startUpdateFlexGrainToDB,
    dispatch
  ),
  clearAllDomainAttributesProps: bindActionCreators(
    clearAllDomainAttributesProps,
    dispatch
  ),
  clearMeasuresProps: bindActionCreators(clearMeasuresProps, dispatch),
  clearSaveResultMsgProps: bindActionCreators(
    clearSaveResultMsgProps,
    dispatch
  ),
  clearSaveFlexGrainResultMsgProps: bindActionCreators(
    clearSaveFlexGrainResultMsgProps,
    dispatch
  ),
  // startGetAllEntities: bindActionCreators(startGetAllEntities, dispatch),
  startGetDatasetColumns: bindActionCreators(startGetDatasetColumns, dispatch),
  startGetAllPerpectiveDetails: bindActionCreators(
    startGetAllPerpectiveDetails,
    dispatch
  ),
  startGetAllMeasuresTypes: bindActionCreators(
    startGetAllMeasuresTypes,
    dispatch
  ),
  startGetAllUnitTypes: bindActionCreators(startGetAllUnitTypes, dispatch),
  startGetAllUnits: bindActionCreators(startGetAllUnits, dispatch),
  startGetAllTransactionalEntities: bindActionCreators(
    startGetAllTransactionalEntities,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPerspective);
