import { IMeasure,IMeasureMsgInfo,IPerspectiveDetail } from "../../components/onboarding/measure-definition/model/IMeasure";

export const GET_ALL_MEASURES = "GET_ALL_MEASURES";
export const GET_ALL_UNITTYPES="GET_ALL_UNITTYPES";
export const GET_ALL_UNITS="GET_ALL_UNITS"
export const GET_ALL_MEASURETYPES="GET_ALL_MEASURETYPES"
export const GET_ALL_PERSPECTIVE_DETAILS="GET_ALL_PERSPECTIVE_DETAILS"
export const GET_ALL_ENTITIES = "GET_ALL_ENTITIES";
export const GET_DATASET_COLUMNS="GET_DATASET_COLUMNS";
export const GET_NEW_SAVE_SUCCESS="GET_NEW_ER_SAVE_SUCCESS";
export const CLEAR_DATASET_COLUMNS="CLEAR_DATASET_COLUMNS";


export interface GetAllMeasures {
    type: typeof GET_ALL_MEASURES;
    allMeasures: IMeasure[];
}

export interface GetAllUnitTypes {
    type: typeof GET_ALL_UNITTYPES;
    allUnitTypes: string[];
}

export interface GetAllUnits {
    type: typeof GET_ALL_UNITS;
    allUnits: string[];
}

export interface GetAllMeasuresTypes {
    type: typeof GET_ALL_MEASURETYPES;
    allMeasureTypes: string[];
}

export interface GetAllPerpectiveDetails {
    type: typeof GET_ALL_PERSPECTIVE_DETAILS;
    allPerspectiveDetails: string[];
}

export interface GetEntities {
    type: typeof GET_ALL_ENTITIES;
    allEntitiesList: any;
}

export interface GetDatasetColumnNames {
    type: typeof GET_DATASET_COLUMNS;
    datasetColumnNames: string[];
}

export interface GetNewERSaveSuccess {
    type: typeof GET_NEW_SAVE_SUCCESS;
    saveResultMsg: IMeasureMsgInfo;
}

export interface ClearDatasetColumns {
    type: typeof CLEAR_DATASET_COLUMNS;
}


export type MeasureDefinitionActionTypes = 
GetAllMeasures|
GetAllUnits|
GetAllUnitTypes|
GetAllMeasuresTypes|
GetAllPerpectiveDetails|
GetEntities|
GetDatasetColumnNames|
ClearDatasetColumns|
GetNewERSaveSuccess;
