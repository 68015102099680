import { IEntityOnboarding, IEntityOnboardingList } from "../../components/onboarding/entity-onboarding/model/IEntityOnboarding";

export const GET_ALL_ENTITIES = "GET_ALL_ENTITIES";
export const DELETE_ENTITY = "DELETE_ENTITY";


export interface GetAllEntities {
    type: typeof GET_ALL_ENTITIES;
    totalEntities: IEntityOnboardingList;
}

export interface DeleteEntity {
    type: typeof DELETE_ENTITY;
    data: IEntityOnboardingList;
}

export type EntityOnboardingActionTypes = GetAllEntities | DeleteEntity;
