import React from "react";
import "./App.scss";
import { Route } from "react-router-dom";
import MintSystemConfigContainer from "./components/mint-systemconfig-container/mint-systemconfig-container";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export let appInsights: any;

export const AppInterceptor: React.FunctionComponent<any> = (props) => {
  appInsights = useAppInsightsContext();

  return (
    <>
      <Route path="/" component={MintSystemConfigContainer}></Route>
      <Route path="/home" component={MintSystemConfigContainer}></Route>
    </>
  );
};

export default AppInterceptor;
