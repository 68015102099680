import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../../redux/types/app-actions";
import { AppState } from "../../../../redux/configureStore";
import * as PerspectiveDefinitionAPI from "../../../../api/perspective-definition-api";
import * as PerspectiveDefinitionActions from "../../../../redux/actions/perspective-definition-actions";
import { IDomainAttributeDetails, IFlexGrain, IFlexGrainDomainAttribute, INewPerspectiveAPI, INewPerspectiveState, IPerspectiveMeasure, IPerspectiveMsgInfo } from "./model/INewPerspective";
import { INewDomainAPI } from "../../domain-definition/new-domain/model/INewDomain";
import {IMeasureInputToDB} from '../../measure-definition/model/IMeasure'

export const startGetAllDomainAttributes = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = PerspectiveDefinitionAPI.getAllDomainAttributes(fiscalYear)
            .then((response) => {
                const data: IDomainAttributeDetails[] = response;
                dispatch(PerspectiveDefinitionActions.getAllDomainAttributes(data.sort((a,b)=> a.DomainName.localeCompare(b.DomainName))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

export const startGetFlexGrainDomainAttributes = (fiscalYear: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = PerspectiveDefinitionAPI.getFlexGrainDomainAttributes(fiscalYear)
            .then((response) => {
                const data: IFlexGrainDomainAttribute[] = response;
                dispatch(PerspectiveDefinitionActions.getFlexGrainDomainAttributes(data.sort((a,b)=> a.DomainName.localeCompare(b.DomainName))));
            })
            .catch((error) => {
                const err = error;
            })
    };
};

export const startGetFlexGrains = (perspectiveId: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = PerspectiveDefinitionAPI.getAllFlexGrains(perspectiveId)
            .then((value) => {
                const data: IFlexGrain[] = value;
                dispatch(PerspectiveDefinitionActions.getAllFlexGrains(data.sort((a,b)=> a.EntityName.localeCompare(b.EntityName))));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};


export const startGetMeasures = (perspectiveId: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = PerspectiveDefinitionAPI.getMeasures(perspectiveId)
            .then((response) => {
                const data: IPerspectiveMeasure[] = response;
                dispatch(PerspectiveDefinitionActions.getMeasures(data.sort((a,b)=> a.PerspectiveMeasure.localeCompare(b.PerspectiveMeasure))));})
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

export const startGetMeasuresAndFlexGrains = (perspectiveId: number) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        PerspectiveDefinitionAPI.getMeasures(perspectiveId)
        .then((response) => {
            const data: IPerspectiveMeasure[] = response;
            dispatch(PerspectiveDefinitionActions.getMeasures(data.sort((a,b)=> a.PerspectiveMeasure.localeCompare(b.PerspectiveMeasure))));

            PerspectiveDefinitionAPI.getAllFlexGrains(perspectiveId)
            .then((value) => {
                const data: IFlexGrain[] = value;
                dispatch(PerspectiveDefinitionActions.getAllFlexGrains(data.sort((a,b)=> a.EntityName.localeCompare(b.EntityName))));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
        })
        .catch((error) => {
            const err = error;
        })
    };
};

const setMeasuresStateToApi = (measureState: INewPerspectiveState) => {
    const mInput : IMeasureInputToDB[] = [];
    var count=0;
    measureState.MeasuresRowData.forEach((element)=>{
        const mInputInner:IMeasureInputToDB={
            MeasureName:measureState.MeasuresRowData!=undefined?measureState.MeasuresRowData[count].PerspectiveMeasure:"",
            MeasureTypeName:measureState.MeasureTypeName!=undefined?measureState.MeasuresRowData[count].MeasureTypeName:element.MeasureTypeName,
            PerspectiveName:measureState.PerspectiveName!=undefined?measureState.PerspectiveName:"",
            UnitName:measureState.UnitName!=undefined?measureState.MeasuresRowData[count].UnitName:element.UnitName,
            UnitTypeName:measureState.UnitTypeName!=undefined?measureState.MeasuresRowData[count].UnitTypeName:element.UnitTypeName,
            FiscalYearID:measureState.FiscalYear,
            MeasureID:0,
            MeasureAttributes:element.MeasureAttributes
        }
        count=count+1;
        mInput.push(mInputInner);
    });
     return mInput;
 }

const setPerspectiveStateToApi = (newPerspectiveState: INewPerspectiveState) => {
    var perspective : INewPerspectiveAPI = {};
    perspective.FiscalYear = newPerspectiveState.FiscalYear; 
    perspective.PerspectiveID = newPerspectiveState.PerspectiveID;
    perspective.PerspectiveName = newPerspectiveState.PerspectiveName;
    perspective.FactTableName = newPerspectiveState.FactTableName;
    perspective.DomainAttributes = newPerspectiveState.DomainsRowData;
    perspective.IsDistributedQuotaApplicable = newPerspectiveState.IsDistributedQuotaApplicable;
    perspective.Measures=setMeasuresStateToApi(newPerspectiveState)
    return perspective;
};

export const startSavePerspectiveToDB = (newPerspective: INewPerspectiveState, startGetAllPerspectives: ()=>void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const newPerspectiveAPI = setPerspectiveStateToApi(newPerspective);
        const res = PerspectiveDefinitionAPI.savePerspectiveToDB(newPerspectiveAPI)
            .then((response) => {
                const result: IPerspectiveMsgInfo = response;
                let tempResult: IPerspectiveMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "Perspective saved successfully.Please proceeed to create ENTITY RELATIONSHIPS for Transaction Sources, if applicable." :
                                                (result.Result === 0 ? "Perspective NOT saved successfully." : 
                                                    (result.Result === -2 ? "Perspective with same name already exists." : "")));
                    dispatch(PerspectiveDefinitionActions.getNewPerspectiveSaveSuccessAction(tempResult));

                    if (startGetAllPerspectives && (result != undefined && result.Result === 1)) 
                        startGetAllPerspectives();
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const startUpdatePerspectiveToDB = (perspective: INewPerspectiveState, startGetAllPerspectives: ()=>void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const newPerspectiveAPI = setPerspectiveStateToApi(perspective);
        const res = PerspectiveDefinitionAPI.updatePerspectiveToDB(newPerspectiveAPI)
            .then((response) => {
                const result: IPerspectiveMsgInfo = response;
                let tempResult: IPerspectiveMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "Perspective updated successfully.Please proceeed to create/edit ENTITY RELATIONSHIPS for Transaction Sources, if applicable." :
                                                (result.Result === 0 ? "Perspective NOT updated successfully." :  ""));
                    dispatch(PerspectiveDefinitionActions.getNewPerspectiveSaveSuccessAction(tempResult));

                    if (startGetAllPerspectives && (result != undefined && result.Result === 1)) 
                        startGetAllPerspectives();
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const startSaveFlexGrainToDB = (flexGrain: IFlexGrain, _startGetMeasuresAndFlexGrains: (perspectiveId: number) => void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = PerspectiveDefinitionAPI.saveFlexGrainToDB(flexGrain)
            .then((response) => {
                const result: IPerspectiveMsgInfo = response;
                let tempResult: IPerspectiveMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "Flex Grain saved successfully." :
                                                (result.Result === 0 ? "Flex Grain NOT saved successfully." :  ""));
                    dispatch(PerspectiveDefinitionActions.getFlexGrainSaveSuccessAction(tempResult));

                    if (result != undefined && result.Result == 1) {
                        _startGetMeasuresAndFlexGrains(flexGrain.PerspectiveID);
                    }
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const startUpdateFlexGrainToDB = (flexGrain: IFlexGrain, _startGetMeasuresAndFlexGrains: (perspectiveId: number) => void) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = PerspectiveDefinitionAPI.updateFlexGrainToDB(flexGrain)
            .then((response) => {
                const result: IPerspectiveMsgInfo = response;
                let tempResult: IPerspectiveMsgInfo = {};
                if (result != null && result != undefined) {
                    tempResult.StatusCode = result.StatusCode;
                    tempResult.Result = result.Result;
                    tempResult.loading = false;
                    tempResult.ErrorMessage = (result.Result === 1 ? "Flex Grain updated successfully." :
                                                (result.Result === 0 ? "Flex Grain NOT updated successfully." :  ""));
                    dispatch(PerspectiveDefinitionActions.getFlexGrainSaveSuccessAction(tempResult));

                    if (result != undefined && result.Result == 1) {
                        _startGetMeasuresAndFlexGrains(flexGrain.PerspectiveID);
                    }
                }
            })
            .catch((error) => {
                const err = error;
                window.alert(error.message);
            })
    };
};

export const clearAllDomainAttributesProps = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const data : any[] = [];
        dispatch(PerspectiveDefinitionActions.getAllDomainAttributes(data));
    };
};

export const clearMeasuresProps = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const data : any[] = [];
        dispatch(PerspectiveDefinitionActions.getMeasures(data));
    };
};

export const clearSaveResultMsgProps = (loading: boolean) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        var tempResult: IPerspectiveMsgInfo = {
            Result: 5,
            StatusCode: 0,
            ErrorMessage: "",
            loading: loading,
        };
        dispatch(PerspectiveDefinitionActions.getNewPerspectiveSaveSuccessAction(tempResult));
    };
};

export const clearSaveFlexGrainResultMsgProps = (loading: boolean) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        var tempResult: IPerspectiveMsgInfo = {
            Result: 5,
            StatusCode: 0,
            ErrorMessage: "",
            loading: loading,
        };
        dispatch(PerspectiveDefinitionActions.getFlexGrainSaveSuccessAction(tempResult));
    };
};

