import React from "react";
import {
  IPerspective,
  PerspectiveDefinitionProps,
  PerspectiveDefinitionLinkStateProps,
  PerspectiveDefinitionLinkDispatchProps,
} from "./model/IPerspectiveDefinition";
import { AppState } from "../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import { startGetAllPerspectives } from "./perspective-definition.service";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import { PerspectiveDefinition } from "./perspective-definition.view";
import { ColDef } from "ag-grid-community";
import { ModalView } from "../../common/modal/modal.view";
import IconButtonView from "../../common/button/icon-button/icon-button.view";
import { IconButton } from "office-ui-fabric-react";
import { getDeepCopy } from "../../../util/javascript-functions";
import NewPerspective from "./new-perspective/new-perspective.controller";
import * as TelemetryProvider from "../../../TelemetryProvider";
import {
  perspectiveDefinitionDeleteButtonClickEvent,
  perspectiveDefinitionPageView,
} from "./perspective-definition.telemtetry-constants";
import { startGetFlexGrains } from "./new-perspective/new-perspective.service";
import { startGetAllMeasures } from "../measure-definition/measure-definition.service";

type Props = PerspectiveDefinitionProps &
  PerspectiveDefinitionLinkStateProps &
  PerspectiveDefinitionLinkDispatchProps;

export class PerspectiveDefinitionList extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    TelemetryProvider._trackPageView(perspectiveDefinitionPageView);
    this.state = {
      editModalParams: undefined,
      isEditModalVisible: false,
      isNewModal: false,
    };
    this.getEditControl = this.getEditControl.bind(this);
    this.getDeleteControl = this.getDeleteControl.bind(this);
    this.updateIsEditModalVisible = this.updateIsEditModalVisible.bind(this);
  }

  componentDidMount() {
    this.props.startGetAllPerspectives();
    //this.props.startGetFlexGrains(this.props.fiscalYear);
    //this.props.startGetAllMeasures();
  }

  public render(): JSX.Element {
    if (
      this.props.allPerspectives != undefined ||
      this.props.allPerspectives != null
    ) {
      var columnDefs: any[] = [];
      var rowData: any[] = [];
      columnDefs = this.getColDefinitions(columnDefs);
      rowData = this.getRowData(rowData, this.props.allPerspectives).filter(
        (p) => p.FiscalYear == this.props.fiscalYear
      );
      var params = {
        allPerspectives: this.props.allPerspectives,
        columnDefs: columnDefs,
        rowData: rowData,
        startGetAllPerspectives: this.props.startGetAllPerspectives,
        editModalParams: this.state.editModalParams,
        showModal: this.state.isEditModalVisible,
        updateIsEditModalVisible: this.updateIsEditModalVisible,
        gridCellKeyPress: this.gridCellKeyPress,
        isNewModal: this.state.isNewModal,
      };
      return <PerspectiveDefinition {...params} />;
    } else {
      return (
        <>
          <div>Loading...</div>
        </>
      );
    }
  }

  private gridCellKeyPress = (event: any) => {
    let keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "Edit":
          // TelemetryProvider._trackEvent(editActivityClickEvent);
          var _editParams = {
            ActionType: "edit",
            FiscalYear: event.data.FiscalYear,
            PerspectiveID: event.data.PerspectiveID,
            PerspectiveName: event.data.PerspectiveName,
            FactTableName: event.data.FactTableName,
            IsDistributedQuotaApplicable:
              event.data.IsDistributedQuotaApplicable,
            Domains: event.data.Domains,
            DomainAttributes: event.data.DomainAttributes,
            MeasureList: event.data.MeasureList,
            startGetAllPerspectives: this.props.startGetAllPerspectives,
          };
          this.setState({
            editModalParams: _editParams,
            isEditModalVisible: true,
          });
          break;
        case "Delete":
          if (event.data.IsActive) {
            this.deleteButtonClicked(event.data);
          }
          break;
        default:
          return;
      }
    }
  };

  private deleteButtonClicked = (params: any) => {
    TelemetryProvider._trackEvent(perspectiveDefinitionDeleteButtonClickEvent);
    var clonedPerspectiveState = getDeepCopy(this.props.allPerspectives);
    var tempVal = window.confirm("Do you want to delete this Perspective ?");
    if (tempVal == true) {
      //this.props.startDeletePerspectiveById(params.PerspectiveID, clonedPerspectiveState);
    }
  };

  private updateIsEditModalVisible(isNewModal: boolean = false) {
    this.setState({
      isEditModalVisible: !this.state.isEditModalVisible,
      isNewModal: isNewModal,
    });
  }

  private getEditControl = (params: any) => {
    var _editParams = {
      ActionType: "edit",
      FiscalYear: params.data.FiscalYear,
      PerspectiveID: params.data.PerspectiveID,
      PerspectiveName: params.data.PerspectiveName,
      FactTableName: params.data.FactTableName,
      IsDistributedQuotaApplicable: params.data.IsDistributedQuotaApplicable,
      Domains: params.data.Domains,
      DomainAttributes: params.data.DomainAttributes,
      MeasureList: params.data.MeasureList,
      startGetAllPerspectives: this.props.startGetAllPerspectives,
    };
    return (

        <IconButtonView
          iconName="SingleColumnEdit"
          fontSize={12}
          title="Edit"
          onClick={() => {
            this.setState({
              editModalParams: _editParams,
              isEditModalVisible: true,
              isNewModal: false,
            });
          }}
          ariaLabel="Edit"
        />

    );
  };

  private getDeleteControl = (params: any) => {
    return (
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "20%" }}>
          <IconButton
            iconProps={{
              iconName: "Delete",
              style: { fontSize: 12 },
            }}
            title="Delete"
            onClick={(event) => this.deleteButtonClicked(params.data)}
            ariaLabel="Delete"
            disabled={!params.data.IsActive}
          />
        </div>
      </div>
    );
  };

  private getColDefinitions(colDefns: ColDef[]) {
    colDefns = [
      {
        field: "FiscalYear",
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
      },
      { field: "PerspectiveID", hide: true },
      {
        field: "PerspectiveName",
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
      },
      { field: "FactTableName", hide: true },
      { field: "IsDistributedQuotaApplicable", hide: true },
      {
        field: "Domains",
        flex: 4,
        sortable: true,
        filter: true,
        resizable: true,
      },
      { field: "DomainAttributes", hide: true },
      {
        headerName: "Measures",
        field: "MeasureList",
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "IsActive",
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Edit",
        width: 60,
        cellRendererFramework: this.getEditControl,
      },
      {
        headerName: "Delete",
        width: 60,
        cellRendererFramework: this.getDeleteControl,
      },
    ];
    return colDefns;
  }

  private getRowData(rowData: any[], perspectives: IPerspective[]) {
    if (perspectives != undefined) {
      perspectives.forEach((perspective) => {
        var domainsList: string[] = [];
        if(perspective.Domains!=undefined)
        {
        perspective.Domains.forEach((d: string) => {
          domainsList.push(d.split(",")[1]);
        });
      }

        rowData.push({
          FiscalYear: perspective.FiscalYearID,
          PerspectiveID: perspective.PerspectiveID,
          PerspectiveName: perspective.PerspectiveName,
          FactTableName: perspective.FactTableName,
          IsDistributedQuotaApplicable:
            perspective.IsDistributedQuotaApplicable,
          Domains: domainsList.join(),
          DomainAttributes: perspective.DomainAttributes,
          MeasureList: perspective.MeasureList,
          IsActive: perspective.IsActive,
        });
      });
    }
    return rowData;
  }
}

const mapStateToProps = (
  state: AppState
): PerspectiveDefinitionLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    allPerspectives: state.perspectiveDefinition.allPerspectives,
    allFlexGrains: state.perspectiveDefinition.allFlexGrains,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): PerspectiveDefinitionLinkDispatchProps => ({
  startGetAllPerspectives: bindActionCreators(
    startGetAllPerspectives,
    dispatch
  ),
  startGetFlexGrains: bindActionCreators(
    startGetFlexGrains,
    dispatch
  ),
  startGetAllMeasures: bindActionCreators(
    startGetAllMeasures,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerspectiveDefinitionList);
