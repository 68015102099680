import * as React from "react";
import { ComboBox } from "office-ui-fabric-react";
import { IComboBoxPropsCustom } from "./models/IComboBox";

const ComboBoxView = (Props: IComboBoxPropsCustom) => {
  return (
    <ComboBox
      allowFreeform
      autoComplete="on"
      //useComboBoxAsMenuWidth
      placeholder={Props.placeholder}
      label={Props.label}
      ariaLabel={Props.ariaLabel}
      options={Props.options}
      onChange={Props.onChange}
      className={Props.className}
      selectedKey={Props.selectedKey}
      styles={Props.styles}
      multiSelect={Props.multiSelect}
      defaultSelectedKey={Props.defaultSelectedKey}
      required={Props.required}
      defaultValue={Props.defaultValue}
      dropdownWidth={Props.dropdownWidth}
      disabled={Props.disabled}
      title={Props.title}
    />
  );
};
export default ComboBoxView;
