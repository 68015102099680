import { AppState } from "./redux/configureStore";
import { AuthenticationState } from "react-aad-msal";

var _datasetColumnDetails: any[] = [];

export const InitialState: AppState = {
  expandCollapseClick: false,
  isSideNavOpen: false,
  entityOnboardingReducer: {},
  newEntityReducer: {},
  authentication: {
    initializing: false,
    initialized: false,
    idToken: null,
    accessToken: null,
    state: AuthenticationState.Unauthenticated,
  },
  userProfile: {},
  domainDefinition: {
    DatasetColumnDetails: _datasetColumnDetails,
  },
  perspectiveDefinition: {},
  measureDefinition: {},
  entityRelationship: {},
  fiscalYear: {},
};
