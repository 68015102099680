import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { IColumn } from "office-ui-fabric-react";
import { GetToken } from "../auth/authorization-token";
import { INewDomainAPI } from "../components/onboarding/domain-definition/new-domain/model/INewDomain";

let authToken = "";
const baseUrl = Configurations.mintSystemConfigApiBaseUrl + "/Domain/";

const columns: IColumn[] = [];

export function getAllDomains() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'Domains', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getDomainTypes() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'DomainTypes', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getAllEntitiesList(fiscalYear: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'Entities?fiscalYear=' + fiscalYear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getDatasetColumns(datasetIds: string) {
    //Sending list of DatasetIDs to API in the form of a string separated by ','
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'DatasetColumns?datasetIdList=' + datasetIds, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function saveDomainToDB(newDomain: INewDomainAPI) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'CreateNewDomain', {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(newDomain),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function updateDomainToDB(domainId: number, domain: INewDomainAPI) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'UpdateDomain?domainId=' + domainId, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(domain),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function deleteDomainById(domainId: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'DeleteDomain?domainId=' + domainId, {
                method: "PUT",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

