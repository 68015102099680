import * as React from "react";
import {
  DefaultPalette,
  AnimationClassNames
} from "office-ui-fabric-react/lib/Styling";
import {
  IconButton,
  initializeIcons,
  Stack,
  IStackItemStyles,
  IStackStyles
} from "office-ui-fabric-react";

initializeIcons();

export class Accordion extends React.Component<IAccordionProps, {}> {
  private accordionHeader: IStackItemStyles = {
    root: {
      background: DefaultPalette.neutralLighter,
      padding: 5,
      cursor: "pointer"
    }
  };
  private accordion: IStackStyles = {
    root: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: DefaultPalette.neutralTertiary
    }
  };
  private accordionConent: IStackStyles = {
    root: {
      padding: 10
    }
  };

  constructor(props: IAccordionProps, public state: any) {
    super(props);

    this.state = {
      content: false
    };
  }

  private showContent = (_e: any) => {
    this.setState({
      content: !this.state.content
    });
  };

  public componentDidMount() {}

  public render(): React.ReactElement<IAccordionProps> {
    return (
      <>
        <Stack horizontal={false} styles={this.accordion}>
          <Stack.Item styles={this.accordionHeader}>
            <Stack horizontal={true} onClick={this.showContent}>
              <Stack.Item>
                <IconButton aria-label={this.props.header}
                  iconProps={{
                    iconName: this.state.content
                      ? "ChevronDown"
                      : "ChevronRight"
                  }}
                />
              </Stack.Item>
              <Stack.Item align="center">{this.props.header}</Stack.Item>
            </Stack>
          </Stack.Item>
          {this.state.content && (
            <Stack.Item
              className={AnimationClassNames.slideDownIn20}
              styles={this.accordionConent}
            >
              {this.props.children}
            </Stack.Item>
          )}
        </Stack>
      </>
    );
  }
}

interface IAccordionProps {
  header: string;
}
