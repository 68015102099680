import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { IColumn } from "office-ui-fabric-react";
import { GetToken } from "../auth/authorization-token";
import {IEntityRelationshipInput, IERRowData} from "../components/onboarding/entityrelationship-definition/new-entityrelationship/model/INewEntityRelationship"

let authToken = "";
const baseUrl = Configurations.mintSystemConfigApiBaseUrl + "/EntityRelationship/";

export function getAllEntityRelationships() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetAllEntityRelationships', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getAllDataSetColumnList() {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetDataSetColumnList', {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function saveEntityRelationshipToDB(newEntityRelationship: IEntityRelationshipInput[]) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'CreateEntityRelationship', {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(newEntityRelationship),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function saveNewTransactionSourceToDB(transactionSourceName: string, columnList: string,fiscalyear: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + "SetDataSetColumnDetails?transactionSourceName=" + transactionSourceName +"&columnList="+columnList+"&fiscalyear="+fiscalyear, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function updateEntityRelationshipToDB(entityRelationship: IEntityRelationshipInput[]) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'UpdateEntityRelationship', {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + authToken,
                },
                body: JSON.stringify(entityRelationship),
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function deleteEntityRelationshipById(entityrelationshipID: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'DeleteEntityRelationship?erID=' + entityrelationshipID, {
                method: "PUT",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getDatasetColumns(fiscalYear: number) {
    //Sending list of DatasetIDs to API in the form of a string separated by ','
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetDatasetColumnDetails?fiscalYear=' + fiscalYear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getDatasetColumnsWithAlias(fiscalYear: number) {
    //Sending list of DatasetIDs to API in the form of a string separated by ','
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetDatasetColumnDetailsWithAlias?fiscalYear=' + fiscalYear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

export function getTransactionDatasetDetails(fiscalYear: number) {
    return GetToken()
        .then((res) => {
            authToken = res;
            return fetch(baseUrl + 'GetTransactionDatasetDetails?fiscalYear=' + fiscalYear, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    Authorization: "Bearer " + authToken,
                },
            });
        })
        .then(handleResponse)
        .catch(handleError);
}

