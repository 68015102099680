import { handleResponse, handleError } from "./api.util";
import { Configurations } from "../App.global";
import { GetToken } from "../auth/authorization-token";

const baseUrl = Configurations.mintSystemConfigApiBaseUrl + "/User";
var authToken = "";

export function getUserRoleDetails() {
  return GetToken()
    .then((res) => {
      authToken = res;
      return fetch(baseUrl, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}
