import * as React from "react";
import { Modal, IconButton } from "office-ui-fabric-react";
import { contentStyles, iconButtonStyles } from "./modal.style";
import { IModalProps } from "./models/IModal";

export interface IModalState {
  showModal: boolean;
}
let doesPropHasIsShowModal: undefined | boolean = false;

export class ModalView extends React.Component<IModalProps, IModalState> {
  constructor(props: IModalProps) {
    super(props);
    doesPropHasIsShowModal = props.showModal;
    this.state = {
      showModal: false,
    };
  }
  public render(): JSX.Element {
    const previewEntryIcon = React.cloneElement(this.props.control, {
      onClick: () => {
        if (this.props.control.props?.onClick) {
          this.props.control.props?.onClick();
        }
        this._showModal();
      },
    });
    return (
      <div>
        {previewEntryIcon}
        <Modal
          // isOpen={this.state.showModal}
          isOpen={
            this.props.showModal !== undefined
              ? this.state.showModal && this.props.showModal
              : this.state.showModal
          }
          onDismiss={
            this.props.onDismissCloseModal !== undefined ?
              this.props.onDismissCloseModal ? this._closeModal : this._showModal 
              : this._closeModal
          }
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <span id="preview">{this.props.title}</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={this._closeModal as any}
            />
          </div>
          <div id="children" className={contentStyles.body}>
            <div>{this.props.children}</div>
          </div>
        </Modal>
      </div>
    );
  }

  private _showModal = (): void => {
    this.setState({ showModal: true });
  };

  private _updateModalVisibility = (showModal: boolean): void => {
    this.setState({ showModal: showModal });
  };

  private _closeModal = (): void => {
    this.setState({ showModal: false });
  };

  private onControlClick = (): void => {
    this.props.control.onClick();
    this._showModal();
  };
}
