import { IMeasure,IMeasureMsgInfo,IPerspectiveDetail } from "../../components/onboarding/measure-definition/model/IMeasure";
import { AppActions } from "../types/app-actions";
import * as types from "../types/measure-definition-action-types";


export const getAllMeasures = (
    allMeasures: IMeasure[]
): AppActions => {
    return ({
        type: types.GET_ALL_MEASURES,
        allMeasures,
    });
}

export const getAllUnitTypes = (
    allUnitTypes: string[]
): AppActions => {
    return ({
        type: types.GET_ALL_UNITTYPES,
        allUnitTypes,
    });
}

export const getAllUnits = (
    allUnits: string[]
): AppActions => {
    return ({
        type: types.GET_ALL_UNITS,
        allUnits,
    });
}

export const getMeasureTypes = (
    allMeasureTypes: string[]
): AppActions => {
    return ({
        type: types.GET_ALL_MEASURETYPES,
        allMeasureTypes,
    });
}

export const getPerspectiveDetails = (
    allPerspectiveDetails: string[]
): AppActions => {
    return ({
        type: types.GET_ALL_PERSPECTIVE_DETAILS,
        allPerspectiveDetails,
    });
}

export const getAllEntities = (
    allEntitiesList: any
): AppActions => {
    return ({
        type: types.GET_ALL_ENTITIES,
        allEntitiesList,
    });
}


export const getDatasetColumns = (
    datasetColumnNames: string[]
): AppActions => {
    return ({
        type: types.GET_DATASET_COLUMNS,
        datasetColumnNames,
    });
}

export const getNewMeasureSaveSuccessAction = (
    saveResultMsg: IMeasureMsgInfo
): AppActions => {
    return ({
        type: types.GET_NEW_SAVE_SUCCESS,
        saveResultMsg,
    });
}

export const clearDatasetColumns = (): AppActions => {
    return ({
        type: types.CLEAR_DATASET_COLUMNS,
    });
}





