import React, { Dispatch, useState } from "react";
import { AppActions } from "../../../redux/types/app-actions";
import { AppState } from "../../../redux/configureStore";
import * as DomainDefinitionAPI from "../../../api/domain-definition-api";
import * as DomainDefinitionActions from "../../../redux/actions/domain-definition-actions";
import { IColumn, IconButton } from "office-ui-fabric-react";
import "../../onboarding/common/onboarding-summary.scss";
import { IDomains } from "./model/IDomainDefinition";


export const startGetAllDomains = () => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = DomainDefinitionAPI.getAllDomains()
            .then((value) => {
                const data: IDomains[] = value;
                dispatch(DomainDefinitionActions.getAllDomains(data));
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            })
    };
};

export const startDeleteDomainById = (domainId: number, clonedDomainState: IDomains[]) => {
    return (dispatch: Dispatch<AppActions>, _getState: () => AppState) => {
        const res = DomainDefinitionAPI.deleteDomainById(domainId)
            .then((value) => {
                const result: any = value;
                if (result) {
                    const domain = clonedDomainState.find(d => d.DomainID == domainId);
                    if (domain != null || domain != undefined) 
                        domain.IsActive = false;                  
                    dispatch(DomainDefinitionActions.deleteDomain(clonedDomainState));
                    alert("Domain deleted successfully.");
                }
                else {
                    alert("Domain could NOT be deleted.");
                }
            })
            .catch((error) => {
                const err = error;
                window.alert("An error occured. Please try again.");
            });
    };
}