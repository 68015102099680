import {
  IHorizontalNavigationBarList,
} from "./model/IHorizontalNavigationBar";

const horizontalNavigationList: IHorizontalNavigationBarList[] = [
  {
    rootPath: "system",
    defaultKey: "sourceonboarding",
    routeList: [
      {
        navKey: "entityonboarding",
        name: "Entity Onboarding",
        url: "/system/entity-onboarding",
      },
      {
        navKey: "entityrelationship",
        name: "Entity Relationship",
        url: "/system/entity-relationship",
      },
      {
        navKey: "domaindefinition",
        name: "Domain Definition",
        url: "/system/domain-definition",
      },
      {
        navKey: "perspectivedefinition",
        name: "Perspective Definition",
        url: "/system/perspective-definition",
      },
    ],
  },
];

export default horizontalNavigationList;
