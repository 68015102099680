import * as types from "../types/measure-definition-action-types";
import { MeasureDefinitionActionTypes } from "../types/measure-definition-action-types";

const measureDefinitionReducerDefaultState: any = {};

const MeasureDefinitionReducer = (
    state = measureDefinitionReducerDefaultState,
    action: MeasureDefinitionActionTypes
): any => {
    switch (action.type) {
        case types.GET_ALL_MEASURES:
            return Object.assign({}, state, {
                allMeasures: action.allMeasures,
            });
            case types.GET_ALL_MEASURETYPES:
                return Object.assign({}, state, {
                    allMeasureTypes: action.allMeasureTypes,
                });
                case types.GET_ALL_UNITS:
                return Object.assign({}, state, {
                    allUnits: action.allUnits,
                });
                case types.GET_ALL_PERSPECTIVE_DETAILS:
                return Object.assign({}, state, {
                    allPerspectiveDetails: action.allPerspectiveDetails,
                });
                case types.GET_ALL_UNITTYPES:
                return Object.assign({}, state, {
                    allUnitTypes: action.allUnitTypes,
                            });
                            case types.GET_ALL_ENTITIES:
                                return Object.assign({}, state, {
                                    allEntitiesList: action.allEntitiesList,
                                });
                                case types.GET_DATASET_COLUMNS:
                                    return Object.assign({}, state, {
                                        datasetColumnNames: action.datasetColumnNames,
                                    });
                     case types.GET_NEW_SAVE_SUCCESS:
                                        return Object.assign({}, state, {
                                            saveResultMsg: action.saveResultMsg,
                                        });	
                
                        case types.CLEAR_DATASET_COLUMNS:
                            return Object.assign({}, state, {
                                DatasetColumnDetails: [],
                            });
                
        default:
            return state;
    }
};

export default MeasureDefinitionReducer;