import React from "react";
import {
  IDropdownOption,
  Label,
  TextField,
  PrimaryButton,
  Checkbox,
  Spinner,
  SpinnerSize,
  Modal,
} from "office-ui-fabric-react";
import { IDropdownPropsCustom } from "../../common/dropdown/models/IDropdown";
import Dropdown from "../../common/dropdown/dropdown.controller";
import "./new-entity.scss";
import {
  fetchEntities,
  saveEntityToDB,
  updateEntityToDB,
  clearSaveEntityMsgDetails,
  startGetDatasetColumnsList,
  GetEntityLakeReference,
  ResetToDefaultELRData
} from "../../../redux/actions/new-entity-action";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { IEntityPreview, IEntityMsgInfo, IEntityLakeReferenceMetadata } from "./model/IEntity";
import { getDeepCopy } from "../../../util/javascript-functions";
import ComboBox from "../../common/combo-box/combo-box.controller";
import {
  IComboBox,
  IComboBoxOption,
  SelectableOptionMenuItemType,
} from "office-ui-fabric-react/lib/ComboBox";
import {
  contentStyles,
  iconButtonStyles,
} from "../../common/modal/modal.style";
import IconButton from "../../common/button/icon-button/icon-button.controller";

export interface INewEntityState {
  IsTransactionSource?: boolean;
  SparkViewRequired?: boolean;
  SourceDataset?: string;
  AltSourceDataset?: string;
  SparkSQLQuery?: string;
  TagName?: string;
  FilterCondition?: string;
  Precedence?: number;
  EntityList?: string;
  ActionType?: string;
  FiscalYear?: number;
  DatasetName?: string;
  IsActive?: true;
  IsAlt?: boolean;
  AltDatasetName?: string;
  DataSetID?: number;
  SelectedEntities: string[];
  AllEntityList: any[];
  EntityIDNameOptions?: IDropdownOption[];
  columnList?: string;
  ispersistable?: boolean;
  datasetColumnNamesList?: any[];
  ELRObjectTypeName?: string;
  ELRDataSourceName?: string;
  ELRSubjectAreaName?: string;
}

interface INewEntityProps {
  DataSetID?: number;
  Entities?: string[];
  totalEntities?: any;
  //FiscalYear?: number;
  IsTransactionSource?: boolean;
  SparkViewRequired?: boolean;
  SourceDataset?: string;
  AltSourceDataset?: string;
  SparkSQLQuery?: string;
  TagName?: string;
  FilterCondition?: string;
  Precedence?: number;
  EntityList?: string;
  ispersistable?: boolean;
  ActionType?: string;
  DatasetName?: string;
  IsActive?: true;
  IsAlt?: boolean;
  saveEntityMsg: IEntityMsgInfo;
  getAllEntits: any;
  getDatasetColumnsList: any;
  fiscalYear: number;
  store?: any; //Unit Testing
  datasetColumnNamesList: any[];
  showModal: boolean;
  updateIsEditModalVisible: any;
  ELRObjectTypeName?: string;
  ELRDataSourceName?: string;
  ELRSubjectAreaName?: string;

  startGetDatasetColumnsList: () => void;
  saveEntity: (newEntityState: INewEntityState, getAllEntits: any, getDatasetColumnsList: any) => void;
  updateEntity: (
    newEntityState: INewEntityState,
    dataSetId: number,
    getAllEntits: any,
    getDatasetColumnsList: any
  ) => void;
  clearUpdateEntitydMsgs: (loading: boolean) => void;
  clearSaveEntityMsgs: (loading: boolean) => void;
  getEntities: (fiscalYear: number) => void;
  getEntityLakeReferenceMetadata: (datasetName: string, fiscalyear: number) => void;
  resetDefaultELRData: () => void;
}

export const defaultELRData: IEntityLakeReferenceMetadata = {
  ObjectTypeName: "Stage",
  DataSourceName: "UI",
  SubjectAreaName: ""
};

class NewEntity extends React.Component<INewEntityProps, INewEntityState> {
  componentWillUnmount() {
    this.props.clearSaveEntityMsgs(false);
    this.props.resetDefaultELRData();
  }

  componentWillMount() {
    this.props.startGetDatasetColumnsList();
    this.props.clearSaveEntityMsgs(false);
  }

  constructor(props: any) {
    super(props);
    var columns = "";
    this.state = {
      DataSetID: this.props.DataSetID,
      IsTransactionSource: this.props.IsTransactionSource,
      SparkViewRequired:
        this.props.SparkSQLQuery != undefined && this.props.SparkSQLQuery != ""
          ? true
          : false,
      TagName: this.props.TagName,
      EntityList: this.props.EntityList,
      Precedence: this.props.Precedence,
      SparkSQLQuery: this.props.SparkSQLQuery,
      FilterCondition: this.props.FilterCondition,
      IsAlt: this.props.IsAlt,
      ActionType: this.props.ActionType,
      FiscalYear: this.props.fiscalYear,
      DatasetName: this.props.DatasetName,
      IsActive: this.props.IsActive,
      SelectedEntities: this.setSelectedEntities(),
      AllEntityList: this.props.totalEntities?.totEntities,
      EntityIDNameOptions: this.getEntityIDNameOptions(),
      datasetColumnNamesList: this.props.datasetColumnNamesList,
      columnList: "",
      ELRObjectTypeName: this.props.ELRObjectTypeName !== undefined ? this.props.ELRObjectTypeName : defaultELRData.ObjectTypeName,
      ELRDataSourceName: this.props.ELRDataSourceName !== undefined ? this.props.ELRDataSourceName : defaultELRData.DataSourceName,
      ELRSubjectAreaName: this.props.ELRSubjectAreaName !== undefined ? this.props.ELRSubjectAreaName : defaultELRData.SubjectAreaName,
    };
    if (this.props.ActionType === "edit" || this.props.ActionType === "clone") {
      this.props.getEntityLakeReferenceMetadata(
        this.props.DatasetName !== undefined ? this.props.DatasetName : "",
        this.props.fiscalYear
      );
      this.props.clearSaveEntityMsgs(false);
    }

    if (this.props.datasetColumnNamesList != undefined) {
      if (this.props.DatasetName != undefined) {
        this.getEntityColumnList();
      }
    }
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  private getEntityIDNameOptions = (): IDropdownOption[] => {
    var data: IDropdownOption[] = [];
    if (this.props.totalEntities != undefined) {
      if (this.props.totalEntities?.totEntities != undefined) {
        var filteredEntities = this.props.totalEntities?.totEntities.filter(
          (d: any) => d.FiscalYear == this.props.fiscalYear
        );
        data = filteredEntities.map((entity: any) => ({
          id: entity.DataSetID,
          text: entity.DataSetName.trim(),
          key: entity.DataSetName.trim(),
        }));
      } else {
        var filteredEntities = this.props.totalEntities?.filter(
          (d: any) => d.FiscalYear == this.props.fiscalYear
        );
        data = filteredEntities.map((entity: any) => ({
          id: entity.DataSetID,
          text: entity.DataSetName.trim(),
          key: entity.DataSetName.trim(),
        }));
      }
    }
    return data.sort((a, b) => a.text.localeCompare(b.text));
  };

  private _isPlanningChanged = (): void => {
    this.setState({ IsAlt: !this.state.IsAlt });
  };

  private setSelectedEntities = (): string[] => {
    var SelectedEntities: string[] = [];

    if (this.props.EntityList != undefined) {
      SelectedEntities =
        this.props.EntityList != undefined
          ? this.props.EntityList.split(",")
          : [];
    }

    return SelectedEntities;
  };

  private _isTransactionSourceChanged = (): void => {
    this.setState({ IsTransactionSource: !this.state.IsTransactionSource });
  };

  private _isSparkViewRequiredChanged = (): void => {
    this.setState({ SparkViewRequired: !this.state.SparkViewRequired });
    if (!this.state.SparkViewRequired == true) {
      this.setState({ IsTransactionSource: true });
    }
  };

  private _clearbuttonClicked = (): void => {
    this.props.clearSaveEntityMsgs(false);
    this.setState({
      DatasetName:
        this.props.ActionType === "edit" ? this.props.DatasetName : "",
      ActionType: "",
      FiscalYear: this.props.fiscalYear,
      IsAlt: false,
      IsTransactionSource: false,
      SparkViewRequired: false,
      ELRObjectTypeName: defaultELRData.ObjectTypeName,
      ELRDataSourceName: defaultELRData.DataSourceName,
      ELRSubjectAreaName: defaultELRData.SubjectAreaName
    });
  };

  private _savebuttonClicked = (): void => {
    if (
      this.state.DatasetName === undefined ||
      this.state.DatasetName === "" ||
      this.state.ELRObjectTypeName === undefined ||
      this.state.ELRObjectTypeName === "" ||
      this.state.ELRDataSourceName === undefined ||
      this.state.ELRDataSourceName === ""
    ) {
      alert("please enter all the mandatory fields.");
      return;
    }
    if (
      this.state.SparkViewRequired == true &&
      !this.state.DatasetName.startsWith("vw_")
    ) {
      alert(
        "Spark View Entity name should start with vw_. Please prefix the name with vw_"
      );
      return;
    }
    if (this.props.ActionType === "edit") {
      this.props.clearSaveEntityMsgs(true);
      this.props.updateEntity(
        this.state,
        this.props.DataSetID != undefined ? this.props.DataSetID : 0,
        this.props.getAllEntits,
        this.props.getDatasetColumnsList,
      );
    } else {
      if (this.state.DatasetName.length > 40) {
        window.alert("Entity name must not exceed the limit of 40 characters.");
        return;
      }
      this.props.clearSaveEntityMsgs(true);
      this.props.saveEntity(this.state, this.props.getAllEntits, this.props.startGetDatasetColumnsList);
    }
  };

  private _onDropDownFiscalyearValueChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    index?: number
  ): void => {
    const FiscalYear = Number(item == null ? "" : item.text);
    this.setState({
      FiscalYear,
    });
  };

  private _onDataSetNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const DatasetName = newValue;
    //   this.getEntityColumnList(newValue!=undefined?newValue:'');
    this.setState({
      DatasetName,
    });
  };

  private _onObjectTypeNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const ELRObjectTypeName = newValue;
    this.setState({
      ELRObjectTypeName,
    });
  };

  private _onDataSourceNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const ELRDataSourceName = newValue;
    this.setState({
      ELRDataSourceName,
    });
  };

  private _onSubjectAreaNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const ELRSubjectAreaName = newValue;
    this.setState({
      ELRSubjectAreaName,
    });
  };

  private _onColumnListChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const columnList = newValue;
    this.setState({
      columnList,
    });
  };

  private _onSparkQueryChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const SparkSQLQuery = newValue;
    this.setState({
      SparkSQLQuery,
    });
  };

  private _onTagNameChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const TagName = newValue;
    this.setState({
      TagName,
    });
  };

  private _onFilterConditionChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const FilterCondition = newValue;
    this.setState({
      FilterCondition,
    });
  };

  private _onPrecedenceChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const Precedence = newValue != undefined ? (newValue == "1" ? 1 : 0) : 0;
    this.setState({
      Precedence,
    });
  };

  private _onIsPersistableChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ): void => {
    const ispersistable =
      newValue != undefined ? (newValue == "1" ? true : false) : false;
    this.setState({
      ispersistable,
    });
  };

  private handleDismiss() {
    this.props.updateIsEditModalVisible(false);
  }

  public render(): JSX.Element {
    //Need to change this to API
    var FiscalyearList = [
      { key: 2020, text: "2020" },
      { key: 2021, text: "2021" },
    ];

    const dropDownFiscalYear: IDropdownPropsCustom = {
      placeholder: "Select a value",
      label: "Fiscal Year:",
      onChange: this._onDropDownFiscalyearValueChange,
      options: FiscalyearList,
      required: true,
      selectedKey: this.state.FiscalYear,
      disabled: this.props.ActionType === "edit" ? true : false,
    };
    
    return (
      <Modal
        isOpen={this.props.showModal}
        //onDismiss={this.handleDismiss}
        containerClassName={contentStyles.container}
        isBlocking={true}
      >
        <div className={contentStyles.header}>
          <span id="preview">New/Edit Entity</span>
          <IconButton
            styles={iconButtonStyles}
            iconName="Cancel"
            title="Close Modal"
            fontSize={12}
            ariaLabel="Close popup modal"
            onClick={this.handleDismiss}
          />
        </div>
        <div id="children" className={contentStyles.body}>
          <div className="new-entity">
            <div className="new-connection-buttons-list">
              <PrimaryButton
                text="Clear"
                onClick={this._clearbuttonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
              <PrimaryButton
                text="Save"
                onClick={this._savebuttonClicked}
                className="header-buttons"
                style={{ backgroundColor: "#288560" }}
              />
            </div>
            {this.getMsgDetails(
              this.props.saveEntityMsg != undefined
                ? this.props.saveEntityMsg
                : {}
            )}
            <div className="seperator-cls">
              <Label required={true}>Entity Name:</Label>
              <TextField
                value={this.state.DatasetName}
                disabled={this.props.ActionType === "edit" ? true : false}
                onChange={this._onDataSetNameChange}
                ariaLabel={"Entity Name"}
              />
            </div>
            <div className="seperator-cls">
              <Label required={true}>
                Entity Lake Reference Metadata:
              </Label>
              <div className="combine-cls">
                <div className="single-cls">
                  <TextField
                  label={"Object Type Name:"}
                  value={this.state.ELRObjectTypeName}
                  onChange={this._onObjectTypeNameChange}
                  ariaLabel={"Object Type Name"}
                  /> 
                </div>
                <div className="single-cls">
                  <TextField
                  label={"Data Source Name:"}
                  value={this.state.ELRDataSourceName}
                  onChange={this._onDataSourceNameChange}
                  ariaLabel={"Data Source Name"}
                  /> 
                </div> 
                <div className="single-cls">
                  <TextField
                  label={"Subject Area Name:"}
                  value={this.state.ELRSubjectAreaName}
                  onChange={this._onSubjectAreaNameChange}
                  ariaLabel={"Subject Area Name"}
                  /> 
                </div> 
              </div>
            </div>
            <div className="seperator-cls">
              <Label required={true}>
                Entity Column List(comma separated):
              </Label>
              <TextField
                value={this.state.columnList}
                onChange={this._onColumnListChange}
                ariaLabel={"Entity Column List"}
              />
            </div>
            <div className="seperator-cls">
              <Checkbox
                checked={this.state.IsTransactionSource}
                onChange={this._isTransactionSourceChanged}
                indeterminate={false}
                //id="1"
                className=""
                label="Transactional Entity"
                disabled={false}
                boxSide="end"
              />
            </div>

            <div className="seperator-cls">
              <Checkbox
                checked={this.state.SparkViewRequired}
                onChange={this._isSparkViewRequiredChanged}
                indeterminate={false}
                //id="1"
                className=""
                label="Spark View Required"
                disabled={false}
                boxSide="end"
              />
            </div>

            {this.state.SparkViewRequired && 
              <div>
                <div className="seperator-cls">
                  <ComboBox
                    options={this.getEntityIDNameOptions()}
                    ariaLabel={"Entities:"}
                    label={"Entities:"}
                    placeholder={"Select a value"}
                    dropdownWidth={350}
                    required={true}
                    selectedKey={this.state.SelectedEntities}
                    onChange={this._onDropDownEntityValueChange}
                    multiSelect={true}
                  />
                </div>
                <div>
                  {" "}
                  <TextField
                    label="View Definition:"
                    multiline
                    rows={5}
                    value={this.state.SparkSQLQuery}
                    onChange={this._onSparkQueryChange}
                  />
                </div>
                <div>
                  <TextField
                    label="TagName(comma separated):"
                    value={this.state.TagName}
                    onChange={this._onTagNameChange}
                  ></TextField>
                </div>
                <div>
                  <TextField
                    label="Filter Condition:"
                    value={this.state.FilterCondition}
                    onChange={this._onFilterConditionChange}
                  ></TextField>
                </div>
                <div>
                  <TextField
                    label="Precedence:"
                    value={this.state.Precedence?.toString()}
                    onChange={this._onPrecedenceChange}
                  ></TextField>
                </div>
                <div>
                  <TextField
                    label="Is Persistable:"
                    value={this.state.ispersistable == true ? "1" : "0"}
                    onChange={this._onIsPersistableChange}
                  ></TextField>
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>
    );
  }

  private _onDropDownEntityValueChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (option) {
      var EntityKey = option == null ? "" : option.key;
      var EntityID = Number(option == null ? "" : option.id);
      var EntityName = option == null ? "" : option.text;
      var obj = {
        id: EntityID,
        name: EntityName,
      };

      if (this.state.SelectedEntities != undefined) {
        this.setState({
          SelectedEntities: option?.selected
            ? [...this.state.SelectedEntities, EntityKey as string]
            : this.state.SelectedEntities.filter(
                (key: string) => key != EntityKey
              ),
        });
      }
    }
  };

  private getEntityColumnList = (): string => {
    //  var newArray:any= getDeepCopy(this.state.newTransactionSourceName);
    var columnList: any;
    if (
      this.props.datasetColumnNamesList != undefined &&
      this.props.DatasetName != undefined
    ) {
      columnList = this.props.datasetColumnNamesList.find(
        (d) =>
          d.DataSetName == "Lake:" + this.props.DatasetName &&
          d.FiscalYear == this.props.fiscalYear
      );
      if (columnList != undefined) {
        columnList = columnList.ColumnList;
      } else {
        columnList = "";
      }
    } else {
      columnList = "";
    }

    return columnList;
  };

  private clearSavePropsData(loading: boolean) {
    if (
      this.props.saveEntityMsg != undefined &&
      this.props.saveEntityMsg.Result != undefined &&
      (this.props.saveEntityMsg.Result === 0 ||
        this.props.saveEntityMsg.Result === 1 ||
        loading)
    ) {
      var clonedState = getDeepCopy(this.props.saveEntityMsg);
      this.props.clearSaveEntityMsgs(true);
    }
  }

  private getMsgDetails = (msg: IEntityMsgInfo) => {
    return (
      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
        {msg.loading ? (
          <div>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : (
          ""
        )}
        {msg != undefined && msg.Result != undefined ? (
          msg.Result === 1 && msg.StatusCode === 200 ? (
            <span style={{ color: "green" }}>{msg.ErrorMessage} </span>
          ) : msg.Result === 0 ? (
            <span style={{ color: "red" }}>{msg.ErrorMessage} </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  componentDidUpdate(prevProps: Readonly<INewEntityProps>, prevState: Readonly<INewEntityState>) {
    
    if (prevProps.datasetColumnNamesList != this.props.datasetColumnNamesList) {
      if (
        this.props.datasetColumnNamesList != undefined &&
        this.props.DatasetName != undefined
      ) {
        var columnList = "";
        var datasetObject = this.props.datasetColumnNamesList.find(
          (d) =>
            d.DataSetName == "Lake:" + this.props.DatasetName &&
            d.FiscalYear == this.props.fiscalYear
        );
        if (datasetObject != undefined) {
          columnList = datasetObject.ColumnList;
        }
        this.setState({
          columnList,
        });
      }
    }
    
    if (prevProps.ELRObjectTypeName !== this.props.ELRObjectTypeName && this.props.ELRObjectTypeName !== undefined) {
      this.setState({
        ELRObjectTypeName: this.props.ELRObjectTypeName,
      });
    }

    if (prevProps.ELRDataSourceName !== this.props.ELRDataSourceName && this.props.ELRDataSourceName !== undefined) {
      this.setState({
        ELRDataSourceName: this.props.ELRDataSourceName,
      });
    }

    if (prevProps.ELRSubjectAreaName !== this.props.ELRSubjectAreaName && this.props.ELRSubjectAreaName !== undefined) {
      this.setState({
        ELRSubjectAreaName: this.props.ELRSubjectAreaName,
      });
    }
  }
}

const mapStateToProps = (state: any, ownProps: any): any => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    saveEntityMsg: state.newEntityReducer.saveEntityMsg,
    EntityIDNameOptions: state.domainDefinition.allEntitiesList,
    datasetColumnNamesList: state.entityRelationship.datasetColumnNamesList,
    ELRObjectTypeName: state.newEntityReducer.ELRObjectTypeName,
    ELRDataSourceName: state.newEntityReducer.ELRDataSourceName,
    ELRSubjectAreaName: state.newEntityReducer.ELRSubjectAreaName,
    // updateEntityMsg: state.newEntityReducer.updateEntityMsg,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEntities: (fiscalyear: number) => dispatch(fetchEntities(fiscalyear)),
    startGetDatasetColumnsList: () =>
      dispatch(startGetDatasetColumnsList()),
    saveEntity: (newEntitytate: INewEntityState, getAllEntits: any, getDatasetColumnsList: any) =>
      dispatch(saveEntityToDB(newEntitytate, getAllEntits, getDatasetColumnsList)),
    updateEntity: (
      newEntitytate: INewEntityState,
      dataSetId: number,
      getAllEntits: any,
      getDatasetColumnsList: any
    ) => dispatch(updateEntityToDB(newEntitytate, dataSetId, getAllEntits, getDatasetColumnsList)),
    clearSaveEntityMsgs: (loading: boolean) =>
      dispatch(clearSaveEntityMsgDetails(loading)),
    getEntityLakeReferenceMetadata: (datasetName: string, fiscalyear: number) =>
      dispatch(GetEntityLakeReference(datasetName, fiscalyear)),
    resetDefaultELRData: () =>
      dispatch(ResetToDefaultELRData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewEntity);
