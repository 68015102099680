import React from "react";
import {
  IEntityOnboardingListProps,
  EntityOnboardingLinkStateProps,
  EntityOnboardingLinkDispatchProps,
} from "./model/IEntityOnboarding";
import { AppState } from "../../../redux/configureStore";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../redux/types/app-actions";
import {
  startGetAllEntities,
  deleteEntityById,
} from "./entity-onboarding-service";
import {
  fetchEntities,
  saveEntityToDB,
  updateEntityToDB,
  clearUpdateEntityMsgDetails,
  clearSaveEntityMsgDetails,
  startGetDatasetColumnsList,
} from "../../../redux/actions/new-entity-action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OnboardingSummaryUtility } from "../common/onboarding-summary.utility";
import { EntityOnboarding } from "./entity-onboarding.view";
import { ColDef } from "ag-grid-community";
import { Entity } from "../../../models/Entity";
import { ModalView } from "../../common/modal/modal.view";
import NewEntity from "./new-entity";
import IconButtonView from "../../common/button/icon-button/icon-button.controller";
import { IconButton } from "office-ui-fabric-react";
import { getDeepCopy } from "../../../util/javascript-functions";

type Props = IEntityOnboardingListProps &
  EntityOnboardingLinkStateProps &
  EntityOnboardingLinkDispatchProps;

export class EntityOnboardingList extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editModalParams: undefined,
      isEditModalVisible: false,
      isNewModal: false,
    };
    this.getEditControl = this.getEditControl.bind(this);
    this.getEditControl = this.getEditControl.bind(this);
    this.updateIsEditModalVisible = this.updateIsEditModalVisible.bind(this);
  }

  componentDidMount() {
    this.props.startGetAllEntities();
    //this.props.startGetDatasetColumnsList();
  }

  public render(): JSX.Element {
    if (
      this.props.totalEntities != undefined ||
      this.props.totalEntities != null
    ) {
      var columnDefs: any[] = [];
      var rowData: any[] = [];
      var totalEntities = this.props.totalEntities?.totEntities;
      columnDefs = this.getColDefinations(columnDefs);
      rowData = this.getRowData(rowData, totalEntities).filter(
        (e) => e.FiscalYear == this.props.fiscalYear
      );
      var params = {
        totalEntities: this.props.totalEntities,
        columnDefs: columnDefs,
        rowData: rowData,
        getAllEntits: this.props.startGetAllEntities,
        getDatasetColumnsList: this.props.startGetDatasetColumnsList,
        editModalParams: this.state.editModalParams,
        showModal: this.state.isEditModalVisible,
        updateIsEditModalVisible: this.updateIsEditModalVisible,
        gridCellKeyPress: this.gridCellKeyPress,
        isNewModal: this.state.isNewModal,
      };
      return <EntityOnboarding {...params} />;
    } else {
      return (
        <>
          <div>Loading...</div>
        </>
      );
    }
  }

  private gridCellKeyPress = (event: any) => {
    let keyPressed = event.event.keyCode;
    console.log(keyPressed === 13 || keyPressed === 32);
    if (!(keyPressed === 13 || keyPressed === 32)) {
      return;
    } else {
      switch (event.colDef.headerName) {
        case "Edit":
          // TelemetryProvider._trackEvent(editActivityClickEvent);
          const _editParams = {
            totalEntities: this.props.totalEntities?.totEntities,
            DataSetID: event.data.DataSetID,
            IsAlt:
              event.data.IsPlanning === undefined
                ? false
                : event.data.IsPlanning,
            IsTransactionSource: event.data.IsTransactionSource,
            ActionType: "edit",
            FiscalYear: event.data.FiscalYear,
            DatasetName: event.data.EntityName,
            IsActive: event.data.IsActive,
            getAllEntits: this.props.startGetAllEntities,
            TagName: event.data.TagName,
            EntityList: event.data.EntityList,
            Precedence: event.data.Precedence,
            SparkSQLQuery: event.data.SparkSQLQuery,
            FilterCondition: event.data.FilterCondition,
          };
          this.setState({
            editModalParams: _editParams,
            isEditModalVisible: true,
          });
          break;
        case "Clone":
          // case "Clone":
          //   TelemetryProvider._trackEvent(viewActivityClickEvent);
          const _cloneParams = {
            totalEntities: this.props.totalEntities?.totEntities,
            IsAlt:
              event.data.IsPlanning === undefined
                ? false
                : event.data.IsPlanning,
            IsTransactionSource: event.data.IsTransactionSource,
            ActionType: "clone",
            FiscalYear: event.data.FiscalYear,
            DatasetName: event.data.EntityName,
            IsActive: event.data.IsActive,
            getAllEntits: this.props.startGetAllEntities,
            TagName: event.data.TagName,
            EntityList: event.data.EntityList,
            Precedence: event.data.Precedence,
            SparkSQLQuery: event.data.SparkSQLQuery,
            FilterCondition: event.data.FilterCondition,
          };
          this.setState({
            editModalParams: _cloneParams,
            isEditModalVisible: true,
          });
          break;
        case "Delete":
          if (event.data.IsActive) {
            this.deleteButClicked(event.data);
          }
          break;
        default:
          return;
      }
    }
  };

  private deleteButClicked = (params: any) => {
    var clonedEntityState = getDeepCopy(this.props.totalEntities);
    var tempVal = window.confirm("Do you want to delete this Entity ?");
    if (tempVal == true) {
      this.props.deleteEntityById(params.DataSetID, clonedEntityState);
    }
  };

  private updateIsEditModalVisible(isNewModal: boolean = false) {
    this.setState({
      isEditModalVisible: !this.state.isEditModalVisible,
      isNewModal: isNewModal,
    });
  }

  private getEditControl = (params: any) => {
    const _editParams = {
      totalEntities: this.props.totalEntities?.totEntities,
      DataSetID: params.data.DataSetID,
      IsAlt:
        params.data.IsPlanning === undefined ? false : params.data.IsPlanning,
      IsTransactionSource: params.data.IsTransactionSource,
      ActionType: "edit",
      FiscalYear: params.data.FiscalYear,
      DatasetName: params.data.EntityName,
      IsActive: params.data.IsActive,
      getAllEntits: this.props.startGetAllEntities,
      getDatasetColumnsList: this.props.startGetDatasetColumnsList,
      TagName: params.data.TagName,
      EntityList: params.data.EntityList,
      Precedence: params.data.Precedence,
      SparkSQLQuery: params.data.SparkSQLQuery,
      FilterCondition: params.data.FilterCondition,
    };
    return (
      
        <IconButtonView
          iconName="SingleColumnEdit"
          fontSize={12}
          title="Edit"
          onClick={() => {
            this.setState({
              editModalParams: _editParams,
              isEditModalVisible: true,
              isNewModal: false,
            });
          }}
          ariaLabel="Edit"
        />
      
    );
  };

  private getCloneControl = (params: any) => {
    const _cloneParams = {
      totalEntities: this.props.totalEntities?.totEntities,
      IsAlt:
        params.data.IsPlanning === undefined ? false : params.data.IsPlanning,
      IsTransactionSource: params.data.IsTransactionSource,
      ActionType: "clone",
      FiscalYear: params.data.FiscalYear,
      DatasetName: params.data.EntityName,
      IsActive: params.data.IsActive,
      getAllEntits: this.props.startGetAllEntities,
      getDatasetColumnsList: this.props.startGetDatasetColumnsList,
      TagName: params.data.TagName,
      EntityList: params.data.EntityList,
      Precedence: params.data.Precedence,
      SparkSQLQuery: params.data.SparkSQLQuery,
      FilterCondition: params.data.FilterCondition,
    };
    return (
      
        <IconButtonView
          iconName="Copy"
          fontSize={12}
          title="Clone"
          disabled={!params.data.IsActive}
          onClick={() => {
            this.setState({
              editModalParams: _cloneParams,
              isEditModalVisible: true,
              isNewModal: false,
            });
          }}
          ariaLabel="Clone"
        />
      
    );
  };

  private getDeleteControl = (params: any) => {
    return (
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "20%" }}>
          <IconButton
            iconProps={{
              iconName: "Delete",
              style: { fontSize: 12 },
            }}
            title="Delete"
            disabled={!params.data.IsActive}
            onClick={(event) => this.deleteButClicked(params.data)}
            ariaLabel="Delete"
          />
        </div>
      </div>
    );
  };

  private getColDefinations(colDefns: ColDef[]) {
    colDefns = [
      {
        field: "FiscalYear",
        flex: 0.5,
        sortable: true,
        filter: true,
        resizable: true,
      },
      { field: "DataSetID", hide: true },
      { 
        field: "EntityName", 
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        field: "IsTransactionSource",
        headerName: "Transactional Entity",
        sortable: true,
        filter: true,
      },
      {
        field: "IsSparkView",
        headerName: "Spark View Entity",
        sortable: true,
        filter: true,
      },
      { field: "IsActive", sortable: true, filter: true },
      {
        headerName: "Edit",
        width: 60,
        cellRendererFramework: this.getEditControl,
        lockPinned: true,
        pinned: "right",
      },
      {
        headerName: "Clone",
        width: 60,
        cellRendererFramework: this.getCloneControl,
        lockPinned: true,
        pinned: "right",
      },
      {
        headerName: "Delete",
        width: 60,
        cellRendererFramework: this.getDeleteControl,
        lockPinned: true,
        pinned: "right",
      },
    ];
    return colDefns;
  }

  private getRowData(rowData: any[], entities1: Entity[]) {
    if (entities1 != undefined) {
      var entities = entities1.filter(
        (e: Entity) =>
          !(
            e.DataSetName?.startsWith("Dim") || e.DataSetName?.startsWith("dim")
          )
      );
      for (var i = 0; i < entities.length; i++) {
        var obj = entities[i];
        rowData.push({
          DataSetID: obj.DataSetID,
          FiscalYear: obj.FiscalYear,
          EntityName: obj.DataSetName,
          IsTransactionSource: obj.IsTransactionSource,
          IsSparkView:
            obj.SparkSQLQuery != undefined && obj.SparkSQLQuery != ""
              ? true
              : false,
          IsPlanning: obj.IsAlt,
          IsActive: obj.IsActive,
          TagName: obj.TagName,
          EntityList: obj.EntityList,
          Precedence: obj.Precedence,
          SparkSQLQuery: obj.SparkSQLQuery,
          FilterCondition: obj.FilterCondition,

          //"SparkViewRequired":obj.SparkSQLQuery
          //"actions": ""
        });
      }
    }
    return rowData.sort((a, b) =>
      a.EntityName.trim().localeCompare(b.EntityName.trim())
    );
  }
}

const mapStateToProps = (state: AppState): EntityOnboardingLinkStateProps => {
  return {
    fiscalYear: state.fiscalYear.fiscalYear,
    totalEntities: state.entityOnboardingReducer.totalEntities,
    datasetColumnNamesList: state.entityRelationship.datasetColumnNamesList,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): EntityOnboardingLinkDispatchProps => ({
  startGetAllEntities: bindActionCreators(startGetAllEntities, dispatch),
  deleteEntityById: bindActionCreators(deleteEntityById, dispatch),
  startGetDatasetColumnsList: bindActionCreators(
    startGetDatasetColumnsList,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityOnboardingList);
